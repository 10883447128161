import { initAzureLogin } from './LoginHelper/AzureLoginHelper';
import { initializeGoogle } from './LoginHelper/GoogleLoginHelper';
import { Keyboard, KeyboardInfo, KeyboardResize } from "@capacitor/keyboard";



declare var ALLConfig: ALLConfigType;

if (ALLConfig.azureConfig) {
  initAzureLogin();
}
// if (ALLConfig.googleConfig) {
//   initializeGoogle(ALLConfig.googleConfig);
// }

import 'material-icons/iconfont/material-icons.css';
import "@fontsource/roboto";

import mylog from "@/log";
import "@/date.extensions";
mylog.initLog(); // initialisieren...

import "babel-polyfill";
import Vue from "vue";

import App from "./App.vue";
// import colors from "vuetify/es5/util/colors";
import AUApi from "./api";
import {
  SchedulePlugin,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda
} from "@syncfusion/ej2-vue-schedule";
import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { CheckBoxPlugin } from "@syncfusion/ej2-vue-buttons";
import { ComboBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { RadioButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import { SwitchPlugin } from "@syncfusion/ej2-vue-buttons";
import { UploaderPlugin } from "@syncfusion/ej2-vue-inputs";
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import {
  DateTimePickerPlugin,
  CalendarPlugin
} from "@syncfusion/ej2-vue-calendars";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { TimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { TextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { ListViewPlugin } from "@syncfusion/ej2-vue-lists";
import { InPlaceEditorPlugin } from "@syncfusion/ej2-vue-inplace-editor";
import { enableRipple } from "@syncfusion/ej2-base";
// import { registerLicense } from '@syncfusion/ej2-base';
// registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkFiUH9XcXRVQ2JaU0c=');

/*
import {
  GridPlugin,
  Filter,
  Page,
  FilterType,
  GridComponent
} from "@syncfusion/ej2-vue-grids";
import {
  DropDownList,
  FilteringEventArgs,
  highlightSearch
} from "@syncfusion/ej2-dropdowns";
*/

// check if local update is available and navigate
import { checkNativeUpdateAvailable } from "./NativeUpdateProvider";
// -- Eigenes Plugin ---------------------------------------
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { initLocalisation } from "./local";
import VuetifyPlugin from "./vuetifyplugin";
import "@/style.css";
import OurCommonPlugin from "./OurCommonPlugin";
import Constants from "./Constants";
import { Capacitor } from "@capacitor/core";

// --- LEAFLET ------
import 'leaflet/dist/leaflet.css';
import { Icon } from "leaflet";
import { importComponents } from './componentImporter';
import { getDeviceInfo } from './DeviceInfoPlugin';
// delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.config.productionTip = false;


function initApp() {
  console.log("loading app");

  console.log("init...");

  importComponents(require.context("./editor", true, /.+\.(vue|js|ts)$/));
  importComponents(require.context("./views", true, /.+\.(vue|js|ts)$/));
  importComponents(require.context("./components", true, /.+\.(vue|js|ts)$/));

  initLocalisation();

  // axios Content-Type fÃ¼r json setzen
  axios.interceptors.request.use((request: InternalAxiosRequestConfig<any>) => {
    if (request && request.headers) {
      // tslint:disable-next-line: no-string-literal
      (request.headers as any)["ContentType"] = "application/json";
      // tslint:disable-next-line: no-string-literal
      (request.headers as any)["RequestTimestamp"] = new Date().toISOStringWithTZWithMs();
    }
    return request;
  });

  AUApi.Current = new AUApi();
  AUApi.Current.init();

  // CommmonPlugin um alle Vue Funktionen die wir so verwenden wollen zu erweitern
  Vue.use(OurCommonPlugin);

  Vue.use(SchedulePlugin);
  Vue.use(TooltipPlugin);
  Vue.use(NumericTextBoxPlugin);
  Vue.use(CheckBoxPlugin);
  Vue.use(ComboBoxPlugin);
  Vue.use(RadioButtonPlugin);
  Vue.use(SwitchPlugin);
  Vue.use(UploaderPlugin);
  Vue.use(DatePickerPlugin);
  Vue.use(DateTimePickerPlugin);
  Vue.use(CalendarPlugin);
  Vue.use(TimePickerPlugin);
  Vue.use(DateRangePickerPlugin);
  Vue.use(ListViewPlugin);
  Vue.use(TextBoxPlugin);
  Vue.use(InPlaceEditorPlugin);
  enableRipple(true);

  // -- Los gehts ---------------------------------------
  // const { StatusBar } = Plugins;
  // StatusBar.setOverlaysWebView({overlay: false});

  // -- iOS Scaling verhindern --------------------------
  const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      const re = /maximum\-scale=[0-9\.]+/g;

      if (content && re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      el.setAttribute('content', content);
    }
  };

  const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
  const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  if (checkIsIOS()) {
    disableIosTextFieldZoom();
  }
  // -- iOS Scaling verhindern --------------------------
  new Vue({
    vuetify: VuetifyPlugin,
    provide: {
      Schedule: [Day, Week, WorkWeek, Month, Agenda]
    },
    render: (h: any) => h(App),
    data: {
      isMobile: false
    }
  }).$mount("#app");
}

let iframe: HTMLIFrameElement;
let oldX = 0;
let oldY = 0;
function resizeFrame() {
  if (window.innerWidth === oldX && window.innerHeight === oldY) { return; }

  oldX = window.innerWidth;
  oldY = window.innerHeight;

  console.log("setting frame size and style...");

  iframe.style.width = "100%"; // window.innerWidth + "px";
  // iframe.style.height = "100%";
  if (!Constants.PlatformAndroid) {
    iframe.style.height = (window.innerHeight - 25) + "px"; // bei - 25 zu hoher Abstand unten...
  } else {
    iframe.style.height = window.innerHeight + "px";
  }
  iframe.style.margin = "0px";
  // iframe.style.border = "3px solid green"; // grÃ¼ner Rahmen bei der App weg!
  // alert("resized " + window.innerWidth + "x" + window.innerHeight);
  console.log("resize - height:" + iframe.style.height);

  setTimeout(resizeFrame, 500);
}

try {

  console.log('checking native update');
  checkNativeUpdateAvailable().then(nativeCheck => {
    console.log('result of updateCheck' + nativeCheck);

    if (ALLConfig.debug) {
      console.warn("skipping local because debug is active!");
      nativeCheck = false;
    } else {
      console.log("nativeCheck:" + nativeCheck + "Update sollte dann starten");
    }

    if (!nativeCheck) {
      initApp();
    } else {
      // do nothing ?
      console.log("native update available - getting redirected");

      const frame = iframe = document.createElement("iframe");

      frame.src = Constants.indexFile;

      resizeFrame();
      const appdiv = document.getElementById("app");
      appdiv?.append(frame);

      (frame.contentWindow as any).Capacitor = Capacitor;
      (frame.contentWindow as any).Cordova = (window as any).Cordova;
      (frame.contentWindow as any).cordova = (window as any).cordova;
      (frame.contentWindow as any).reloadApp = () => window.location.reload();
      (frame.contentWindow as any).resizeFrame = () => resizeFrame;
      window.addEventListener("resize", resizeFrame, { passive: true });
    }
  }).catch(e => alert("Fehler beim Initialisieren der App: " + e));
} catch (e) {
  alert("Fehler beim Initialisieren der App: " + e);
}

if (Capacitor.getPlatform() !== "web") {
  Keyboard.addListener("keyboardDidShow", resizeFrame);
  Keyboard.addListener("keyboardDidHide", resizeFrame);
}
