



import { Vue, Component, Prop, Provide } from "vue-property-decorator";
// import { Globals } from "@/Globals"; // kÃ¶nnte man das in die EditComponent rein hÃ¤ngen?
import EditComponent from "./EditComponent";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  checkAndInitializeMap,
  fitMapBounds,
  flyMapTo,
  MapMarker,
  MapPolyLine,
  unbindMapFromSite
} from "../MapHelper";

// basemap.at - mal anschauen - gratis!
@Component
export default class EditMap extends EditComponent {
  @Prop() protected Abflatlong!: any[];
  @Prop() protected Anklatlong!: any[];
  @Prop() protected standort: {lat: number, lng: number} | undefined;
  @Prop() protected kmAnzeige!: string;
  @Prop() protected Abfahrtsort!: string;
  @Prop() protected Ankunftsort!: string;
  private lat = 47.92525;
  private long = 16.87635;
  private abflat = 48.20798;
  private abflong = 15.62468;
  private ortLayer: any = L.layerGroup();
  private initalized: boolean = false;
  private mymap?: L.Map;
  private popup = L.popup();


  public mounted() {
    const logo = L.icon({
      iconUrl: "map_marker.png",
      iconSize: [41, 41],
      iconAnchor: [21, 41]
    });
    const marker: MapMarker[] = [];
    const lines: MapPolyLine[] = [];
    let bounds: [[number, number], [number, number]] | undefined;
    if (this.standort) {
      marker.push({
        lat: this.standort.lat,
        long: this.standort.lng,
        icon: logo,
        text: "Standort"
      });
    } else {
      marker.push(
        {
          lat: this.Abflatlong[0],
          long: this.Abflatlong[1],
          text: "Abfahrtsort: " + this.Abfahrtsort
        },
        {
          lat: this.Anklatlong[0],
          long: this.Anklatlong[1],
          text: "Ankunftsort: " + this.Ankunftsort
        }
      );

      bounds = [
        [this.Abflatlong[0], this.Abflatlong[1]],
        [this.Anklatlong[0], this.Anklatlong[1]]
      ];

      lines.push({
        points: [
          [this.Abflatlong[0], this.Abflatlong[1]],
          [this.Anklatlong[0], this.Anklatlong[1]]
        ],
        color: "green",
        weight: 10,
        opacity: 1,
        text: ""
      });
    }
    checkAndInitializeMap(
      this.lat,
      this.long,
      this.$refs.map as HTMLDivElement,
      { marker, lines }
    );

    this.initalized = true;

    console.log("showing lines:" + JSON.stringify(lines));
    console.log("showing marker:" + JSON.stringify(marker));
    if (bounds) {
      fitMapBounds(bounds!);
    } else if (this.standort) {
      flyMapTo([this.standort.lat, this.standort.lng]);
    }
  }
  public unmounted() {
    unbindMapFromSite();
  }
  public beforeUnmount() {
    unbindMapFromSite();
  }
  public beforeDestroy() {
    unbindMapFromSite();
    // if (this.mymap) {
    //   this.mymap.remove();
    // }
  }

  // private layer = L.marker([this.lat, this.long], { icon: this.icon })
  //   .addTo(this.mymap)
  //   .bindPopup("I am here.")
  //   .openPopup();
  // private circle = L.circle([this.lat, this.long], {
  //   color: "grey",
  //   fillColor: "#f03",
  //   fillOpacity: 0.5,
  //   radius: 100
  // }).addTo(this.mymap);
  // private popup = L.popup() // extra popup - zB fÃ¼r Firmenadresse interessant...
  //   .setLatLng([this.lat, this.long])
  //   .setContent("I am a standalone popup.")
  //   .openOn(this.mymap);â
  private onMapClick(e: any) {
    console.log("markierte Position:" + e);

    if (this.mymap && e) {
      this.popup
        .setLatLng(e.latlng)
        .setContent("<p>" + e.latlng.lat + "," + e.latlng.lng + "</p>")
        .openOn(this.mymap);
    }
  }
}
