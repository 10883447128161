










































































































































































































import AUApi from "../api";
import { Vue, Component, Prop } from "vue-property-decorator";
import RoleMenuMapping from "@/RoleMenuMapping";
import CryptoJS from "crypto-js";
import { Capacitor } from "@capacitor/core";
import {
  CheckVersion,
  curentALLVersion,
  setUpdateVersionCallback,
} from "../VersionChecker";
/*  nativChecken ob update vorhanden  */
import { downloadNativeUpdate, reloadApp } from "../NativeUpdateProvider";
import Constants from "@/Constants";
import { encryptForAD } from "@/ADCrypter";
import { makeRequest } from "@/RequestHandler";
import { setCurrentUser } from "@/UserInfo";
import PasswordValidator from "@/PasswortValidator";
import { showSnackbar } from "@/UIHelper";
import {
  doAzureLogin,
  getAzureAccountName,
  getAzureToken,
  getAzureUserName,
  SetAzureLoginHelper,
} from "@/LoginHelper/AzureLoginHelper";
import { doGoogleLogin } from "@/LoginHelper/GoogleLoginHelper";

import { loadLocalData, saveLocalData } from "@/BrowserDataStore";
import {
  showNativeLoginDialog,
  getDeviceInfo,
  setDeviceInfoCallback,
} from "@/DeviceInfoPlugin";
import {
  GetDisplayName,
  GetLoginHelper,
  GetUserName,
  isBiometricAvailable,
  getBioCredential,
  setBioCredential,
  deleteBioCredentials,
  hasBioCredentialsStored
} from "@/LoginHelper/LoginHelper";

declare var ALLConfig: ALLConfigType;
declare interface LoginCache {
  selectedDB?: string;
}

declare interface LoginData extends ALLUser {
  info: string;
  LongToken: string;
  art: number;
}

@Component
export default class AllLogin extends Vue {
  @Prop() public siteData: any;
  @Prop() public app: any;

  private showLoading: boolean = false;
  private showPassword: boolean = false;
  private showWelcome: boolean = false;
  private valid: boolean = false;
  private DBs: AllConfigDB[] = [];
  private selectedDB: AllConfigDB | undefined;
  private Roots?: AllConfigRoot[] = [];
  private selectedRoot: AllConfigRoot | undefined;
  private azureConfig: AzureConfig | undefined;

  private userfriendlyname: string = "";
  private avatar: any;
  private successText: string = "";
  private errorText: string = "";
  private debugText: string = "";
  private Benutzer: any = null;

  private name: string = "";
  private password: string = "";
  private mandant: string = "-2";
  private hash?: string;
  private art: string = "1";
  private useLoginHelper: boolean = false;

  private isMobile: boolean = false;
  private initialized: boolean = false;
  private showDB: string = "";

  private azureLoginActive = false;
  private googleLoginActive = false;
  private isNativeLoginNeeded: boolean = false;

  private loginArt?: number;
  private timer?: number;

  private passwordValid: boolean = true;
  private passwordMessage: string = "";
  private bioAvailable = false;
  public created() {
    this.isMobile = Capacitor.getPlatform() !== "web";
  }
  public async mounted() {
    const me = this;
    setDeviceInfoCallback((di) => {
      me.isNativeLoginNeeded = di.useNativeLogin === true;
    });
    // Media Abfragen - und merken ob Handy oder Ipad..
    // und dann auf das statt isMobile prÃ¼fen
    this.isMobile = Capacitor.getPlatform() !== "web";
    // if (window.matchMedia('screen and (max-width: 768px)').matches) {}
    // this.isMobile = true; // Capacitor.getPlatform() !== "web";
    this.$api.onLoadConfig = () => me.initConfig();
    this.initConfig();
    if (this.DBs?.length === 1) {
      this.showDB = this.DBs[0].name;
    }

    // this.timer = setInterval(() => me.checkPasswordAutoFill(), 300);
    getDeviceInfo(true).then((t) => {
      me.isNativeLoginNeeded = t.useNativeLogin === true;
    });
    this.bioAvailable = (await isBiometricAvailable()) && hasBioCredentialsStored();
  }

  public unmounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  private checkPasswordAutoFill() {
    if (!this.showWelcome) {
      let pwdEl: HTMLInputElement | undefined;
      const snurzpups = this.$refs.password as any;
      if (snurzpups?.length) {
        pwdEl = snurzpups[0].$el;
      } else {
        pwdEl = snurzpups?.$el;
        // snurzpups.$emit("focus");
      }

      if (pwdEl) {
        if (pwdEl.value && !this.password && pwdEl.value !== this.password) {
          console.log("autofill detected!", pwdEl.value, this.password);
          this.password = pwdEl.value;
        }
      }
      if (this.password) {
        this.valid = true;
        if (this.timer) {
          clearInterval(this.timer);
        }
      }
    }
  }

  private onFocusPW() {
    // setTimeout(() => {
    //   const compPW = this.$refs.password as any;
    //   compPW.$emit("focus");
    // });
  }
  private get showDebug() {
    return Constants.isDebug;
  }
  private get plattform() {
    return Capacitor.getPlatform();
  }

  private saveDB() {
    saveLocalData<LoginCache>(
      "loginCache",
      {
        selectedDB: this.selectedDB?.name,
      },
      false
    );
  }

  private showNativeLogin() {
    const me = this;
    showNativeLoginDialog()
      .then((res) => {
        if (res && res.success && res.user && res.pwd) {
          me.name = res.user;
          me.password = res.pwd;
          me.handleSubmit();
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  private async initConfig() {
    this.checkAppVersion();
    this.$root.$forceUpdate();
    this.DBs = this.$api.getDBs();
    this.initialized = ALLConfig.initialized === true;
    this.selectedDB = undefined;
    this.showDB = ""; // nach Qr Code Scannen - initalisieren das DEMO nicht hÃ¤ngen bleibt!

    const loginCache = loadLocalData<LoginCache | undefined>(
      "loginCache",
      undefined,
      false
    );
    if (loginCache?.selectedDB) {
      this.selectedDB = this.DBs.find((d) => d.name === loginCache?.selectedDB);
    } else if (this.$api.dB && this.DBs) {
      this.selectedDB = this.DBs.find((d) => d.db === this.$api.dB);
    }
    if (this.DBs?.length === 1 && this.selectedDB) {
      this.showDB = this.selectedDB.name;
    }
    this.Roots = this.$api.getRootUrls();
    this.azureConfig = ALLConfig.azureConfig;
    this.selectedRoot = undefined;
    if (this.$api.rootUrl) {
      this.selectedRoot = this.Roots?.find((d) => d.rootUrl === this.$api.rootUrl);
    }
    // 23.2.2022 ein automatisches neu einloggen - soll NICHT bei der APP passiereN!
    const cookie = this.$api.getLocalData();
    if (cookie !== undefined && cookie != null && !this.isMobile) {
      this.successText = "automatische Neuanmeldung!"; // Web_autom_Neuanmeldung
      this.doLogin(cookie);
    }

    if (ALLConfig.azureConfig) {
      this.azureLoginActive = true;

      getAzureToken().then((token) => {
        const accountName = getAzureUserName();
        if (!token || !accountName) {
          // Meldung weg... kommt immer auch wenn man nicht mit AZURE versucht einzuloggen
          // showSnackbar({
          //   text: "Bitte Azure-Anmeldung erneuern!",
          //   duration: 2000,
          //   color: "orange",
          // });
          //   checkRefreshToken().then((r) => {
          //   if (r) {
          //     this.handleAzureLogin(r);
          //   }
          // });
        } else {
          this.handleAzureLogin(token);
        }
      });
    }
    // if (ALLConfig.googleConfig) {
    //   this.googleLoginActive = true;
    // }
  }

  private async doBioLogin() {
    const me = this;
    try {
      const cred = await getBioCredential();
      if (cred) {
        me.art = "7";
        me.hash = cred.hash;
        me.name = cred.user;
        me.Benutzer = cred.user;
        await me.handleSubmit();
      }
    } catch (error) {
      const errorText = "" + error;
      if (
        errorText.indexOf("No credentials found") >= 0 ||
        errorText.indexOf("KeychainError") >= 0
      ) {
        me.errorText =
          "Keine Anmeldedaten gefunden - bitte das erste Mal anmelden und danach kÃ¶nnen die Daten gespeichert werden.";
      } else {
        me.errorText = "Fehler: " + error;
        console.error(error);
      }
    }
  }

  private handleAzureLogin(token: string) {
    if (token) {
      // LoginHelper neu setzen weil token ja da is...
      SetAzureLoginHelper();
      this.successText = "Willkommen " + getAzureAccountName();
      this.useLoginHelper = true;
      if (!this.name) {
        this.name = getAzureUserName();
      }
      this.handleSubmit();
    }
  }

  private validate() {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }
  private checkName(e: KeyboardEvent) {
    if (!PasswordValidator.isValidNameChar(e.key)) {
      showSnackbar({
        duration: 500,
        color: "red",
        text: "Eingabe von " + e.key + " nicht erlaubt!",
      });
      e.cancelBubble = true;
      e.preventDefault();
    }
    return false;
  }
  private checkEnter(e: KeyboardEvent) {
    if (e && e.keyCode === 13) {
      this.handleSubmit();
    }
  }
  private checkEnterInput(password: string) {
    if (password) {
      this.valid = true;
      if (this.password !== password) {
        this.password = password;
      }
    }
  }

  private async doAzureLogin() {
    try {
      const res = await doAzureLogin();
      if (res) {
        await this.handleLoginWithHelper();
      }
    } catch (ex) {
      this.errorText = "Error on login:" + ex;
    }
  }

  private async doGoogleLogin() {
    try {
      const res = await doGoogleLogin();

      if (res) {
        await this.handleLoginWithHelper();
      }
    } catch (ex) {
      this.errorText = "Error on login:" + ex;
    }
  }

  private async handleLoginWithHelper() {
    this.useLoginHelper = true;
    this.successText = "Willkommen " + (await GetDisplayName());
    if (!this.name) {
      this.name = await GetUserName();
    }
    this.handleSubmit();
  }

  private async doLogin(loginData: LoginData | string, reload?: boolean) {
    this.showLoading = true;
    this.debugText = JSON.stringify(loginData);
    let userdata: LoginData;
    if (typeof loginData === "string") {
      const parsed: LoginData | LoginData[] = JSON.parse(loginData);
      userdata = (parsed as LoginData[])?.length
        ? (parsed as LoginData[])[0]
        : (parsed as LoginData);
    } else {
      userdata = loginData;
    }
    if (this.DBs && this.DBs.length > 0 && userdata.dB) {
      this.selectedDB = this.DBs.find((d) => d.db === userdata.dB);

      if (this.selectedDB) {
        console.log("logging into db " + JSON.stringify(this.selectedDB));
        this.$api.dB = this.selectedDB.db;
      }
    }
    if (this.Roots?.length) {
      if (this.selectedRoot) {
        console.log("using RootUrl" + JSON.stringify(this.selectedRoot));
        this.$api.rootUrl = this.selectedRoot.rootUrl;
      }
    }
    setCurrentUser(userdata);
    if ((reload && this.$api.user.code) || (!this.password && this.loginArt === 8)) {
      // bei Reload soll kein neuer 2FA Code notwendig sein
      // bei loginArt 8 == longToken && 2FA - darf ich nur bei LongToken Ã¼bergabe 2FA abfragen - sonst nicht mehr!
      this.$api.user.code = false;
    }
    if (this.name) {
      this.$api.user.kennung = this.name;
    }

    if (loginData instanceof Object && loginData.LongToken) {
      if (await isBiometricAvailable()) {
        // ist LongToken & 2FA eingestellt - soll wenn User Ã¼ber LongToken reinkommt - keine 2FA gweprÃ¼ft werden!
        await setBioCredential(loginData.kennung, loginData.LongToken);
      }
    }

    console.log(
      "got user: id: " +
        this.$api.user.id +
        " aic: " +
        this.$api.user.aic +
        " error: " +
        this.$api.user.error
    );
    this.Benutzer = (this.$root as any).userData = this.$api.user;
    // error PrÃ¼fung VOR der PrÃ¼fung ob es admin & AIc gibt - sonst kommt die falsche Meldung
    if (this.$api.user.error > 0) {
      // ist das login mit Token prÃ¼fe ich ob es einen Error gibt
      console.error("FEHLER Login: " + userdata.info);
      this.errorText = userdata.info;
      this.password = "";
      this.successText = "";
      // ist der Token abgelaufen - dann sollen auch die Cred.. gelÃ¶scht werden!
      if (this.$api.user.error === 13) {
        //    this.successText = "Fehlertyp / errorcode " + +loginData.status;
        this.bioAvailable = false;
        await deleteBioCredentials();
      }
      setTimeout(
        () =>
           this.$api.logout().then((response: any) => {
            reloadApp(true);
          }),
        6000
      );
      return;
    }
    // console.log("User: " + JSON.stringify(this.$api.user));
    if (!this.$api.user.aic && !this.$api.user.admin) {
      this.errorText =
        "Dem Benutzer wurde kein Stammsatz zugeordnet - keine persÃ¶nliche Erfassung mÃ¶glich!";
      // ist der User kein ADMIN & hat keinen Stammsatz hinterlegt - dann gibt es keine Formulare zu Ã¶ffnen!
      this.password = "";
      this.successText = "";
      setTimeout(
        () =>
          this.$api.logout().then((response: any) => {
            reloadApp(true);
          }),
        6000
      );
      // this.$api.logout().then((response: any) => {
      //   reloadApp(true);
      // });
      this.showLoading = false;
      return;
    }

    if (!this.$api.user.tt) {
      // gibt es ein tt Wert - dann gibt es kein 1x... dann darf das nicht geprÃ¼ft werden
      if (!/^\d+x\d+$/.exec("" + this.$api.user.id)) {
        this.errorText = userdata.info;
        // this.errorText = "User nicht gefunden!";
        this.$api.eraseLocalData();
        this.password = "";
        return;
      }
    }
    if (!this.$api.user.startArray) {
      // gibt es kein Startformular - dann ausloggen!
      this.errorText = "Es wurde kein Startformular gefunden! Sie werden abgemeldet!";
      this.password = "";
      this.successText = "";
      setTimeout(
        () =>
          this.$api.logout().then((response: any) => {
            reloadApp(true);
          }),
        6000
      );
      return;
    }
    this.userfriendlyname = userdata.bezeichnung;
    this.showWelcome = true;
    // JokerStt Firma immer setzen..
    if (this.$api.user.firma) {
      const firma = this.$api.user.firma;
      this.$api.setSyncStamm(firma.stt, firma.aic);
    }
    // speichern der aktuellen User Daten (this.$api.user)..
    this.$api.setLocalData(60);
    this.avatar = this.$api.getUserAvatar();
    this.successText = "init....";
    const me = this;
    // const getAdmin = await this.$api.getAdmin();
    // if (getAdmin.data.admin === true) {
    //   // wenn Admin noch erlaubt - dann auf Admin Masken umschalten!
    // }
    this.$api.getGlobal(
      () => {
        RoleMenuMapping.InitializeAppByRole(userdata, me);
        if (userdata.startArray) {
          me.$emit("login", true, {
            pwValid: me.passwordValid,
            pwMessage: me.passwordMessage,
          });
        } else {
          me.$emit("login", false);
          console.error("Login failed!");
        }
      },
      (txt: any) => {
        me.successText = txt;
        console.log(txt);
      },
      (txt: any) => {
        me.successText = "";
        me.errorText = txt;
        console.error(txt);
        me.$emit("login", false);
        console.error("Login failed!");
      }
    );
  }
  // PW verschlÃ¼sseln
  private async handlePassword(res: any) {
    if (res === undefined) {
      this.errorText =
        "Der Webserver meldet sich nicht zurÃ¼ck - bitte wenden Sie sich an Ihe EDV!";
      return;
    }

    // meldung entfernt - da Roland die bestehende Verbidnung dann weiter verwendet wird..
    // const anzahlLogin = res.data.anzahlLog ?? 0;
    // if (anzahlLogin > 0) {
    //   // Meldung an User schicken - ob trotzdem anmelden...
    //   if (
    //     confirm(
    //       this.$globalsBezeichnung(
    //         this.Globals.Begriff,
    //         "Web_BenutzerAngemeldet",
    //         "Der Benutzer ist bereits angemeldet -
    // wollen Sie diesen ausloggen und neu einsteigen? /
    // The user is already logged in - do you want to log him out and log in again?"
    //       )
    //     )
    //   ) {
    //     // wenn ok - dann anmelden
    //   } else {
    //     this.errorText = this.$globalsBezeichnung(
    //       this.Globals.Begriff,
    //       "Web_Anmelden_abgebrochen",
    //       "Anmelden abgebrochen / Registration canceled"
    //     );
    //     return;
    //   }
    // }

    const pwminLength = res.data.pwML;
    const pwminDigit = res.data.pwMZ;
    const pwminSp = res.data.pwMS;
    const pwminUpperCase = res.data.pwMG;
    const pwminLowerCase = res.data.pwMK;
    const pwAblauf = res.data.pwAblauf;
    const userArt = res.data.art;
    this.loginArt = userArt;
    if (userArt !== 4 && userArt !== 3 && userArt !== 5 && this.art !== "7") {
      // 2.. sehr sicher, 3.. LDAP, 4.. Azure, 5.. Google,
      // 6 .. Long Token, 7 .. Ã¼ber E-Mail, 8 .. Kombi LongToken/E-Mail
      // bei 6 = login mit LongToken - da darf ich nicht auf das PW prÃ¼fen
      // bei AZURE & Idap darf diese PrÃ¼fung nicht stattfinden!
      const heute = new Date().toISOString().substr(0, 10);
      if (pwAblauf <= heute) {
        this.passwordValid = false;
        this.passwordMessage = "Passwort abgelaufen!";
      } else {
        const response = PasswordValidator.validate(
          this.password,
          pwminLength,
          pwminDigit,
          pwminSp,
          pwminUpperCase,
          pwminLowerCase
        );
        this.passwordValid = response.isValid;
        this.passwordMessage = response.message;
      }
    }
    const userAic = res.data.aic;
    const userDatum = res.data.datum;
    if (userArt === 1) {
      // 1 sollte es nicht mehr geben

      const seed = this.password + userAic;
      this.hash = CryptoJS.MD5(seed).toString();
      // console.log("pswHash:" + this.hash);
      this.art = "4";
      return;
    } else if (userArt === 2 || userArt === 7) {
      // 6 ist ohne PW - gilt aber nur fÃ¼r APP!
      const seed = userAic + "$" + this.password + "#" + userDatum;
      this.hash = CryptoJS.MD5(seed).toString();
      this.art = "4";
      // console.log("pswHash:" + this.hash);
      return;
    } else if (userArt === 3) {
      // LDAP PrÃ¼fung....
      const seed = this.password;
      this.hash = encryptForAD(seed);
      // console.log("pswHash:" + this.hash);
      this.art = "2";
      return;
    } else if (userArt === 4) {
      this.art = "5";
      // AZURE PrÃ¼fung....
      this.useLoginHelper = true;
      return;
    } else if (userArt === 5) {
      this.art = "6";
      // google PrÃ¼fung....
      this.useLoginHelper = true;
      return;
    } else if ((userArt === 6 || userArt === 8) && this.art !== "7") {
      // 6 = long Token, 8 = Longtoken mit 2FAuth
      this.art = "4";
      try {
        const seed = userAic + "$" + this.password + "#" + userDatum;
        this.hash = CryptoJS.MD5(seed).toString();
      } catch (error) {
        console.error(error);
      }
      // Long Token
      return;
    }
  }
  private onNewConfig() {
    this.initConfig();
    this.initialized = ALLConfig.initialized = true;
  }
  private checkAppVersion() {
    const me = this;

    if (this.$api.rootUrl) {
      CheckVersion().then((t) => {
        curentALLVersion.warVersion = t.warVersion;
        curentALLVersion.webVersion = t.webVersion;
        console.log(curentALLVersion.warVersion);
      });
    }
  }
  private onConfigError(err: string) {
    this.errorText = err;
  }
  private onConfigSuccess(txt: string) {
    this.successText = txt;
  }

  private async handleSubmit() {
    this.errorText = "";
    this.valid = false;
    let DB;
    if (this.selectedDB) {
      DB = this.$api.dB = this.selectedDB.db;
    } else {
      DB = this.$api.dB;
    }
    if (!DB) {
      this.errorText = "Bitte Datebank auswÃ¤hlen!";
      return;
    }
    let selectUrl = this.$api.rootUrl;
    if (this.selectedRoot) {
      selectUrl = this.selectedRoot.rootUrl;
    }

    if (!this.name) {
      this.errorText = this.errorText = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_BenutzerAngeben",
        "Bitte Benutzer angeben!"
      );
      return;
    }
    const Kennung = this.name; // aus Response dann aic.. etc auslesen
    if (!this.password && !this.useLoginHelper && this.art !== "7") {
      this.errorText = this.Globals.Begriff("Web_PW_angeben", "Bitte Passwort angeben!");
      return;
    }
    const me = this;
    try {
      if (this.useLoginHelper) {
        const userdata = await this.$api.getUserData(DB, Kennung, selectUrl);
        if (userdata) {
          const noMobileDevice = userdata.data.noMobile;
          const phone = window.innerWidth < Constants.MobileWidthThreshold;
          if (noMobileDevice && phone) {
            const txt = this.$globalsBegriffMemo(
              this.Globals.Begriff,
              "Web_keinLoginMobile",
              "Login auf Smartphones nicht erlaubt!"
            );
            console.error(txt + "phone: " + phone);
            this.errorText = txt;
            this.password = "";
            this.successText = "";
            return;
            // abbrechen wenn Handy...
          }
        }

        await this.handlePassword(userdata);

        // nun PW verschlÃ¼sseln
        me.errorText = this.successText = "";
        me.showLoading = true;
        let url = this.$api.fullUrl("loginT", "webLog/");
        // nur bei art 6 == google - darf auf loginG gestellt werden!
        if (this.art === "6") {
          url = this.$api.fullUrl("loginG", "webLog/");
        }
        const token = await GetLoginHelper().getToken();
        const response = await makeRequest<LoginData>({
          url,
          method: "POST",
          contentType: "application/x-www-form-urlencoded",
          data: {
            name: this.name,
            token,
            db: this.$api.dB,
            mandant: this.mandant,
          },
        });

        me.$nextTick(() => {
          me.doLogin(response.data);
        });
      } else {
        const userdata = await this.$api.getUserData(DB, Kennung, selectUrl);
        await this.handlePassword(userdata);
        // bei Mehrfachanmeldung kann der User das anmelden abbrechen...
        if (this.errorText) {
          this.password = "";
          this.successText = "";
          return;
        }
        if (userdata) {
          const noMobileDevice = userdata.data.noMobile;
          const phone = screen.availWidth < 800;
          console.log("Handy: " + phone + ", screenWidth: " + screen.availWidth);
          if (noMobileDevice && phone) {
            const txt = this.$globalsBegriffMemo(
              this.Globals.Begriff,
              "Web_keinLoginMobile",
              "Login auf mobilen GerÃ¤ten nicht erlaubt!"
            );
            console.error(txt + "phone: " + phone);
            this.errorText = txt;
            this.password = "";
            this.successText = "";
            return;
            // abbrechen wenn Handy...
          }
          // nun PW verschlÃ¼sseln
          me.errorText = this.successText = "";
          me.showLoading = true;
          const url = this.$api.fullUrl("login", "webLog/");
          console.log("Login daten: art: " + this.art);
          const response = await makeRequest<LoginData>({
            url,
            method: "POST",
            contentType: "application/x-www-form-urlencoded",
            data: {
              name: this.name,
              password: this.hash ?? "",
              db: this.$api.dB,
              mandant: this.mandant,
              art: this.art,
            },
          });
          me.$nextTick(() => {
            me.doLogin(response.data);
          });
        }
      }
    } catch (ex) {
      // ein Error heraussen reicht
      me.handleLoginError(ex);
      // me.handleLoginError(new AxiosError("" + ex) );
      // im Axios Error kommt nur message und name - aber kein Response vom Webserver
      // den brauchen wir aber fÃ¼r die Meldungen siehe unten...
    }
  }

  // private handleLoginError(ex: AxiosResponse<any>) {
  private async handleLoginError(ex: any) {
    console.error("bin im HandleLoginError: " + ex);
    this.password = "";
    const rueckgabe = ex;
    // Biometric Login lÃ¶schen wenn vorhanden
    this.bioAvailable = false;
    await deleteBioCredentials();

    this.errorText =
      "Entschuldigung!\r\nLogin aus technischen GrÃ¼nden derzeit nicht mÃ¶glich! Bitte prÃ¼fen Sie Ihre Internetverbindung!";
    // Meldung: Web_Login_fehlgeschlagen
    if (ex.response?.status === 423) {
      this.errorText = ex.response.data?.info;
      // me.errorText = "Es lÃ¤uft ein Update! Einloggen derzeit nicht mÃ¶glich.";
      this.successText = "Fehlertyp / errorcode " + ex.response.status;
      // } else if (ex.bodyText !== undefined) {
      //   this.password = "";
      //   console.error("Login failed!", ex.bodyText);
      // } else if (ex.data !== undefined) {
      //   this.password = "";
      //   console.error("Login failed!", ex.data);
    } else if (ex?.response?.data?.info) {
      this.errorText = ex.response.data.info;
      if (this.errorText.indexOf("user not") >= 0) {
        this.name = "";
      }
      this.successText = "Fehlertyp / errorcode " + +ex.response.status;
    } else {
      console.error("App-Login failed!", ex);
    }
    this.showLoading = false;
  }
}
