import Caching from "./Caching";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding, FileWriteOptions, WriteFileOptions } from '@capacitor/filesystem';
import Constants from "./Constants";

import { initHttpHandling, makeRequest, RequestConfig } from "@/RequestHandler";
import { reloadApp } from "./NativeUpdateProvider";
import { AxiosResponse } from "axios";
import { showSnackbar } from "./UIHelper";
import { getCurrentUser } from "./UserInfo";
import { IsLoginHelperSet, Logout } from "./LoginHelper/LoginHelper";
import { valueToFormatStr } from "@/editor/CalenderHelper";

declare var ALLConfig: ALLConfigType;

const COOKIE_NAME = "allunlimited";
const LOGOUT_URL = "webLog/logout/";
const defaultUrlSuffix = "webAbf/";
const defaultSaveUrl = "webSave/";
const UrlwebGlob = "webGlo/"; // Globale Datei mit allen Meldungen die zur VerfÃ¼gung stehen
const UrlCbo = "webCbo/"; // Ordner um Comboboxen die leer sind nachzuladen
const Urlcalc = "webCalc/";
const UrlwebLog = "webLog/";

export default class AUApi {
  public static Current: AUApi;
  public ApiBusyMessage: string = "Berechnung lÃ¤uft....";
  public onLoadConfig?: () => void;
  // Url zur DB
  // dBAppl: '.my8:188.20.96.198/appl_v14b_test',
  public get user(): ALLUser { return getCurrentUser(); }
  public mandant: any = { aic: 1 };

  // Systemdaten - definieren damit selbst wenn leer noch initialisiert!
  public Message = [];
  public Holiday: Holiday[] = [];
  public Stt: Stammtyp[] = [];

  public Begriff: Begriff[] = [];
  // public Bew = [];
  public Code: Code[] = [];
  // public Rollen = [];
  public Eigenschaft: Eigenschaft[] = []; // za wos?
  public SysAbfrage: SysAbfrage[] = []; // za wos?
  public Mandanten: Mandant[] = [];
  public Periodensperre: Periodensperre[] = [];
  public Security: Security[] = [];
  // public Sprachen = [];
  public Einheiten: Einheit[] = [];
  public SyncStamm: SyncStamm[] = [];
  public Ampel: Ampel[] = [];
  private date = new Date();
  // 9.9.2019 Zeitraum immer auf aktuellen Monat stellen!
  public zeitbereich: APIZeitbereich = {
    bereich: "Monat",
    von: new Date(this.date.getFullYear(), this.date.getMonth(), 1),
    bis: new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
    // von: new Date(2018, 10, 1),
    // bis: new Date(2018, 10, 31) // ab April 2019 darf es INKLSUIVE sein!
  };
  public stichtag: Date | null = null;

  private holidayYears: number[] = [];

  public rootUrl: string = ""; // 'http://82.218.187.26:8080/ALL/UNLIMITED/',
  public nativeRoot: string = "";
  // Ã¶ffentliche url wo der proxy gepublished ist.
  private proxy: string = ""; // "https://allunlimited.marekworks.at/?url=";
  public dB: string = ""; // '.my8:127.0.0.1/demo_2020';
  private inited: boolean;
  public isBusy: boolean = false;
  public setBusyState: ((isBusy: boolean) => void) | null = null;
  public showBusyMessage: (() => void) | null = null;

  constructor() {
    this.inited = false;
  }

  private setBusy(isBusy: boolean = true) {
    if (isBusy !== this.isBusy) {
      this.isBusy = isBusy;
      if (this.setBusyState) {
        this.setBusyState(isBusy);
      }
    }
  }

  private checkBusyAndSetIfAvailable() {
    if (!this.isBusy) {
      this.setBusy();
      return false;
    } else {
      if (this.showBusyMessage) {
        this.showBusyMessage();
      }
      return true;
    }
  }

  public getCurrentConfig() { return ALLConfig; }

  private async loadConfigFromDevice() {
    try {
      const fileparams = {
        path: "config.json",
        directory: Directory.Data,
        encoding: Encoding.UTF8
      };
      const fileresult = await Filesystem.readFile(fileparams);
      if (fileresult?.data) {
        if (fileresult.data instanceof Blob) {
          const json = await fileresult.data.text();
          ALLConfig = JSON.parse(json);
        } else {
          ALLConfig = JSON.parse(fileresult.data);
        }
      }
    } catch (err) {
      console.error("fehler im laden der config: " + err);
      this.initAfterLoadConfig();
    }
  }
  public saveAllConfig(config: ALLConfigType) {
    ALLConfig = config;
    this.initAfterLoadConfig();
    const data = JSON.stringify(config);
    const fileparams: WriteFileOptions = {
      path: "config.json", directory: Directory.Data, data, encoding: Encoding.UTF8
    };

    return Filesystem.writeFile(fileparams);
  }
  public async fileWrite(data: any, path: string) {
    try {
      const result = await Filesystem.writeFile({
        path,
        data,
        directory: Directory.Documents,
        encoding: Encoding.UTF8
      });
      console.log('Wrote file', result);
    } catch (e) {
      console.error('Unable to write file', e);
    }
  }

  public init() {
    initHttpHandling();

    if (Capacitor.getPlatform() !== "web") {
      this.loadConfigFromDevice()?.then(() => {
        this.initAfterLoadConfig();
      }).catch(e => {
        this.initAfterLoadConfig();
      });
    } else {
      this.initAfterLoadConfig();
    }


    // const me = this;
    // axios.get("public/config.txt").then((data) => {
    //     me.rootUrl = data.data.rootUrl;
    //     me.proxy = data.data.proxy;
    //     me.dB = data.data.dB;
    //     me.inited = true;
    // }).catch(this.onfail);
  }

  public initAfterLoadConfig() {
    if (!ALLConfig) {
      alert("KONFIGURATION UNGÃLTIG!!!");
      console.error("KONFIGURATION UNGÃLTIG!!!");
    }

    ALLConfig.initialized = ALLConfig?.rootUrl !== undefined;
    if (ALLConfig.rootUrl) {
      this.rootUrl = ALLConfig.rootUrl;
      // this.nativeRoot = this.rootUrl;
    } else {
      let path = "/ALL/UNLIMITED/";
      if (ALLConfig.path) {
        path = ALLConfig.path;
      }
      this.nativeRoot = window.location.href;
      this.rootUrl = window.location.origin + path;
      ALLConfig.initialized = true;
    }
    this.proxy = ALLConfig.proxy;
    this.dB = ALLConfig.dB;

    if (this.onLoadConfig) { this.onLoadConfig(); }
    this.inited = true;
  }

  public getAPIZeitbereich(zeitbereich: any): string {
    if (!zeitbereich) {
      zeitbereich = AUApi.Current.zeitbereich;
    }
    const parameter =
      this.getParameterDate(zeitbereich.von) +
      "-" +
      this.getParameterDate(zeitbereich.bis) +
      "-" +
      zeitbereich.bereich;
    return parameter;
  }
  public check() {
    console.log("servus bin da");
  }

  public getDBs() {
    return ALLConfig.DBs;
  }
  public getRootUrls() {
    return ALLConfig.roots;
  }

  public viewError!: (para: any) => void | undefined;
  public viewStammdatenUpdate!: (para: any) => void;
  public onfail(err: any) {
    console.error("## api error:" + err + " " + err.response.data.info);
    if (this && this.viewError) {
      let errorText = err;
      if (err.response.data.info) {
        if (err?.request?.status === 401) {
          errorText = err.response.data.info;
        } else {
          errorText = errorText + "\n" + err.response.data.info;
        }
      }
      this.viewError(errorText);
    }
    if (err?.request?.status === 401) {
      // console.log("error 401");
      const me = this;
      setTimeout(() => {
        showSnackbar({ text: "Anmeldung ungÃ¼ltig - Bitte erneut anmelden!", color: "red" });
        me.logout();
      }, 2000);
    }
  }

  private getParameterDate(date: Date): string {
    const year = date.getFullYear().toString();
    const month2Digit = ("00" + (date.getMonth() + 1).toString()).substr(-2);
    const day2Digit = ("00" + date.getDate().toString()).substr(-2);
    return year + month2Digit + day2Digit;
  }

  public fullUrl(
    url: string,
    suffix: string = "",
    skipProxy: boolean = false
  ): string {
    if (suffix === "") {
      suffix = defaultUrlSuffix;
    }
    url = this.rootUrl + suffix + url;
    if (this.proxy !== undefined && !skipProxy) {
      return this.proxy + encodeURIComponent(url);
    }
    return url;
  }
  private saveUrl(saveurl: string, savesuffix: string) {
    if (savesuffix === undefined) {
      savesuffix = defaultSaveUrl;
    }
    saveurl = this.rootUrl + savesuffix + saveurl;
    if (this.proxy !== undefined) {
      return this.proxy + encodeURIComponent(saveurl);
    }
    return saveurl;
  }

  private calcUrl(calcurl: string, calcsuffix: string = ""): string {
    if (calcsuffix === "") {
      calcsuffix = Urlcalc;
    }
    calcurl = this.rootUrl + calcsuffix + calcurl;
    if (this.proxy !== undefined) {
      return this.proxy + encodeURIComponent(calcurl);
    }
    return calcurl;
  }
  private webLogUrl(webLog: string, webLogsuffix: string = ""): string {
    if (webLogsuffix === "") {
      webLogsuffix = UrlwebLog;
    }
    webLog = this.rootUrl + webLogsuffix + webLog;
    if (this.proxy !== undefined) {
      return this.proxy + encodeURIComponent(webLog);
    }
    return webLog;
  }
  private GlobUrl(globurl: string, globsuffix: string = ""): string {
    if (globsuffix === "") {
      globsuffix = UrlwebGlob;
    }
    globurl = this.rootUrl + globsuffix + globurl;
    if (this.proxy !== undefined) {
      return this.proxy + encodeURIComponent(globurl);
    }
    return globurl; // alle Nachrichten laden
  }
  private cboUrl(cbourl: string, cbosuffix: string = ""): string {
    if (cbosuffix === "") {
      cbosuffix = UrlCbo;
    }
    cbourl = this.rootUrl + cbosuffix + cbourl;
    if (this.proxy !== undefined) {
      return this.proxy + encodeURIComponent(cbourl);
    }
    return cbourl; // alle Nachrichten laden
  }

  public get(
    url: string,
    suffix: string,
    success: any,
    aic: number | null,
    zeitbereich: APIZeitbereich | null,
    pagZeilen: number | null
  ) {
    if (!aic) {
      aic = this.user?.aic ?? null;
    }
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }

    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }

    const getOptions: RequestConfig = {
      method: "GET",
      url: this.fullUrl(url, suffix),
      dataType: "json",
      contentType: "application/json",
      headers: {
        aicStamm: aic, // aktuell immer MA AIC, AIC von LOGIN
        vecStamm: "", // array mit StammsÃ¤tzen
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis,
        pagZeilen
      }
    };

    const me = this;
    const p = makeRequest(getOptions)
      .then((x: any) => me.handleResponse(x, success))
      .catch((c: any) => me.onfail(c));
    return p;
  }

  // tslint:disable-next-line: member-ordering
  public getQuery(
    query: string,
    success?: (data: AxiosResponse<QueryResponse>) => void,
    aic: number | null = null,
    zeitbereich: APIZeitbereich | null = null,
    pagZeilen: number | null = 0,
    useCaching: boolean | null = false,
    bewegungsdaten: boolean = false,
    setztVector: string = "0",
    formular: number = 0,
    vecStamm?: any[],
    suche?: string,
    newData?: boolean,
    queryAic?: number,
    tabUbergabe?: any[],
    jokerStt?: any[],
    keinAustritt?: string,
  ): Promise<AxiosResponse<QueryResponse>> {
    const me = this;
    const d = new Date();
    // if (!formular && Constants.isDebug) {
    //   showSnackbar("kein Formular: " + query);
    // }
    if (this.user) {
      const tokenEnde = this.user.time;
      const tokenTime = new Date(tokenEnde);
      // ist das tokenEnde schon erreicht
      // - darf ich auch keine Statements mehr absetzen
      if (tokenTime.valueOf() < d.valueOf()) {
        me.onfail("Token" + " " + this.user.id + " " +
          this.user.time + " ist bereits abgelaufen - Sie werden abgemeldet");
        // zum Testen mal nicht ausloggen!
        setTimeout(() => {
          console.log("User wird ausgelogged");
          this.logoutUser();
        }, 6000);
      }
      // kein AIC = Neuanlage...darf ich nicht setzen - da die Modelle bei den ABfragen dann nicht greifen!
      if (!aic) {
        aic = this.user.aic;
      }
    }
    if (setztVector === "1") {
      aic = null;
    }
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }
    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }

    const nullStrCheck = (nr?: number | null) => {
      if (!nr) { return "0"; }
      return '' + nr;
    };
    const neuAnlage = 0;
    // if (newData) {
    //   neuAnlage = 1;
    // }
    // nicht verwendbar sobald die ABfrage vorgefÃ¼llt wird - was ich aber nicht weiÃ!
    const get: any = {
      method: "POST",
      url: this.fullUrl("getVec/"),
      dataType: "json",
      contentType: "application/json",
      sessionKey: this.user?.sessionKey,
      headers: {
        ID: "",
        abfrage: query,
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis,
        pagZeilen: nullStrCheck(pagZeilen), // bei 0 werden alle Zeilen geliefert ansonsten die angabenene Anzahl
        /*
        'pagAb': 0, //ab welcher Zeile sollen die Daten geliefert werden - 0 = 1 Zeile
        'pagNr': 0, //Nr der Abfrage  AicBegriff der Abfrage
        'filterTyp': 1, //0= kein, 1= alle, 2 = nur Ordner, 3 = Firma
        'firma': "", // aic Firma bei filterTyp
        neuAnlage, ... nochmal besprechen wie...
        */
        setztVector: setztVector ?? "0",
        formular,
        aicBegriff: queryAic,
        keinAustritt: keinAustritt ?? "0",
      },
      data: { suche: suche ?? "", vecStamm, Tab: tabUbergabe, jokerStt }
    };

    if (bewegungsdaten) {
      get.headers.aicBew = aic;
    } else {
      get.headers.aicStamm = aic; // aktuell immer MA AIC, AIC von LOGIN
    }

    if (useCaching) {
      const cached = Caching.Instance.checkCache(get);
      if (cached) {
        return new Promise((resolve, reject) => {
          if (success) {
            success(cached);
          }
          resolve(cached);
        });
      }
    }
    if (query || queryAic) {
      const p = makeRequest(get)
        .then((x: any) => {
          this.handleChanged(x);
          if (this.handleErrors(x)) {
            return;
          }
          if (useCaching) {
            get.headers.ID = "";
            Caching.Instance.cacheRequest(get, x);
          }
          if (success) {
            success(x);
          }
          return x;
        })
        .catch((c: any) => me.onfail(c));
      return p;
    }
    throw new Error("query fehlt" + query);
  }
  public getStammtyp(
    success: any,
    aicStt: number | null,
    zeitbereich: APIZeitbereich | null,
    useCaching: boolean | null = false,
    aicRolle?: number | null,
  ) {
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }
    // if (typeof zeitbereich === "object") {
    //   zeitbereich = this.getAPIZeitbereich(zeitbereich);
    // }
    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }
    const get: any = {
      type: "GET",
      url: this.cboUrl("cbo/"),
      dataType: "json",
      contentType: "application/json",
      sessionKey: this.user?.sessionKey,
      headers: {
        stt: aicStt,
        rolle: aicRolle,
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis
      }
    };

    if (useCaching) {
      const cached = Caching.Instance.checkCache(get);
      if (cached) {
        return new Promise((resolve, reject) => {
          if (success) {
            success(cached);
          }
          resolve(cached);
        });
      }
    }
    const me = this;

    if (aicStt) {
      const p = makeRequest(get)
        .then((x: any) => {
          this.handleChanged(x);
          if (this.handleErrors(x)) {
            return;
          }
          if (useCaching) {
            get.headers.ID = "";
            Caching.Instance.cacheRequest(get, x);
          }
          success(x);
          return x;
        })
        .catch((c: any) => me.onfail(c));
      return p;
    }
    console.error("aicSttVon fehlt");
  }
  public getHierarchie(
    success: any,
    aicEig: string | null,
    aicSttVon: number | null,
    aicSttBis: number | null,
    aicRolle: number | null,
    zeitbereich: APIZeitbereich | null,
    useCaching: boolean | null = false
  ) {
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }
    // if (typeof zeitbereich === "object") {
    //   zeitbereich = this.getAPIZeitbereich(zeitbereich);
    // }
    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }
    const get: any = {
      type: "GET",
      url: this.cboUrl("cboHierachieH/"),
      dataType: "json",
      contentType: "application/json",
      sessionKey: this.user?.sessionKey,
      headers: {
        aicEig,
        aicSttVon, // Stammtyp Beginn = Firma
        aicSttBis, // Stammtyp Ende == zB MA
        aicRolle,
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis
      }
    };

    if (useCaching) {
      const cached = Caching.Instance.checkCache(get);
      if (cached) {
        return new Promise((resolve, reject) => {
          if (success) {
            success(cached);
          }
          resolve(cached);
        });
      }
    }
    const me = this;

    if (aicSttVon) {
      const p = makeRequest(get)
        .then((x: any) => {
          this.handleChanged(x);
          if (this.handleErrors(x)) {
            return;
          }
          if (useCaching) {
            get.headers.ID = "";
            Caching.Instance.cacheRequest(get, x);
          }
          success(x);
          return x;
        })
        .catch((c: any) => me.onfail(c));
      return p;
    }
    console.error("aicSttVon fehlt");
  }
  public getHSStruktur(
    success: any,
    HS: number | null,
    zeitbereich: APIZeitbereich | null,
    useCaching: boolean | null = false
  ) {
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }
    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }
    const get: any = {
      type: "GET",
      url: this.calcUrl("struktur/", "webAbf/"),
      dataType: "json",
      contentType: "application/json",
      sessionKey: this.user?.sessionKey,
      headers: {
        HS,
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis
      }
    };

    if (useCaching) {
      const cached = Caching.Instance.checkCache(get);
      if (cached) {
        return new Promise<AxiosResponse<HSResponse>>((resolve, reject) => {
          if (success) {
            success(cached);
          }
          resolve(cached);
        });
      }
    }
    const me = this;

    if (HS) {
      const p = makeRequest<HSResponse>(get)
        .then((x: AxiosResponse<HSResponse>) => {
          this.handleChanged(x);
          if (this.handleErrors(x)) {
            return { data: { error: "no data" } } as unknown as AxiosResponse<HSResponse>;
          }
          if (useCaching) {
            get.headers.ID = "";
            Caching.Instance.cacheRequest(get, x);
          }
          success(x);
          return x;
        })
        .catch((c: any) => me.onfail(c));
      return p;
    }
    console.error("aicEignschaft fehlt");
  }
  // public getCboLoad(
  //   success: any,
  //   aicStt: string | null,
  //   suche: string | null,
  //   zeitbereich: APIZeitbereich | null,
  //   useCaching: boolean | null = false
  // ) {
  //   if (!zeitbereich) {
  //     zeitbereich = this.zeitbereich;
  //   }
  //   let von = null;
  //   let bis = null;
  //   if (zeitbereich.von) {
  //     von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
  //   }
  //   if (zeitbereich.bis) {
  //     bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
  //   }
  //   const get: any = {
  //     type: "GET",
  //     url: this.cboUrl("suche/"),
  //     dataType: "json",
  //     contentType: "application/json",
  //     sessionKey: this.user?.sessionKey,
  //     headers: {
  //       aicStt,
  //       suche,
  //       zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
  //       von,
  //       bis
  //     }
  //   };

  //   if (useCaching) {
  //     const cached = Caching.Instance.checkCache(get);
  //     if (cached) {
  //       return new Promise((resolve, reject) => {
  //         if (success) {
  //           success(cached);
  //         }
  //         resolve(cached);
  //       });
  //     }
  //   }
  //   const me = this;
  //   if (aicStt) {
  //     const p = makeRequest(get)
  //       .then((x: any) => {
  //         this.handleChanged(x);
  //         if (this.handleErrors(x)) {
  //           return;
  //         }
  //         if (useCaching) {
  //           get.headers.ID = "";
  //           Caching.Instance.cacheRequest(get, x);
  //         }
  //         success(x);
  //         return x;
  //       })
  //       .catch((c: any) => me.onfail(c));
  //     return p;
  //   }
  //   console.error("Stammtyp fehlt");
  // }
  // grundsÃ¤tzlich mÃ¼ssen alle responses da mal durch
  private handleResponse(x: any, success: ((x: any) => any) | null = null) {
    if (this.handleErrors(x)) {
      return;
    }
    this.handleChanged(x);
    if (success) {
      return success(x);
    }
    return x;
  }

  private handleChanged(x: any) {
    if (x.data && x.data && x.data.header && x.data.header.changed) {
      const changed: string[] = x.data.header.changed;
      if (changed.indexOf("periodenSperre") >= 0) {
        this.getGlobalPeriodensperre();
      }
      if (changed.indexOf("holidays") >= 0) {
        this.getGlobalHoliday();
      }
      if (changed.indexOf("stt") >= 0) {
        this.getGlobalStammtypen();
      }
      if (changed.indexOf("begriffe") >= 0) {
        this.getGlobalBegriffe();
      }
      if (changed.indexOf("codes") >= 0) {
        this.getGlobalCodes();
      }
      if (changed.indexOf("mandanten") >= 0) {
        this.getGlobalMandanten();
      }
      if (changed.indexOf("einheiten") >= 0) {
        this.getGlobalEinheiten();
      }
      if (changed.indexOf("security") >= 0) {
        this.getGlobalSecurity();
      }
      if (changed.indexOf("allSyncStamm") >= 0) {
        this.getGlobalSyncStamm();
      }
      if (changed.indexOf("ampel") >= 0) {
        this.getGlobalAmpel();
      }
      if (changed.indexOf("msg") >= 0) {
        this.getMessage(true).then((data: any) => {
          this.user!.msg = data?.data?.length ?? 0;
        });
      }
      // ui notification
      if (this.viewStammdatenUpdate) {
        this.viewStammdatenUpdate(changed.join(","));
      }
    }
    // if (x.data && x.data && x.data.header && x.data.header.msg) {
    //   this.user.msg = x.data.header.msg;
    // }
    if (x.data && x.data && x.data.header && x.data.header.alarm) {
      this.getGlobalPeriodensperre();
      // wird alarm 1 zurÃ¼ck geliefert, dann mÃ¼ssen die Periodensperren erneuert werden
    }
    if (x.data && x.data.header && x.data.header.vorfehler && x.data.header.vorfehler[0].error) {
      // Fehler ausgeben...
      showSnackbar({ text: "Modellfehler aufgetreten", color: "red" });
      console.error("Thread - Modell Fehler: " + x.data.header.vorfehler[0].error);
    }
  }

  private handleErrors(x: any): boolean {
    if (x && x.data && x.data.error) {
      // wenn Server meldet dass er nicht verbunden is dann abmelden...
      if (x.data.error === "nicht verbunden") {
        this.logoutUser();
        return true;
      }
    }
    return false;
  }

  public postBew(
    saveurl: string,
    aicBegriff: string,
    aicAbfAll: number,
    rowIndex: number,
    type: string,
    data: any,
    success: any,
    zeitbereich: any,
    aicStamm?: number,
    haupt?: number,
    getData?: number,
    aicStt?: number,
    jokerStt?: any[],
    varUbergabe?: any[],
  ) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }

    if (!rowIndex) {
      rowIndex = 0; // wenn kein rowIndex da dann mach ma einen
    }
    if (!data.aic) {
      data.aic = 0; // wenn kein aic da dann mach ma einen
    }
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }

    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }
    let aicStammUbergabe = this.user!.aic;
    if (aicStamm) {
      aicStammUbergabe = aicStamm;
    } else if ( rowIndex) {
      // gibt es schon einen BEW AIC - dann aicStamm NICHT Ã¼bergeben
      // da Roland dann die Haupt Relation darauf Ã¤ndert - egal ob es der richtige Stamm ist oder nicht
      // ab WAR 1.649 (v 5.18.11) behoben... ich lass es aber sicherheitshalber drinnen!
      aicStammUbergabe = 0;
    }
    if (!aicStt) {
      aicStt = 0;
    }
    const opt: any = {
      method: "POST",
      url: this.saveUrl(saveurl, ""),
      data: {
        save: data,
        jokerStt,
        var: varUbergabe
      },
      headers: {
        aicBegriff, // header.aicBegriff
        aicAbfAll,
        aicFom: 0, // derzeit leer also 0 nicht '' weil sonst nicht int ;)
        aicPool: rowIndex, // Zeilenindex bei tabelle
        aicStamm: aicStammUbergabe, // aktuell immer MA AIC, AIC von LOGIN
        anlage: type, // muss Eingabe oder Tabelle sein
        zone: "", // zone leer Ã¼bergeben und WEB prÃ¼ft dann!
        // zone: new Date().getTimezoneOffset() * -1, // Zeitzone des Benutzers (in minuten?)
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis,
        status: "save",
        haupt,
        getData,
        aicStt
      },
      dataType: "json",
      contentType: "application/json"
    };
    console.log(JSON.stringify(opt.data));

    return makeRequest(opt)
      .then((x: any) => this.handleResponse(x, success))
      .finally(() => this.setBusy(false));

    // wir verwenden am besten den Promise Stil bei den Calls fÃ¼r asyncrone abarbeitung...
    // .catch(AUApi.onfail);
  }

  public post(
    url: string,
    aicBegriff: string,
    type: string,
    data: any,
    success: any,
    zeitbereich: any,
    aicStamm?: number,
    stammtyp?: boolean,
    getData?: number,
    stichtag?: Date | null,
    jokerStt?: any[],
  ) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }
    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }
    let aicStammUbergabe = this.user!.aic;
    if (aicStamm) {
      aicStammUbergabe = aicStamm;
    }
    if (stammtyp && !aicStamm) { // bei neuem Stammtyp muss als aicStamm 0 Ã¼bergeben werden
      aicStammUbergabe = 0;
    }
    let stringStichtag = "1970-01-01";
    if (stichtag) {
      stringStichtag = valueToFormatStr(new Date(stichtag), "yyyy-MM-dd");
    }
    const opt: any = {
      method: "POST",
      url: this.saveUrl(url, ""),
      data: {
        save: data,
        jokerStt
      },
      headers: {
        aicBegriff, // header.aicBegriff
        aicFom: 0, // derzeit leer
        aicStamm: aicStammUbergabe, // aktuell immer MA AIC, AIC von LOGIN
        anlage: type, // muss Eingabe oder Tabelle sein
        stichtag: stringStichtag, // new Date().getTimezoneOffset() * -1,
        // "Content-Type": "application/json"
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis,
        getData
      },
      dataType: "json",
      contentType: "application/json"
    };
    return makeRequest(opt)
      .then((x: any) => this.handleResponse(x, success))
      .finally(() => this.setBusy(false));
  }
  public postForm(url: string, data: any, success: any) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    url = this.fullUrl(url, "");
    const params = {
      type: "POST",
      url,
      data,
      dataType: "json",
      contentType: "application/json"
    };
    return makeRequest(params)
      .then((x: any) => this.handleResponse(x, success))
      .catch(this.onfail)
      .finally(() => this.setBusy(false));
  }

  public postCalc(
    varUbergabe: any[],
    tabUbergabe: any[],
    aicBegriff: number,
    aic: number | undefined,
    zeitbereich: APIZeitbereich | undefined | null,
    abbruch: number,
    debug: number,
    bemerkung: string,
    success: any,
    maxBefehle?: number,
    vecStamm?: any[],
    aicBew?: number,
    jokerStt?: any[],
  ) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    if (!aic) {
      aic = this.user?.aic;
    }
    if (aicBew === undefined) {
      aicBew = 0;
    }
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }

    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }
    // maximale Anzahl von Befehlen..
    let max = 0;
    if (maxBefehle) {
      max = maxBefehle;
    }
    const calc: any = {
      method: "POST",
      url: this.calcUrl("berechneVar/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        ID: this.user!.id,
        aicBegriff, // Modell Aic aus Begriffstablle
        aicStamm: aic, // aktuell immer MA AIC, AIC von LOGIN
        aicBew,
        vecStamm,
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis,
        zone: new Date().getTimezoneOffset() * -1,
        abbruch,
        max,
        debug,
        bemerkung
      },
      data: { var: varUbergabe, Tab: tabUbergabe, vecStamm, jokerStt }
    };
    // Zeitzone des Benutzers muss Ã¼bergeben werden, damit modell die richtige Zone speichert!
    console.log(JSON.stringify(calc.data));
    const me = this;
    return makeRequest(calc)
      .then((x: any) => this.handleResponse(x, success))
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public postCalcWeiter(
    iVB: number,
    tabUbergabe: any[],
    frage: number,
    success: any,
  ) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const calc: any = {
      method: "POST",
      url: this.calcUrl("weiter/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        ID: this.user!.id,
        iVB,
        ja: frage
      },
      data: { Tab: tabUbergabe }
    };
    // Zeitzone des Benutzers muss Ã¼bergeben werden, damit modell die richtige Zone speichert!
    console.log(JSON.stringify(calc.data));
    const me = this;
    return makeRequest(calc)
      .then((x: any) => this.handleResponse(x, success))
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public postCalcFertig(
    iVB: number,
    success: any,
  ) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const calc: any = {
      method: "POST",
      url: this.calcUrl("fertig/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        ID: this.user!.id,
        iVB
      }
    };
    // Zeitzone des Benutzers muss Ã¼bergeben werden, damit modell die richtige Zone speichert!
    console.log(JSON.stringify(calc.data));
    const me = this;
    return makeRequest(calc)
      .then((x: any) => this.handleResponse(x, success))
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public postCalcNoDebug() {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    return makeRequest({
      method: "DELETE",
      url: this.calcUrl("debug/"),
    })
      .then((x: any) => this.handleResponse(x, null))
      .finally(() => this.setBusy(false));
  }
  public postCalcDebug(abbruch: number, debug: number, success: any) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const calc: any = {
      method: "POST",
      url: this.calcUrl("debug/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        abbruch,
        debug
      }
    };
    const me = this;
    makeRequest(calc)
      .then((x: any) => this.handleResponse(x, success))
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  // mÃ¼ssen wir noch testen!
  public delBew(
    saveurl: string,
    aicBegriff: string,
    aicAbfAll: number,
    rowIndex: number,
    type: string,
    // data: any,
    success: any,
    zeitbereich?: any,
  ) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }
    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }
    if (!rowIndex) {
      rowIndex = 0;
    } // wenn kein rowIndex da dann mach ma einen
    // if (!data.aic) {
    //   data.aic = 0;
    // } // wenn kein aic da dann mach ma einen
    const opt: any = {
      method: "DELETE",
      url: this.saveUrl(saveurl, ""),
      headers: {
        aicBegriff, // header.aicBegriff
        aicAbfAll,
        aicFom: 0, // derzeit leer also 0 nicht '' weil sonst nicht int ;)
        aicPool: rowIndex, // Zeilenindex bei tabelle
        aicStamm: "",
        // aicStamm: this.user!.aic, // aktuell immer MA AIC, AIC von LOGIN
        anlage: type, // muss Eingabe oder Tabelle sein
        zone: "",
        // zone: new Date().getTimezoneOffset() * -1, // Zeitzone des Benutzers (in minuten?)
        status: "del",
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis,
        // "Content-Type": "application/json"
      },
      dataType: "json",
      contentType: "application/json"
    };
    return makeRequest(opt)
      .then((x: any) => this.handleResponse(x, success))
      .finally(() => this.setBusy(false));

    // wir verwenden am besten den Promise Stil bei den Calls fÃ¼r asyncrone abarbeitung...
    // .catch(AUApi.onfail);
  }
  public delStt(
    saveurl: string,
    aicBegriff: string,
    aicStamm: number,
    type: string,
    success: any,
    zeitbereich?: any,
    aicRolle?: number
  ) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }

    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }
    const opt: any = {
      method: "DELETE",
      url: this.saveUrl(saveurl, ""),
      headers: {
        aicBegriff, // header.aicBegriff
        aicStamm,
        anlage: type, // muss Eingabe oder Tabelle sein
        zone: "",
        // zone: new Date().getTimezoneOffset() * -1, // Zeitzone des Benutzers (in minuten?)
        status: "del",
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis,
        aicRolle
        // "Content-Type": "application/json"
      },
      dataType: "json",
      contentType: "application/json"
    };
    return makeRequest(opt)
      .then((x: any) => this.handleResponse(x, success))
      .finally(() => this.setBusy(false));

    // wir verwenden am besten den Promise Stil bei den Calls fÃ¼r asyncrone abarbeitung...
    // .catch(AUApi.onfail);
  }
  public delete(url: string, success: any) {
    const opt: any = {
      crossOrigin: false,
      type: "DELETE",
      url: this.fullUrl(url, "")
    };
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    makeRequest(opt)
      .then((x: any) => this.handleResponse(x, success))
      .catch(this.onfail)
      .finally(() => this.setBusy(false));
  }

  public login(username: string, password: string, mandant: any, success: any) {
    this.postForm(
      "webLog/loginM",
      { name: username, password, db: this.dB, mandant },
      success
    );
  }

  public refresh() {
    return makeRequest({
      method: "GET", url: this.webLogUrl("/refresh/"),
    })
      .then((x: any) => this.handleResponse(x, null));
  }
  public async logout(doReloadApp: boolean = true) {
    if (this.checkBusyAndSetIfAvailable()) {
      throw this.ApiBusyMessage;
    }
    const logoutVar: any = {
      method: "post",
      url: this.webLogUrl("logout/"),
      dataType: "json",
      contentType: "application/json",
    };
    const me = this;
    let resp: AxiosResponse | null = null;
    try {
      resp = await makeRequest(logoutVar)
        .then((x: any) => this.handleResponse(x));
    } catch (e) {
      console.warn("logout failed", e);
    }
    if (await IsLoginHelperSet()) {
      Logout();
    }
    if (doReloadApp) { reloadApp(true); }
    return resp ?? { data: { info: "logout failed", msgType: "error" } };
  }

  public logoutByBeacon() {
    const url = this.webLogUrl("logout/");
    const request = "";
    const headers = {
      type: 'application/json',
      ID: AUApi.Current.user?.id
    };
    const blob = new Blob([request], headers);
    navigator.sendBeacon(url, blob);
  }

  public getInfos() {
    const url = this.webLogUrl("ver/");
    return makeRequest({
      method: "GET", url
    })
      .then((x: any) => {
        return this.handleResponse(x);
      });
  }
  public getAdmin() {
    const url = this.webLogUrl("admin/");
    return makeRequest({
      method: "GET", url
    })
      .then((x: any) => {
        return this.handleResponse(x);
      });
  }
  public deleteAdmin() {
    const url = this.webLogUrl("admin/");
    return makeRequest({
      method: "DELETE", url
    })
      .then((x: any) => {
        return this.handleResponse(x);
      });
  }
  public countAdmin() {
    const url = this.webLogUrl("adminFrei/");
    return makeRequest({
      method: "GET", url
    })
      .then((x: any) => {
        return this.handleResponse(x);
      });
  }
  public getPersEinst() {
    const me = this;
    return makeRequest({
      method: "GET", url: this.webLogUrl("pers/"),
      dataType: "json",
      contentType: "application/json",
    })
      .then((x: any) => this.handleResponse(x))
      .catch((c: any) => me.onfail(c));
  }
  public postPersEinst(sprache: string, land: string, mandant: string, zeitFormat: string) {
    const persDef: any = {
      method: "post",
      url: this.webLogUrl("pers/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        sprache,
        land,
        mandant,
        zeitFormat
      }
    };
    const me = this;
    return makeRequest(persDef)
      .then((x: any) => this.handleResponse(x))
      .catch((c: any) => me.onfail(c));
  }
  public newUser(
    aic: number,
    kennung: string,
    bezeichnung: string,
    art: number,
    tel: string,
    eMail: string | null,
    sprache: number,
    land: number,
    person: number,
    hauptBenutzerGruppe: number,
    copyAic: number) {
    const newUser: any = {
      method: "post",
      url: this.webLogUrl("user/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        aic,
        kennung,
        bezeichnung,
        art,
        tel,
        eMail,
        sprache,
        land,
        person,
        hauptBenutzerGruppe,
        copyAic
      }
    };
    const me = this;
    return makeRequest(newUser)
      .then((x: any) => this.handleResponse(x))
      .catch((c: any) => me.onfail(c));
  }
  public resetPassword(kennung: string) {
    const resetPW: any = {
      method: "post",
      url: this.webLogUrl("resetPW/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        user: kennung
      }
    };
    const me = this;
    return makeRequest(resetPW)
      .then((x: any) => this.handleResponse(x))
      .catch((c: any) => me.onfail(c));
  }
  // public getUserStammsatz() {
  //   const url = this.webLogUrl("userStamm/");
  //   return makeRequest(url)
  //     .then((x: any) => {
  //       return this.handleResponse(x);
  //     });
  //   }
  public getUser(stammsatz: number, vorlage: number) {
    const url = this.webLogUrl("users/");
    const getUser: any = {
      method: "get",
      url,
      dataType: "json",
      contentType: "application/json",
      headers: {
        person: stammsatz,
        vorlage
      }
    };
    return makeRequest(getUser)
      .then((x: any) => {
        return this.handleResponse(x);
      });
  }
  public getUserGroup() {
    const url = this.webLogUrl("usergroups/");
    return makeRequest(url)
      .then((x: any) => {
        return this.handleResponse(x);
      });
  }
  public deleteUser(aic: string) {
    const newUser: any = {
      method: "delete",
      url: this.webLogUrl("user/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        aic
      }
    };
    const me = this;
    return makeRequest(newUser)
      .then((x: any) => this.handleResponse(x))
      .catch((c: any) => me.onfail(c));
  }
  public getVersion() {
    const url = this.webLogUrl("ver2/");
    return makeRequest(url)
      .then((x: any) => {
        return this.handleResponse(x);
      });
  }

  public getMessage(persoenlich: boolean) {
    let pers = "0";
    if (persoenlich) {
      pers = "1";
    }
    // quitt 1 - lÃ¶scht Nachrichten gleich!
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    return makeRequest({
      method: "GET", url: this.webLogUrl("Msg/"),
      headers: { quitt: 0, pers }
    })
      .then((x: any) => {
        this.handleResponse(x);
        return x;
      })
      .finally(() => this.setBusy(false));
  }
  public quittMessage(aic: string) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const quittMsg: any = {
      method: "post",
      url: this.webLogUrl("quittMsg"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        aic,
      }
    };
    const me = this;
    return makeRequest(quittMsg)
      .then((x: any) => {
        this.handleResponse(x);
        return x;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public fehlerProtokoll(text: string, begriff?: number) {
    const fehlerProtokoll: any = {
      method: "post",
      url: this.webLogUrl("fehler"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        tat: text,
        begriff,
      }
    };
    const me = this;
    return makeRequest(fehlerProtokoll)
      .then((x: any) => {
        this.handleResponse(x);
        return x;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public changePW(PWnew: string, PWold: string) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const me = this;
    const changePW: any = {
      method: "post",
      url: this.webLogUrl("changePW"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        user: this.user!.kennung,
        PWold,
        PWnew
      }
    };
    return (
      makeRequest(changePW)
        .then((x: any) => this.handleResponse(x)) //  @isabelle: nix tun ?
        // .then(() => this.logoutUser()) bitte nicht ausloggen :-)
        .catch((c: any) => me.onfail(c))
        .finally(() => this.setBusy(false))
    );
  }
  public logoutall() {
    return makeRequest(this.webLogUrl("logoutAll/"))
      .then((x: any) => this.handleResponse(x)) //  @isabelle: nix tun ?
      .then(() => this.logoutUser());
  }
  public setToken() {
    return makeRequest(this.webLogUrl("setToken"))
      .then((x: any) => this.handleResponse(x));
  }
  public clearToken() {
    return makeRequest(this.webLogUrl("clearToken"))
      .then((x: any) => this.handleResponse(x))
      .finally(() => this.setBusy(false));
  }
  public refreshToken() {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    return new Promise((resolve, reject) => {
      makeRequest({
        url: this.webLogUrl("refreshToken/"),
      })
        .then((x: any) => {
          this.handleResponse(x);
          resolve(x);
        })
        .catch((err: Error) => reject(err))
        .finally(() => this.setBusy(false));
    });
  }
  // SyncStamm setzen - wichtig auch bei Modellen!
  public setSyncStamm(syncStt: number, syncStamm: number) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const setSyncStamm: any = {
      method: "POST",
      url: this.GlobUrl("syncStamm"),
      dataType: "json",
      contentType: "application/json",
      headers: { stt: syncStt, aic: syncStamm, rolle: 0 },
      data: {}
    };
    const me = this;
    makeRequest(setSyncStamm)
      .then((x: any) => this.handleResponse(x))
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public setSyncVec(syncVec: any[]) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const setSyncVec: any = {
      method: "POST",
      url: this.GlobUrl("syncVec"),
      dataType: "json",
      contentType: "application/json",
      headers: {},
      data: { jokerStt: syncVec }
    };
    const me = this;
    makeRequest(setSyncVec)
      .then((x: any) => this.handleResponse(x))
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public getSprachen() {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const getSprachen: any = {
      method: "GET",
      url: this.GlobUrl("sprachen"),
      dataType: "json",
      contentType: "application/json",
    };
    const me = this;
    return makeRequest(getSprachen)
      .then((x: any) => {
        this.handleResponse(x);
        return x;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public getLaender() {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const getLaender: any = {
      method: "GET",
      url: this.GlobUrl("laender"),
      dataType: "json",
      contentType: "application/json",
    };
    const me = this;
    return makeRequest(getLaender)
      .then((x: any) => {
        this.handleResponse(x);
        return x;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public getapiAbfragen(getImport: number) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const getapi: any = {
      method: "GET",
      url: this.GlobUrl("api"),
      dataType: "json",
      contentType: "application/json",
      headers: { import: getImport },
    };
    const me = this;
    return makeRequest(getapi)
      .then((x: any) => {
        this.handleResponse(x);
        return x;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public getBenutzergruppen() {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const getBenutzergruppen: any = {
      method: "GET",
      url: this.GlobUrl("benutzergruppen"),
      dataType: "json",
      contentType: "application/json",
    };
    const me = this;
    return makeRequest(getBenutzergruppen)
      .then((x: any) => {
        this.handleResponse(x);
        return x;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public getUserData(DB: any | undefined, Kennung?: string, url?: string) {
    if (url) {
      this.rootUrl = url;
    }
    const userData: RequestConfig = {
      method: "GET",
      url: this.webLogUrl("user"),
      dataType: "json",
      contentType: "application/json",
      headers: { DB, Kennung },
      data: {}
    };
    if (!DB) {
      DB = this.dB;
    }
    if (!Kennung) {
      Kennung = this.user!.kennung;
    }
    // im Cache schaun ob es das schon gibt und wenn nicht machen und cachen ;)
    return Caching.Instance.checkAsync(userData, x => {
      const me = this;
      return makeRequest<ALLUser>(userData)
        // aic, Datum und Art - sollte zurÃ¼ckkommen - das muss dann verschlÃ¼sselt werden...
        .then((resp: any) => {
          if (resp.status !== 200) {
            throw { response: resp, message: "getUser request failed" };
          }
          if (this.handleErrors(resp)) {
            return;
          }
          Caching.Instance.cacheRequest(userData, resp);
          return resp; // response neu zurÃ¼ckgeben, damit es auch weitergegeben wird...
        })
        .catch(e => { throw e; })
        .finally(() => this.setBusy(false));
    });
  }
  public getCode(code: string) {
    const getCode: any = {
      method: "post",
      url: this.webLogUrl("setCode/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        Code: code,
      }
    };
    const me = this;
    return makeRequest(getCode)
      .then((x: any) => this.handleResponse(x))
      .catch((c: any) => me.onfail(c));
  }

  public getVariablen() {
    return makeRequest({ url: this.calcUrl("var/") })
      .then((x: any) => this.handleResponse(x))
      .finally(() => this.setBusy(false));
  }
  public deleteVariable(variable?: string) {
    // if (this.checkBusyAndSetIfAvailable()) {
    //   return Promise.reject(this.ApiBusyMessage);
    // }
    if (variable) {
      return makeRequest({
        method: "DELETE", url: this.calcUrl("var1/"),
        headers: { Var: variable }
      })
        .then((x: any) => this.handleResponse(x))
        .finally(() => this.setBusy(false));
    } else {
      return makeRequest({
        method: "DELETE", url: this.calcUrl("var/"),
      })
        .then((x: any) => this.handleResponse(x))
        .finally(() => this.setBusy(false));
    }
  }
  public getImage(file: any) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    return makeRequest(this.fullUrl("image2/" + this.user!.id + ":" + file))
      .finally(() => this.setBusy(false));
  }
  public getSmallImage(file: any) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    return makeRequest(this.fullUrl("imageM/" + this.user!.id + ":" + file))
      .finally(() => this.setBusy(false));
  }
  public deleteAllVar() {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    return makeRequest({
      method: "DELETE", url: this.calcUrl("var/"),
    })
      .then((x: any) => this.handleResponse(x))
      .finally(() => this.setBusy(false));
  }
  // public setVariableString(variable: string) {
  //   return axios.get(
  //     this.calcUrl("setVarS/" + this.user.id + "-" + "WebMemo" + "-" + variable)
  //   );
  // }
  public setVariable(varUbergabe: any[], success: any) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const setVar: any = {
      method: "POST",
      url: this.calcUrl("var/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        aicStamm: this.user?.aic
      },
      data: { var: varUbergabe }
    };
    const me = this;
    makeRequest(setVar)
      .then((x: any) => this.handleResponse(x, success))
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }

  public setFavorit(aic: number, set: boolean, success: any) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    if (!aic) {
      throw new Error("no aic given to setFavorit!");
    }
    let method = "DELETE";
    if (set) {
      method = "POST";
    }
    const setVar: any = {
      method,
      url: this.calcUrl("favorit/", "webAbf/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        iStamm: aic,
        iBG: 0 // hier soll spÃ¤ter dann die Benutzergruppe Ã¼bergeben werden
      }
    };
    const me = this;
    return makeRequest(setVar)
      .then((x: any) => this.handleResponse(x, success))
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public print(
    stamm: number,
    druck: number,
    zeitbereich: APIZeitbereich,
    uebergabeFarbe?: boolean,
    uebergabeUmbruch?: boolean,
    layout?: string,
    nurSummeUebergabe?: boolean,
    keineSummeUebergabe?: boolean,
    gruppiertUebergabe?: boolean,
    zeitFormat?: boolean,
    vecStamm?: any[],
    tabUbergabe?: any[],
    zeitraum?: any[],
    seteMail?: boolean,
    setpdfSave?: boolean,
    jokerStt?: any[],
  ) {
    // if (this.checkBusyAndSetIfAvailable()) {
    //   return Promise.reject(this.ApiBusyMessage);
    // }
    if (!stamm && !vecStamm) {
      stamm = this.user!.aic;
    }
    let von = null;
    let bis = null;
    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
      if (zeitbereich.von) {
        von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
      }
      if (zeitbereich.bis) {
        bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
      }
    } else {
      if (zeitbereich.von) {
        von = zeitbereich.von;
      }
      if (zeitbereich.bis) {
        bis = zeitbereich.bis;
      }
    }
    let farbe: string = "0";
    if (uebergabeFarbe) {
      farbe = "1";
    }
    let umbruch: string = "0";
    if (uebergabeUmbruch) {
      umbruch = "1";
    }
    let gruppiert: string = "0";
    if (gruppiertUebergabe) {
      gruppiert = "1";
    }
    let keineSumme: string = "0";
    if (keineSummeUebergabe) {
      keineSumme = "1";
    }
    let nurSumme: string = "0";
    if (nurSummeUebergabe) {
      nurSumme = "1";
    }
    let showFormat: string = "dez";
    if (zeitFormat) {
      showFormat = "min";
    }
    let eMail = "0";
    if (seteMail) {
      eMail = "1";
    }
    let pdfSave = "0";
    if (setpdfSave) {
      pdfSave = "1";
    }
    const method = "POST";
    const print: any = {
      method,
      url: this.calcUrl("druck/", "webAbf/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        druck,
        stamm,
        layout,
        wait: "1",
        raster: "0",
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis,
        farbe,
        umbruch,
        keineSumme,
        nurSumme,
        gruppiert,
        zeitFormat: showFormat,
        eMail,
        pdfSave
      },
      data: { Tab: tabUbergabe, vecStamm, ZR: zeitraum, jokerStt }
    };
    const me = this;
    return makeRequest(print)
      .then((resp: any) => {
        return resp;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public getStammListe(prog: number, success?: (data: AxiosResponse<QueryResponse>) => void) {
    const me = this;
    const method = "GET";


    const zeitbereich = this.zeitbereich;

    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }
    const stamm: any = {
      method,
      url: this.GlobUrl("stt/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        prog,
        zeitart: zeitbereich.bereich, // Periodeneinheit, tag, Woche, Monat-....
        von,
        bis,
      },
    };
    const p = makeRequest(stamm)
      .then((x: any) => {
        this.handleChanged(x);
        if (this.handleErrors(x)) {
          return;
        }
        if (success) {
          success(x);
        }
        return x;
      })
      .catch((c: any) => me.onfail(c));
    return p;

  }
  public hauptschirm(success?: any) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const method = "Get";
    const hauptschirm: any = {
      method,
      url: this.calcUrl("hs", "webAbf/"),
      dataType: "json",
      contentType: "application/json",
    };
    const me = this;
    return makeRequest(hauptschirm)
      .then((resp: any) => {
        return resp;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public getHSEinstellung(HS: number, zeitbereich: any) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }

    if (!zeitbereich) {
      zeitbereich = this.zeitbereich;
    }

    let von = null;
    let bis = null;
    if (zeitbereich.von) {
      von = zeitbereich.von.toISOStringWithTZ().substr(0, 10);
    }
    if (zeitbereich.bis) {
      bis = zeitbereich.bis.toISOStringWithTZ().substr(0, 10);
    }
    const method = "Get";
    const hsEinstellung: any = {
      method,
      url: this.calcUrl("ans/", "webAbf/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        HS,
        art: "1",
      }
    };
    const me = this;
    return makeRequest(hsEinstellung)
      .then((resp: any) => {
        return resp;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public getStichtag(eig: number, stamm: number, aktuell?: string) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const method = "Get";
    const stichtag: any = {
      method,
      url: this.calcUrl("stichtag", "webAbf/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        eig,
        stamm,
        stichtag: aktuell,
      }
    };
    const me = this;
    return makeRequest(stichtag)
      .then((resp: any) => {
        return resp;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public getStatusAufgaben(aufgabe: number) {
    const method = "Get";
    const statusAufgabe: any = {
      method,
      url: this.GlobUrl("status/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        aufgabe,
      }
    };
    const me = this;
    return makeRequest(statusAufgabe)
      .then((resp: any) => {
        return resp;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }
  public getBerechtigung(stt: string, begriff: string) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const method = "Get";
    const berechtigung: any = {
      method,
      url: this.calcUrl("berechtigung", "webAbf/"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        stt,
        begriff
      }
    };

    return makeRequest(berechtigung)
      .then((x: any) => {
        this.handleResponse(x);
        return x;
      })
      .finally(() => this.setBusy(false));

  }

  // es kÃ¶nnen pro Spalte und Abfrage Parameter fÃ¼r den User gemerkt werden!
  public setPersParameter(varUbergabe: any[], success?: any) {
    {
      if (this.checkBusyAndSetIfAvailable()) {
        return Promise.reject(this.ApiBusyMessage);
      }
      const setPara: any = {
        method: "POST",
        url: this.calcUrl("spaltePara/", "webAbf/"),
        dataType: "json",
        contentType: "application/json",
        headers: {
        },
        data: { var: varUbergabe }
      };
      const me = this;
      return makeRequest(setPara)
        .then((x: any) => this.handleResponse(x, success))
        .catch((c: any) => me.onfail(c))
        .finally(() => this.setBusy(false));
    }
  }
  public deletePersParameter(aicAbfrage: any, success?: any) {
    {
      if (this.checkBusyAndSetIfAvailable()) {
        return Promise.reject(this.ApiBusyMessage);
      }
      const setPara: any = {
        method: "DELETE",
        url: this.calcUrl("spaltePara/", "webAbf/"),
        dataType: "json",
        contentType: "application/json",
        headers: {
          begriff: aicAbfrage,
        },
      };
      const me = this;
      return makeRequest(setPara)
        .then((x: any) => this.handleResponse(x, success))
        .catch((c: any) => me.onfail(c))
        .finally(() => this.setBusy(false));
    }
  }
  public getGlobalHoliday(
    setStatus:
      | ((title: string, count: number, array: any) => void)
      | null = null
  ) {
    const me = this;
    return makeRequest<Holiday[]>({ url: me.GlobUrl("holidays") })
      .then(response => {
        if (setStatus) {
          setStatus(
            "Feiertage",
            response.data.length,
            JSON.stringify(response.data)
          );
        }
        if (response.data) {
          me.Holiday = response.data;
          me.Holiday.forEach((element: any) => {
            element.isoDay = element.datum.substr(0, 10);
            const year = parseInt(element.datum.substr(0, 4), 10);
            me.holidayYears.push(year);
          });
        } else {
          console.warn("keine Feiertage geladen !!");
        }
      });
  }
  private getGlobalStammtypen(
    setStatus:
      | ((title: string, count: number, array: any) => void)
      | null = null
  ) {
    const me = this;
    return makeRequest<Stammtyp[]>({ url: me.GlobUrl("stt") })
      .then(data => {
        if (setStatus) {
          setStatus("Stammtypen", data.data.length, JSON.stringify(data.data));
        }
        me.Stt = data.data;
      });
  }
  private getGlobalAmpel(
    setStatus:
      | ((title: string, count: number, array: any) => void)
      | null = null
  ) {
    const me = this;
    return makeRequest<Ampel[]>({ url: me.GlobUrl("ampel") })
      .then(data => {
        if (setStatus) {
          setStatus("Ampel", data.data.length, JSON.stringify(data.data));
        }
        me.Ampel = data.data;
      });
  }
  public getGlobalBegriffe(
    setStatus:
      | ((title: string, count: number, array: any) => void)
      | null = null
  ) {
    const me = this;
    return makeRequest<Begriff[]>({ url: me.GlobUrl("begriffe") })
      .then(data => {
        if (setStatus) {
          setStatus("Begriffe", data.data.length, JSON.stringify(data.data));
        }
        me.Begriff = data.data;
      });
  }
  private getGlobalCodes(
    setStatus:
      | ((title: string, count: number, array: any) => void)
      | null = null
  ) {
    const me = this;
    return makeRequest<Code[]>({ url: me.GlobUrl("codes") })
      .then(data => {
        if (setStatus) {
          setStatus("Codes", data.data.length, JSON.stringify(data.data));
        }
        me.Code = data.data;
      });
  }
  private getGlobalMandanten(
    setStatus:
      | ((title: string, count: number, array: any) => void)
      | null = null
  ) {
    const me = this;
    return makeRequest<Mandant[]>({ url: me.GlobUrl("mandanten") })
      .then(data => {
        if (setStatus) {
          setStatus("Mandanten", data.data.length, JSON.stringify(data.data));
        }
        me.Mandanten = data.data;
      });
  }
  private getGlobalPeriodensperre(
    setStatus:
      | ((title: string, count: number, array: any) => void)
      | null = null
  ) {
    const me = this;
    return makeRequest<Periodensperre[]>({ url: me.GlobUrl("periodenSperre") })
      .then(data => {
        if (setStatus) {
          setStatus(
            "Periodensperre",
            data.data.length,
            JSON.stringify(data.data)
          );
        }
        me.Periodensperre = data.data;
      });
  }
  private getGlobalEinheiten(
    setStatus:
      | ((title: string, count: number, array: any) => void)
      | null = null
  ) {
    const me = this;
    return makeRequest<Einheit[]>({ url: me.GlobUrl("einheiten") })
      .then(data => {
        if (setStatus) {
          setStatus("Einheiten", data.data.length, JSON.stringify(data.data));
        }
        me.Einheiten = data.data;
      });
  }
  private getGlobalSecurity(
    setStatus:
      | ((title: string, count: number, array: any) => void)
      | null = null
  ) {
    const me = this;
    return makeRequest<any>({ url: me.webLogUrl("security/") })
      .then(data => {
        if (setStatus) {
          setStatus("Security", data.data.length, JSON.stringify(data.data));
        }
        me.Security = data.data;
      });
  }
  private getGlobalSyncStamm(
    setStatus:
      | ((title: string, count: number, array: any) => void)
      | null = null
  ) {
    const me = this;
    return makeRequest<SyncStamm[]>({ url: me.GlobUrl("allSyncStamm/") })
      .then(data => {
        if (setStatus) {
          setStatus("SyncStamm", data.data.length, JSON.stringify(data.data));
        }
        me.SyncStamm = data.data;
      });
  }

  public getGlobal(success: any, status: (txt: string) => void, fail: any) {
    const me: AUApi = this;

    let promises = [];

    let finished = 0;
    const setStatus = (title: string, count: number, array: any) => {
      finished++;
      // console.log(title + " ->" + count);
      // console.table(" Array: " + array);
      status(finished + "/" + promises.length + " " + title);
    };

    promises = [
      // Begriffe - Titel, Buttons, Modelle, Frame, Meldungen, SystenAbfagen
      this.getGlobalBegriffe(setStatus),
      // Meldungen fÃ¼r Benutzer - geht ab 10.5.2019 mit Begriffen mit
      this.getGlobalHoliday(setStatus),
      // Stammtypen - aktuell nirgendst erforderlich
      this.getGlobalStammtypen(setStatus),

      // Bewegungstypen - aktuell nicht erforderlich
      // axios.get(me.GlobUrl("getBew") }).then(data => {
      //   console.log("Bewegungstypen ->" + data.data.length);
      //   me.Bew = data.data;
      // }),
      // Codes - Monate, Wochentag, Anlage, Programme
      this.getGlobalCodes(setStatus),

      // Rolle - Mitarbeiter Rollen etc (Bewerber, Mitarbeiter...)
      // aktuell nicht erforderlich
      // axios.get(me.GlobUrl("getRolle") }).then(data => {
      //   console.log("Rolle ->" + data.data.length);
      //   me.Rollen = data.data;
      // }),

      // Eigenschaften - alle Eigenschaften die es gibt.. bin nicht sicher ob wir das brauchen?
      // 13.5.2019 ausgeblendet - da ich nicht genau weiÃ wozu wir das Ã¼berhaupt brauchen
      // axios.get(me.GlobUrl("getEig") }).then(data => {
      //   console.log("Eigenschaft ->" + data.data.length);
      //   me.Eigenschaft = data.data;
      // }),
      this.getGlobalMandanten(setStatus),
      this.getGlobalPeriodensperre(setStatus),
      this.getGlobalEinheiten(setStatus),
      this.getGlobalSecurity(setStatus),
      // holt alle SyncStamm die gesetzt sind..
      this.getGlobalSyncStamm(setStatus),
      this.getGlobalAmpel(setStatus)
    ];

    Promise.all(promises)
      .then(data => {
        console.log("finished login...");
        success(data);
      })
      .catch(e => {
        if (e.response.status === 401 || e.response.status === 500 || e.response.status === 499) {
          alert(e.response.data.info);
          setTimeout(() => this.logoutUser(), 2000);
          // this.logoutUser();
          // window.location.reload();
        }
        console.log("login failed: " + e);
        fail(e);
      });
  }
  private logoutUser() {
    this.eraseLocalData();
    reloadApp();
  }

  public setLocalData(min: number) {
    this.user!.dB = this.dB;
    const value = JSON.stringify(this.user);
    // sessionKey for Caching
    if (this.user) {
      this.user.sessionKey =
        new Date().toISOString() + "-" + Math.floor(Math.random() * 100);
    }
    const name = COOKIE_NAME;
    let date = null;
    let expires = "";
    if (min) {
      date = new Date();
      date.setTime(date.getTime() + min * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    } else {
      expires = "";
    }
    if (typeof Storage !== "undefined") {
      if (date) {
        localStorage.expires = date.toUTCString();
      }
      localStorage.loginData = value;
      if (this.user && this.user.bildFile) {
        const bildFile = this.user.bildFile;
        localStorage.avatarBild = this.fullUrl(
          "image2/" + this.user.id + ":" + bildFile
        );
      } else {
        localStorage.avatarBild = "/static/user.png";
      }
    } else {
      const cook = name + "=" + (value || "") + expires + "; path=/";
      console.log("setting cookie: " + cook);
      document.cookie = cook;
    }
  }
  public getLocalData() {
    const name = COOKIE_NAME;
    if (typeof Storage !== "undefined") {
      if (localStorage.loginData) {
        if (new Date(localStorage.expires) >= new Date()) {
          return localStorage.loginData;
        }
      }
    } else {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    }
    return null;
  }
  public getUserSettingsForBegriff(aicBegriff: string) {
    if (this.user != null && typeof Storage !== "undefined") {
      const key: string = "user_" + this.user.id;
      const json: any = localStorage.getItem(key);
      const data = JSON.parse(json);
      if (data) {
        return data[aicBegriff];
      }
    }
    return {};
  }
  public setUserSettingsForBegriff(aicBegriff: string, begriffData: any) {
    if (this.user != null && typeof Storage !== "undefined") {
      const json: any = localStorage.getItem("user_" + this.user.id);
      let data = JSON.parse(json);

      if (!data || data === "") {
        data = {};
      }
      data[aicBegriff] = begriffData;

      localStorage.setItem("user_" + this.user.id, JSON.stringify(data));
    }
    return {};
  }
  public getUserAvatar() {
    if (typeof Storage !== "undefined" && localStorage.avatarBild) {
      return localStorage.avatarBild as string;
    }
    return "/static/user.png";
  }
  public eraseLocalData() {
    if (typeof Storage !== "undefined") {
      const text = this.user.bezeichnung;
      {
        localStorage.loginData = null;
        localStorage.expires = null;
        localStorage.activeTab = "";
        localStorage.activeTabVG = "";
        localStorage.activeTabAntragMA = "";
        localStorage.activeHS = "";
        localStorage.activeZeitraumDPVG = "";
        localStorage.activeAnzeigeDPVG = "";
        Object.keys(localStorage).forEach((key) => {
          if (key.includes(text)) { delete localStorage[key]; }
        });
      }
      document.cookie = COOKIE_NAME + "=; Max-Age=-99999999;";
    }
  }

  public mapDataToSaveObject(
    newdata: any,
    columns: any[],
    originalObj: any | undefined
  ) {
    let data = newdata;
    if (originalObj) {
      data = {};
      for (const key of Object.keys(newdata)) {
        const newVal = newdata[key];
        let newValComp = null;
        if (newVal) {
          newValComp = JSON.stringify(newVal);
        }

        const oldVal = originalObj[key];
        let oldValComp = null;
        if (oldVal) {
          oldValComp = JSON.stringify(oldVal);
        }

        if (newValComp !== oldValComp) {
          data[key] = newdata[key];
        }
      }
    }
    const mapped: any = [];
    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      const propertyname: any = col.name;
      if (
        (col.meta && (col.meta.keinSpeichern || col.meta.keinSave)) ||
        !col.aicEig
      ) {
        // console.log("Spalte " + col.name + " is readonly or invisible");
        continue;
      }
      let val = data[propertyname];
      // orignalObj wird beim STAMM Speichern geschickt
      if (originalObj !== null) {
        if (val !== null && val !== undefined) {
          if (col.type === "mass") {
            const ori = originalObj[propertyname];
            // Erfassung von % ist in 0,5... aber gespeichert werden muss 50..
            if (ori && ori.kz === "%" && ori.faktor > 0) {
              val = val / ori.faktor;
            } else if (col.meta.einheit) {
              const einheit = col.meta.einheit;
              const anzeige = this.Einheiten.find((c: any) => c.aic === einheit);
              if (anzeige && anzeige.kennung === "%" && anzeige.faktor > 0) {
                val = val / anzeige.faktor;
              }
            }
            val = { aic: col.meta.einheit, value: val };
          } else if (typeof val === "object" && val.aic) {
            val = val.aic;
          } else if (col.type === "hierarchie") {
            // bei hierarchue kommt es in einem Array..
            val = val[0];
          }
          // nur aic schicken...

          // console.log("Spalte " + col.name + " = " + val);
          // damit entstehen doppelte Zeilen!
          // mapped.push({
          //   aic: col.aicEig,
          //   data: val
          //   // , kennung: col.kennung
          // });
        }
      } else if (col.type === "mass") {
        if (typeof val === "object") {
          if (val === null) {
            val = 0;
          } else {
            const faktor = val.faktor;
            val = val.value;
            if (faktor !== 0 && val !== 0) {
              val = val / faktor;
            }
          }
        }
        // bei BEW Speichern muss immer ALLES geschickt werden!
        if (val === "0:00" || val === undefined || val === null) {
          val = 0;
        }
        val = { aic: col.meta.einheit, value: val };
      } else if (col.type === "comboBox" && val === null || col.type === "comboBox" && val === undefined) {
        // val darf nicht "null" sein sonst kommt ein Error
        val = 0;
      } else if (col.type === "zahl") {
        if (val === "0:00" || val === undefined || val === null) {
          val = 0;
        }
      } else if (col.type === "fileChoicer" && val) {
        val = { data: val.data, aic: val.aic, name: val.name };
      } else if (typeof val === "object" && val?.aic) {
        val = val.aic;
      } else if (col.type === "dateTimePicker" || col.type === "datePicker") {
        if (val === undefined) {
          val = "";
        }
        // nur aic schicken...
      }
      console.log("Spalte " + col.title + " = " + val);
      if (newdata.aic_Stamm) {
        if (val !== undefined) {
          mapped.push({
            aic: col.aicEig,
            data: val
          });
        }
      } else {
        mapped.push({
          aic: col.aicEig,
          data: val
        });
      }
    }
    if (mapped.length === 0) {
      return null;
    }
    if (data.aic) {
      mapped.aic = data.aic;
    } // wenn kein aic da dann mach ma einen

    return mapped;
  }

  public SaveData(
    header: any,
    aicAbfAll: number,
    aicBewPool: any,
    newData: any,
    columns: any[],
    zeitbereich: any,
    originalObj: any | undefined,
    success: any,
    error: any,
    aicStamm?: number,
    getData?: boolean,
    saveHaupt?: boolean,
    aicStt?: number,
    stichtag?: Date | null,
    jokerStt?: any[],
    varUbergabe?: any[],
  ) {
    if (header.aicBew) {
      const me = this;
      let dataToSave = null;
      if (JSON.stringify(newData) === JSON.stringify(originalObj)) {
        // nothing todo...
        if (originalObj.aic_Bew_pool === 0) {
          // ist er AicBewPool 0 - dann muss auch wenn die Daten ident geblieben sind gespeichert werden
          // da die Daten dann vom Modell vorgefÃ¼llt wurden und der User nur sein OK gibt!
          dataToSave = this.mapDataToSaveObject(newData, columns, null);
        }
      } else {
        dataToSave = this.mapDataToSaveObject(newData, columns, null);
      }
      if (dataToSave == null) {
        const returnObject = { nothingHappend: true };
        if (success) {
          success(returnObject);
        }
        return Promise.resolve();
      }
      if (!zeitbereich) {
        zeitbereich = this.zeitbereich;
      }
      // Roland prÃ¼ft nicht mehr auf Haupt...
      let haupt = 0;
      if (saveHaupt) {
        haupt = 1;
      }
      let getDataNumber = 0;
      if (getData) {
        getDataNumber = 1;
      }
      return this.postBew(
        "webSave/Bew/",
        header.aicBegriff,
        aicAbfAll,
        aicBewPool,
        "EINGABE",
        dataToSave,
        success,
        zeitbereich,
        aicStamm,
        haupt,
        getDataNumber,
        aicStt,
        jokerStt,
        varUbergabe
      ).catch(error);
    } else {
      const dataToSave = this.mapDataToSaveObject(
        newData,
        columns,
        originalObj
      );
      let getDataNumber = 0;
      if (getData) {
        getDataNumber = 1;
      }
      return this.post(
        "webSave/Stt/",
        header.aicBegriff,
        "EINGABE",
        dataToSave,
        success,
        zeitbereich,
        aicStamm,
        true,
        getDataNumber,
        stichtag,
        jokerStt
      ).catch(error);
    }
  }

  public DeleteData(
    header: any,
    bewPool: number,
    aicAbfAll?: any,
    zeitbereich?: any,
    success?: any,
    error?: (r: any) => void,

  ) {
    if (header.aicBew) {
      if (typeof aicAbfAll !== "number") {
        aicAbfAll = 0;
      }
      const me = this;
      return this.delBew(
        "webSave/Bew/",
        header.aicBegriff,
        aicAbfAll,
        bewPool,
        "EINGABE",
        success,
        zeitbereich
      ).catch(error);
    } else {
      const me = this;
      const rolle = header.aicRolle;
      return this.delStt(
        "webSave/Stt/",
        header.aicBegriff,
        bewPool,
        "",
        success,
        zeitbereich,
        rolle
      ).catch(error);
    }
  }
  public checkBew(
    aicBew: number,
    bew?: number,
  ) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const copyStamm: any = {
      method: "get",
      url: this.saveUrl("/checkBew", "webSave"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        ID: this.user!.id,
        aic: aicBew,
        bew
      }
    };
    const me = this;
    return makeRequest(copyStamm)
      .then((x: any) => {
        this.handleResponse(x);
        return x;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));

  }
  public copyStamm(aicStamm: number) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const copyStamm: any = {
      method: "post",
      url: this.saveUrl("/copyStamm", "webSave"),
      dataType: "json",
      contentType: "application/json",
      headers: {
        ID: this.user!.id,
        aicStamm
      }
    };
    const me = this;
    return makeRequest(copyStamm)
      .then((x: any) => {
        this.handleResponse(x);
        return x;
      })
      .catch((c: any) => me.onfail(c))
      .finally(() => this.setBusy(false));
  }

  public isHoliday(date: Date) {
    const isoDay = date.toISOString().substr(0, 10);
    return this.Holiday.findIndex((h: any) => h.isoDay === isoDay) >= 0;
  }
  private pendingHolidayYears: any = [];
  public checkHolidayRange(date: Date) {
    const me = this;
    const promise = new Promise((resolve, reject) => {
      const year = date.getFullYear();
      if (me.holidayYears.indexOf(year) < 0) {
        if (me.pendingHolidayYears.indexOf(year < 0)) {
          me.pendingHolidayYears.push(year);
          console.log("loading holidays for " + year);
          makeRequest<Holiday[]>({
            url: me.GlobUrl("holidays"),
            headers: { jahr: year }
          })
            .then((response: any) => {
              if (response.data) {
                me.Holiday = response.data;
                me.Holiday.forEach((element: any) => {
                  element.isoDay = element.datum.substr(0, 10);
                  me.holidayYears.push(year);
                  resolve(me.getHoliday(date));
                });
              } else {
                console.warn("keine Feiertage geladen !!");
              }
            });
        } else {
          promise.then(res => resolve(res));
        }
      } else {
        resolve(me.getHoliday(date));
      }
    });
    return promise;
  }

  public getHoliday(date: Date | string) {
    let isoDay = date;
    if (typeof date === "string") {
      isoDay = date.substr(0, 10);
    } else {
      isoDay = date.toISOStringWithTZ().substr(0, 10);
    }
    return this.Holiday.find((h: any) => h.isoDay === isoDay);
  }

  public changeMandant(mandant: any) {
    if (this.checkBusyAndSetIfAvailable()) {
      return Promise.reject(this.ApiBusyMessage);
    }
    const setMandant: any = {
      method: "POST",
      url: this.webLogUrl("mandant/"),
      dataType: "json",
      contentType: "application/json",
      headers: { Mandant: mandant.aic }
    };
    return makeRequest(setMandant)
      .then((data: any) => {
        this.mandant = mandant;
        console.log("Mandant gewechselt auf: " + mandant.aic);
      })
      .finally(() => this.setBusy(false));
  }
}


