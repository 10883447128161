

































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import CreateList from "@/components/CreateList";
import { numberToStringWithThousends, toNumber } from "@/DataHelper";
import { showPDF2, showImage } from "@/PdfHelper";
import { getKZValueDisplay, getKennungValue, getKZTitle, getKZValue } from "@/DataHelper";

@Component
export default class DialogKosten extends CreateList {
  private showDialog: boolean = false;
  @Prop({ default: 300 }) public contentHeight!: number;

  private timeLine: boolean = false;
  private nachErfassung: boolean = false;
  private editaic: any;

  private titleColor = "dvhBackground";
  private sumCost: number = 0;
  private sumCost1: string = "";
  private itemHeight: string = "110";
  constructor() {
    super();
    this.showLoading = true;
  }

  public mounted() {
    this.mountedBase();
  }

  protected refreshItems() {
    this.items = []; // ausleeren fÃ¼r refresh
    this.sumCost = 0;

    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      const header = getKZValueDisplay(row, "header", this.columns);
      const differenz: any = getKennungValue(row, "hours", this.columns);
      const title = getKZValueDisplay(row, "titel", this.columns);
      let title2 = getKZValueDisplay(row, "titel2", this.columns);
      if (title2) {
        const textTitle2 = getKZTitle(row, "titel2", this.columns);
        title2 = textTitle2 + ": " + title2;
      }
      let subtitel = getKZValueDisplay(row, "subtitel", this.columns);
      if (subtitel) {
        const textSubtitel = getKZTitle(row, "subtitel", this.columns);
        subtitel = textSubtitel + ": " + subtitel;
      }
      const subtitel1 = getKZValueDisplay(row, "subtitle1", this.columns);
      const cost = getKZValue(row, "subtitle1", this.columns);
      this.sumCost += cost;
      const summe = toNumber(this.sumCost);
      const summe2 = numberToStringWithThousends(summe, 2);
      this.sumCost1 = summe2;
      let text = getKZValueDisplay(row, "text", this.columns);
      if (text) {
        const textTitel = getKZTitle(row, "text", this.columns);
        text = textTitel + ": " + text;
      }
      let icon = getKZValueDisplay(row, "icon", this.columns);
      let select = false;
      const selectAic = getKZTitle(row, "bezahlt", this.columns);
      if (selectAic) {
        select = false;
      }
      let pdf = getKZValue(row, "pdf", this.columns);
      let memo = "";
      let checkbox = false;
      if (pdf && pdf.name && pdf.type !== "application/pdf") {
        memo = pdf.name;
        icon = pdf;
        pdf = this.loadImage(pdf);
        this.itemHeight = "120";
      } else {
        checkbox = true;
      }
      this.items.push({
        header,
        title,
        title2,
        subtitel,
        subtitel1,
        text,
        icon,
        differenz,
        divider: true,
        inset: true,
        aic: row.aic_Bew_pool,
        menu: false,
        select,
        pdf,
        memo,
        checkbox,
      });
    }
  }
  private loadImage(image: any) {
    if (image.datentyp === "Doku") {
      const base64data = this.$api.fullUrl(
        "pdf2/" + this.$api.user.id + ":" + +image.aic + "_" + image.name
      );
      return base64data;
    } else {
      const base64data = this.$api.fullUrl(
        "image2/" + this.$api.user.id + ":" + +image.aic + "_" + image.name
      );
      return base64data;
    }
  }
  private onShowPdf(pdf: any) {
    showPDF2(pdf.name, pdf.aic, this.$api.user.id);
  }
  private onShowImage(image: any) {
    showImage(image, image.aic, this.$api.user.id);
  }
}
