




























































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
// import Constants from "@/Constants";
import { getKennungValueDisplay, getKennungValue, getKennungTitle } from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";
import Constants from "@/Constants";
import { VarUebergabe } from "@/CalcHelperStatic";

@Component
export default class SoftTermBDE extends EditPage {
  private auftrag: string = "";
  private kst: string = "";
  private taetigkeit: string = "";
  private letzteBuchung: string = "---";
  private letzteBuchungUm: string = "---";
  private letzteBuchungAic: string = "";
  private showMobil: boolean = false;

  constructor() {
    super();
    // this.aic = this.$api.user.aic;
    // debugger;
    // if (this.siteData.keinBDEAntrag || this.siteData.admin) {
    //   this.query = "Web_SoftwareTerminal";
    // } else if (this.checkBegriff("Web_NacherfassungBDE")) {
    //   this.query = "WebAntragStempelungen";
    // } else {
    //   this.query = "Web_SoftwareTerminal";
    // }
  }
  public created() {
    if (this.siteData.keinBDEAntrag || this.siteData.admin) {
      this.query = "Web_SoftwareTerminal";
    } else if (this.checkBegriff("Web_NacherfassungBDE")) {
      this.query = "WebAntragStempelungen";
    } else {
      this.query = "Web_SoftwareTerminal";
    }
  }
  public mounted() {
    this.mountedBase();
    console.log("BDE start...", this.zeitbereich);
  }
  protected initialize() {
    this.letzteBuchung = getKennungValueDisplay(this.data, "BDE", this.columns);
    this.letzteBuchungUm = getKennungValueDisplay(this.data, "Uhrzeit", this.columns);
    this.letzteBuchungAic = getKennungValue(this.data, "BDEAIC", this.columns);
    if (Constants.isMobile) {
      const auftragHandy = getKennungTitle("Auftrag_Handy", this.columns);
      if (auftragHandy) {
        this.showMobil = true;
      }
    }
  }

  public save() {
    let varUebergabe: VarUebergabe[] = [];
    if (this.siteData.varUbergabe) {
      varUebergabe = this.siteData.varUbergabe;
    }
    if (this.auftrag || this.kst || this.taetigkeit) {
      // eines MUSS vorhanden sein!
      this.onSave(this.data, "", "", this.queryAIC, true, varUebergabe);
    } else {
      const auftragText = getKennungTitle("INSTRUCTION", this.columns);
      const kstText = getKennungTitle("COST_CENTRE", this.columns);
      const taetigkeitText = getKennungTitle("ACTIVITY", this.columns);
      let meldung = this.$globalsBegriffMemo(
        this.Globals.Begriff,
        "WEB_SpeichernNichtMoeglich",
        "Speichern kann nicht durchgefÃ¼hrt werden da folgende Daten leer sind"
      );
      meldung = meldung + ": " + auftragText + ", " + kstText + ", " + taetigkeitText;
      showSnackbar({
        text: meldung,
        color: "warning",
      });
    }
  }
  public bewDelete(aic: any) {
    if (aic) {
      this.onDelete(aic, "");
      this.letzteBuchung = "";
      this.letzteBuchungAic = "";
      this.letzteBuchungUm = "";
    }
  }
}
