









































































































































































































































































































































































































































































































































// import { Component, Vue } from 'vue-property-decorator';
import AUApi from "./api";
// import HelloWorld from "./components/HelloWorld.vue";
import Vue from "vue";
// import { Browser, extend } from '@syncfusion/ej2-base'; in einem Sample gesehen... eventuell noch hilfreich...
import Constants from "@/Constants";
import { setCulture } from "@syncfusion/ej2-base";
import "@/style.css";
// import { enableRipple } from "@syncfusion/ej2-base";
import { Component } from "vue-property-decorator";
import { initializeCalender } from "@/editor/CalenderHelper";
import {
  closeDialog,
  DialogParameter,
  initializeUIHelper,
  showDialog,
  showSnackbar,
} from "@/UIHelper";
import { setTokenEnd, checkTokenRefresh, initializeTokenHelper } from "@/TokenHelper";
import {
  pushSite,
  goBack,
  goToState,
  goBackTo,
  showSite,
  initNavigationHelper,
  onShowSite,
  setRootView,
} from "@/NavigationHelper";
import { curentALLVersion, setUpdateVersionCallback } from "./VersionChecker";
import { reloadApp } from "./NativeUpdateProvider";
import { initAutoAus } from "./AutoAusHelper";
import { getDeviceInfo } from "./DeviceInfoPlugin";
import { formatDate } from "@/editor/CalenderHelper";
import {
  cancelScan,
  initializeBarcodeWrapper,
  toggleBarcodeScannerTorch,
} from "@/BarcodeScanWrapper";
import { initAppUnloadHandler } from "@/NativeUpdateProvider";

declare var ALLVersion: ALLVersionType;

@Component
export default class App extends Vue {
  private username!: string;
  private loginVisible: boolean = true;
  private showPassword: boolean = false;
  private authCodeDialog: boolean = false;
  private authCode: string = "";
  private drawer: boolean = false;
  private fixed: boolean = false;
  private appHeight: string = "60px";

  public bottomMenuVisible: boolean = false;
  public activeBtn: any = 0;

  private alertText: string = "....";
  private alertVisible: boolean = false;
  private alertColor: string = "";
  private alertType: string = "error";
  private warVersion: string = "";
  private webVersion: string = "";
  private showDebug: boolean = false;
  private showAdmin: boolean = false;
  private useAdmin: boolean = false;
  private showIcon: boolean = true;
  private tooltipAd: string = "";
  private onlyAdmin: boolean = false;
  private lastAdmin: Date = new Date();
  private showZeitRaumInHeader: boolean = false;
  private admin: boolean = false;
  private logoutInProgress = false;

  private showComponents: boolean = true;

  private avatar: any = this.$api.getUserAvatar();
  private tokenEnde: any = "";
  private showAvatar: boolean = false;
  private date: string = new Date().toISOString().substr(0, 7);
  private menu: boolean = false;
  private startVal: any = this.$api.zeitbereich.von;
  private endVal: any = this.$api.zeitbereich.bis;

  private menuItems: any[] = [];
  private userItems: any = [
    { text: "Mandanten", icon: "contacts" },
    { text: "Debug aktivieren", icon: "query_builder", action: "debug" },
  ];
  private zeitbereichDialog: boolean = false;
  private closeOnClick: boolean = true;

  private activeStackIndex: number = 0;
  private contentStack: any[] = [
    {
      site: "AllWelcome",
      title: this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_Willkommen",
        "Willkommen"
      ),
    },
  ];
  // private isNavigating: boolean = false;

  private showDef: boolean = false;

  private rootView: any = {
    site: "AllWelcome",
    data: null,
    title: this.$globalsBezeichnung(this.Globals.Begriff, "Web_Willkommen", "Willkommen"),
  };

  private dialogs: DialogParameter[] = [];
  private showDialogs = true;
  private isApiBusy: boolean = false;
  private showBarcodeReader: boolean = false;

  public created() {
    const me = this;
    initializeUIHelper(this, this.dialogs);
    Constants.App = this;
    document.getElementById("cancelBarcodeScan")!.onclick = (e) => {
      e.cancelBubble = true;
      e.preventDefault();
      cancelScan();
      return false;
    };

    document.getElementById("toogleBarcodeScanTorch")!.onclick = (e) => {
      e.cancelBubble = true;
      e.preventDefault();
      toggleBarcodeScannerTorch().catch((err) => console.log(err));
      return false;
    };

    const scannerOverlay = document.getElementById("scannerOverlay");
    const toogleBarcodeScanTorch = document.getElementById("toogleBarcodeScanTorch");

    // block clicks from doing unexpected stuff...
    scannerOverlay!.onclick = (e) => {
      e.cancelBubble = true;
      e.preventDefault();
      return false;
    };

    initAppUnloadHandler();

    initializeBarcodeWrapper(
      () =>
        new Promise<void>((a, r) => {
          const appDiv = document.getElementById("app");
          appDiv!.classList.remove("appFadeIn");
          appDiv!.classList.add("appFadeOut");
          scannerOverlay!.classList.add("appFadeIn");
          setTimeout(() => {
            scannerOverlay!.style.display = "block";
          }, 1000);
          a();
        }),
      () =>
        new Promise<void>((a, r) => {
          const appDiv = document.getElementById("app");
          appDiv!.classList.add("appFadeIn");
          scannerOverlay!.style.display = "none";
          scannerOverlay!.classList.remove("appFadeOut");

          appDiv!.classList.remove("appFadeOut");
          a();
        })
    );
    const safeAreaInsets = require("safe-area-insets");
    const bounds = safeAreaInsets.top;
    console.log("Notch: " + bounds);
    // console.log("mobil: " + Constants.isMobile);
    let tablet = this.$isTablet();
    let deviceInfo = false;
    getDeviceInfo(true).then((t) => {
      deviceInfo = true;
      tablet = t.isTablet;
      this.$forceUpdate();
      console.log("deviceinfo: " + JSON.stringify(t));
      const phone = this.$isPhone();
      if (bounds > 40 && !tablet) {
        this.appHeight = "70px";
      } else if (phone && !tablet) {
        this.appHeight = "50px";
      } else if (tablet) {
        this.appHeight = "70px";
      }
      console.log("isPhone:" + this.$isPhone());
      console.log("isPad:" + this.$isTablet() + "Tablet" + tablet);
      console.log("appHeight: " + this.appHeight);
    });
    if (!deviceInfo) {
      if (bounds > 40 && !tablet) {
        this.appHeight = "70px";
      } else if (this.$isPhone() && !tablet) {
        this.appHeight = "50px";
      }
    }
    console.log("isPhone:" + this.$isPhone());
    console.log("isPad:" + this.$isTablet() + "> 600" + tablet);
    console.log("app: " + this.appHeight);
    initNavigationHelper(this, this.contentStack, {
      getActiveViewIndex: () => me.activeStackIndex,
      setActiveViewIndex: (i) => (me.activeStackIndex = i),
      refreshFullPageContent: me.refreshFullPageContent,
      refreshZeitRaumInHeader: me.refreshZeitRaumInHeader,
      setBottomActiveButton: (btn) => (me.activeBtn = btn),
    });
    initializeTokenHelper(this);
    setUpdateVersionCallback(() => {
      this.warVersion = curentALLVersion.warVersion;
      this.webVersion = curentALLVersion.webVersion;
    });
    this.$api.check();
    this.$api.setBusyState = (x) => {
      this.isApiBusy = x;
      this.$root.$emit("isApiBusy", x);
    };
    this.$api.showBusyMessage = () => {
      showSnackbar({
        text: this.$globalsBezeichnung(
          this.Globals.Begriff,
          "Web_Berechnung_laueft:",
          "Berechnung lÃ¤uft..."
        ),
        duration: 800,
        color: "error",
      });
    };
    window.addEventListener("resize", this.onResize, { passive: true });
    this.$root.$on("addMenu", (value: any) => {
      me.addMenu(value.site, value.name, value.icon, value.img, value.subsites);
    });
    this.$root.$on("showDialog", (value: any) => {
      console.warn("pls refactor to use UIHelper.showDialog!!!");
      showDialog(value);
    });
    this.$root.$on("snackbar", (value: string | SnackbarData) => {
      showSnackbar(value);
    });

    this.$root.$on("doLogout", () => {
      me.handleLogout();
    });

    this.$root.$on("alert", (value: any) => {
      if (value.text) {
        me.onAlert(value.text, value.type, value.color);
      }
    });

    this.$root.$on("onShowSite", (parameter: any) => {
      console.warn("onShowSite old... pls refactor");
      onShowSite(parameter);
    });
    this.$root.$on("showSite", (parameter: any) => {
      console.warn("showSite old... pls refactor");
      onShowSite(parameter);
    });

    this.$root.$on("goBack", () => {
      console.warn("goBack old... pls refactor");
      goBack();
    });
    me.$api.viewStammdatenUpdate = (d: any) => {
      showSnackbar("Daten wurden aktualisiert!");
      // showSnackbar("Daten wurden aktualisiert:" + " " + d);
      // this.refreshFullPageContent(); // 4.6.2020 soll auch alles neu geladen werden?
    };
    me.$api.viewError = (d: any) => {
      if (d) {
        // gibt es einen Error Code zeige ich diesen an
        if (d.response) {
          me.alertText = d.response.message;
          console.error(d.response.message);
          me.alertVisible = true;
          if (d.response.status === 401) {
            // bei 401er Error automatisch ausloggen...
            console.error("401er Error");
            setTimeout(() => this.handleLogout(), 2000);
            // this.handleLogout();
          }
        } else {
          me.alertText = d;
          console.error(d);
          me.alertVisible = true;
        }
      } else {
        me.alertText = "Fehler beim kontaktieren des Servers!";
        me.alertVisible = true;
      }
      if (typeof d === "string") {
        me.alertText += "\r\n" + d;
      } else if (d.message !== undefined) {
        console.error(d.message);
        if (d.stack !== undefined) {
          console.error(d.stack);
          me.alertText = d;
          if (d.response && d.response.data && d.response.data.error) {
            me.alertText = d.response.data.error + " " + d;
          }
        }
      }
    };

    window.onpopstate = (event: any) => {
      goToState(document.location.href, event.state);
    };

    getDeviceInfo().catch((e) => console.log(e));
  }
  private tooltipAdmin() {
    const date2 = new Date().getTime();
    const date = this.lastAdmin.getTime();
    const diff = Math.abs(date2 - date) / 1000;
    if (diff > 10 || this.tooltipAd === "") {
      this.lastAdmin = new Date();
      let text = "";
      if (this.useAdmin) {
        text = this.$globalsBegriffMemo(
          this.Globals.Begriff,
          "Web_Admin_aktiv",
          "Erweiterte Rechte aktiv!"
        );
      } else {
        text = this.$globalsBezeichnung(
          this.Globals.Begriff,
          "Web_Admin_setzen",
          "Erweiterte Rechte aktivieren"
        );
      }
      const refresh = formatDate(new Date(), "HH:mm:ss");
      const testAnzahl = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_Verfuegbar",
        " VerfÃ¼gbar: "
      );
      const textAnzeige = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_letzteAktualiserung",
        "letzte Aktualisierung: "
      );
      this.$api.countAdmin().then((e: any) => {
        this.tooltipAd =
          text + "<br>" + testAnzahl + e.data.anzahl + "<br>" + textAnzeige + refresh;
      });
    }
  }
  private async setAdmin(value: boolean) {
    this.tooltipAdmin();
    if (value) {
      const getAdmin = await this.$api.getAdmin();
      if (getAdmin.data.admin === true) {
        this.useAdmin = true;
        if (this.checkBegriff("Web_DynDashboardVG")) {
          const me = this;
          if (this.checkBegriff("LZ_web_SoftTerm")) {
            me.activeBtn = 1;
          }
          this.onShowBottomSite("DashboardAufgabenTemplatedPage", "", "Dashboard", 0);
        }
        // wenn Admin noch erlaubt - dann auf Admin Masken umschalten!
      } else {
        this.useAdmin = false;
        const text = this.$globalsBegriffMemo(
          this.Globals.Begriff,
          "Web_Admin_Lizenz",
          "Erweiterte Sicht nicht mÃ¶glich, es sind alle ArbeitsplÃ¤tze in Verwendung- bitte versuchen Sie es in einigen Minuten nochmals."
        );
        showSnackbar({
          text,
          color: "warning",
          duration: 4000,
          closedManual: true,
        });
      }
    } else {
      const getAdmin = await this.$api.deleteAdmin();
      this.useAdmin = false;
      const textFormular = "Buchungen";
      const userArray: any[] = this.$api.user.startArray;
      const start = userArray.find((sn) => sn === textFormular);
      if (start) {
        if (this.checkBegriff("Web_Buchungen")) {
          if (this.checkBegriff("Web_DynDashboardMA")) {
            this.onShowBottomSite("SoftTermBuchungen", "", "Buchungen", 1);
          } else {
            this.onShowBottomSite("SoftTermBuchungen", "", "Buchungen", 0);
          }
        } else if (this.checkBegriff("LZ_web_SoftTerm")) {
          this.onShowBottomSite("SoftTermSoftTerm", "", "Home", 0);
        }
      } else if (this.checkBegriff("LZ_web_SoftTerm")) {
        this.onShowBottomSite("SoftTermSoftTerm", "", "Home", 0);
      }
      const text = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_Admin_entfernen",
        "Erweiterte Sicht wurde entfernt"
      );
      showSnackbar({
        text,
        color: "warning",
        duration: 4000,
        closedManual: true,
      });
      if (this.bottomMenuVisible) {
        const me = this;
        me.activeBtn = 0;
        goBackTo(this.contentStack[0], 0);
      }
    }
  }
  private dialogClickOutside(dlg: any) {
    if (dlg.persistent) {
      return;
    }
    closeDialog(dlg);
  }

  // wenn die navigation geschlossen wird - lokal auch merken, damit toggle funktioniert
  public navigationClosed() {
    if (this.drawer) {
      this.drawer = false;
    }
  }

  protected closeDialog(data: DialogParameter, returnValue: boolean, returnedData?: any) {
    if (returnedData && !data.returnedData) {
      data.returnedData = returnedData;
    }
    closeDialog(data, returnValue);
  }

  protected checkBegriff(kennung: string) {
    const x = this.$globalsKennung(this.Globals.Begriff, kennung, false);
    return x;
  }

  protected goBack() {
    console.log("goBack old - pls use NavigationHelper!!");
    goBack();
  }

  private onResize() {
    if (this.dialogs.length > 0) {
      console.log("skip resize if dialog is open");
      return;
    }
    const comp: any = this.$refs.currentComponent;
    const args = {
      isMobile: Constants.isMobile,
      width: window.innerWidth,
      height: window.innerHeight,
      bottomMenuVisible: this.bottomMenuVisible,
    };
    if (comp) {
      if (comp.length && comp.length > 0) {
        for (let i = 0; i < comp.length; i++) {
          if (comp[i].onResize && typeof comp[i].onResize === "function") {
            comp[i].onResize(args);
          }
        }
      } else {
        if (comp.onResize && typeof comp.onResize === "function") {
          comp.onResize(args);
        }
      }
      this.$root.$emit("windowResize", args);
    }
  }

  private toggleClick() {
    (this.$refs.dockSidebar as any).toggle(); // cast auf any weil er den Typ nicht kennt...
  }

  private handleLogout() {
    this.logoutInProgress = true;
    const me = this;
    // console.log("logout" + " " + this.Benutzer);
    return this.$api.logout(false).then((response: any) => {
      const msgType = response.data.msgType;
      const info = response.data.info;
      const memo = response.data.memo;
      const title = response.data.titel;
      if (info) {
        if (msgType === "Error") {
          showSnackbar({
            text: info,
            color: "error",
          });
          reloadApp(true); // immer reloaden auch wenn ein Error kommt!
        } else if (msgType === "Info") {
          showSnackbar({
            text: info,
            color: "info",
          });
          reloadApp(true);
        } else if (msgType === "Warnung") {
          showSnackbar({
            text: info,
            color: "warning",
          });
        }
      } else {
        reloadApp(true);
      }
    });
  }

  // private handleRefresh() {
  //   this.$api.refresh().then((response: any) => {
  //     console.log("alle Tabellen werden neu geladen!");
  //   });
  //   return;
  // }
  private async sendCode() {
    const response = await this.$api.getCode(this.authCode);
    if (response?.data?.token) {
      this.authCodeDialog = false;
      this.$api.user.id = response?.data?.token;
      this.$api.user.time = response.data.time;
      setTokenEnd(response.data.time);
    } else {
      this.authCode = "";
      showSnackbar({
        text: "Code falsch - versuchen Sie es bitte erneut",
        color: "warning",
      });
    }
  }
  private async showApp(login: boolean, pw: any) {
    // Navigation initialisieren...
    // console.log("showing startPage: " + JSON.stringify(this.rootView));
    if (this.$api.user.bildFile) {
      this.showAvatar = true;
    }
    if (this.$api.user.admin && this.$api.user.ohne) {
      this.useAdmin = true;
    } else if (this.$api.user.admin) {
      const persFrame = this.$globalsFrame("Begriff", true);
      // nur wenn es keine pers- Formulare gibt - dann soll gleich Admin gesetzt werden
      if (persFrame.length === 0) {
        const getAdmin = await this.$api.getAdmin();
        if (getAdmin.data.admin === true) {
          this.useAdmin = true;
          this.onlyAdmin = true;
        } else {
          const text = this.$globalsBegriffMemo(
            this.Globals.Begriff,
            "Web_Admin_Logout",
            "Es sind derzeit sÃ¤mtliche lizenzierte ArbeitsplÃ¤tze in Verwendung. Bitte versuchen Sie es in einigen Minuten nochmals."
          );
          showSnackbar({
            text,
            duration: 50000,
            color: "red",
          });
          setTimeout(() => this.handleLogout(), 5000);
        }
      } else {
        const rootMask = persFrame.find((m: any) => m.kennung === "SoftTerm");
        if (!rootMask) {
          if (this.contentStack) {
            this.onShowBottomSite(
              this.contentStack[0].site,
              "",
              this.contentStack[0].name,
              0
            );
          } else {
            this.onShowBottomSite("SoftTermAnwesenheiten", "", "Anwesenheiten", 0);
          }
        }
      }
      // this.activeBtn = 0;
      // goBackTo(this.contentStack[0], 0);
    } else {
      const persFrame = this.$globalsFrame("Begriff", true);
      // wurde ADMIN nicht gesetz - aber es gibt trotzdem KEINE pers. Formulare
      // dann muss abgefangen werden das der USER dann OHNE Admin Lizenz rein kann!
      if (persFrame.length === 0) {
        const getAdmin = await this.$api.getAdmin();
        if (getAdmin.data.admin === true) {
          this.useAdmin = true;
          this.onlyAdmin = true;
        } else {
          const text = this.$globalsBegriffMemo(
            this.Globals.Begriff,
            "Web_Admin_Logout",
            "Es sind derzeit sÃ¤mtliche lizenzierte ArbeitsplÃ¤tze in Verwendung. Bitte versuchen Sie es in einigen Minuten nochmals."
          );
          showSnackbar({
            text,
            duration: 50000,
            color: "red",
          });
          setTimeout(() => this.handleLogout(), 5000);
        }
      }
    }
    if (this.$api.user.sprache) {
      console.log("setting language " + this.$api.user.sprache);
      setCulture(this.$api.user.sprache.toLowerCase());
      this.$api.user.locale = "de-at";
      if (this.$api.user.sprache === "EN") {
        this.$api.user.locale = "en";
      }
      initializeCalender(this.$api);
    } else {
      // keine ISO Sprache dann fix DE
      this.$api.user.locale = "de-at";
      this.$api.user.sprache = "DE";
    }
    if (this.$api.user.time) {
      const tokenAblauf = this.$api.user.time;
      this.tokenEnde = new Date(tokenAblauf).toLocaleTimeString();
    }

    initAutoAus((this.$api?.user?.autoAus ?? 0) * 1000, () => {
      showSnackbar({
        text: "Sie werden wegen InaktivitÃ¤t abgemeldet",
        duration: 10000,
        color: "red",
      });
      this.handleLogout();
    });
    window.history.pushState(this.rootView, this.rootView.title, this.rootView.site);
    this.loginVisible = false;
    this.username = this.$api.user.bezeichnung;
    if (Constants.isMobile) {
      this.username = this.username.slice(0, 12); // text abschneiden
      this.username = this.username + "...";
    }
    if (this.$api.user.rechte === "Def") {
      this.showDef = true;
    }
    if (this.$api.user.admin) {
      this.showAdmin = true;
      console.log("Mobil: " + Constants.isMobile);
      console.log("phone: " + this.$isPhone());
      if (Constants.isMobile) {
        // am handy immer wegschalten nicht nur in der App!
        this.showIcon = false;
      }
    }
    if (this.$api.user.code) {
      this.authCodeDialog = true;
    }
    const pwCheck = pw.pwValid;
    if (!pwCheck) {
      console.log("PW Meldung: " + pw.pwMessage);
      const text = pw.pwMessage;
      if (this.$api.user.rechte !== "Def" && !Constants.isDebug) {
        this.showPassword = true;
        showSnackbar({
          text,
          duration: 50000,
          color: "red",
          position: "top",
          hPosition: "right",
        });
      }
    }
    this.rebuildUserMenu();
    // const me = this;
    // Meldung Ã¼ber Anmeldung wollen die Kunden nicht... (4.11.2020)
    // const snackMeldung: string = this.$globalsBegriffMemo(
    //   this.Globals.Begriff,
    //   "Web_Anmeldung",
    //   "Danke fÃ¼r die Anmeldung!"
    // );
    // const snackText = this.$api.user.bezeichnung + ": " + snackMeldung;
    // showSnackbar(snackText);
    // this.setTokenRefreshTimer();
    checkTokenRefresh(); // wird Ã¼ber den Header refresht soll Token IMMER neu geladen werden!
    this.avatar = this.$api.getUserAvatar();
    this.refreshZeitRaumInHeader();
    if ((window as any).resizeFrame) {
      (window as any).resizeFrame();
    }
  }
  private rebuildUserMenu() {
    // in Navigation.vue auch - sollte nicht doppelt sein ...
    this.userItems[0].subItems = null;
    if (this.$api.user.mandantW) {
      const mandantenMenu = [];
      for (let i = 0; i < this.$api.user.mandantW.length; i++) {
        const mid = this.$api.user.mandantW[i];
        const mandant: any = this.$api.Mandanten.find((m: any) => m.aic === mid);
        if (mandant) {
          let color = "#ccc";
          if (mid === this.$api.mandant.aic) {
            color = "black";
          }
          mandantenMenu.push({
            text: mandant.bezeichnung,
            data: mandant,
            action: "mandant",
            icon: "supervised_user_circle",
            color,
          });
        }
      }
      this.userItems[0].subItems = mandantenMenu;
    }
  }
  private getUserMessage() {
    const me = this;
    this.$api.getMessage(true).then((data: any) => {
      // console.info("Nachrichten: " + data.data);
      const nachrichten = data.data;
      if (nachrichten.length > 0) {
        for (let i = 0; i < nachrichten.length; i++) {
          const text = nachrichten[i].memo;
          const aic = nachrichten[i].aic_bew_pool;

          showSnackbar({
            text,
            duration: -1,
            color: "green",
            position: "top",
            hPosition: "right",
            isMessage: true,
            height: 240,
            aic,
            css: "white-space: pre-wrap; overflow: scroll;",
            onClose: (sb) => {
              if (sb.closedManual && sb.aic) {
                me.$api.quittMessage(sb.aic);
                me.$api.user.msg = me.$api.user.msg - 1;
              }
            },
          });
        }
      }
    });
  }
  private addMenu(site: string, name: string, icon: string, img: string, subsites: any) {
    if (!img && !icon) {
      img = "https://via.placeholder.com/128/bd1932/FFFFFF?text=" + name.substr(0, 2);
    }
    if (name) {
      name = this.$begriffBezeichnung(name);
    }
    this.menuItems.push({ site, name, icon, img, subsites });
    if (this.menuItems && this.menuItems.length > 1) {
      this.bottomMenuVisible = false;
    } else {
      this.bottomMenuVisible = true;
      // StartFormular WEB_Admin soll immer SideMenu anlegen!
      const textAdmin = "Web_Admin";
      const userArray: any[] = this.$api.user.startArray;
      this.admin = userArray.find((sn) => sn === textAdmin);
      if (this.admin) {
        this.bottomMenuVisible = false;
      }
    }

    Constants.BottomMenuVisible = this.bottomMenuVisible;
  }
  private onShowBottomSite(site: string, data: any, title: string, aic: any) {
    this.activeStackIndex = 0;
    setRootView({ site, data, title, aic });
    // onShowSite({ site, data, title, aic, replaceCurrent: true });
  }
  private onShowSite(site: string, data: any, title: string, aic: any) {
    onShowSite({ site, data, title, aic, replaceCurrent: true });
  }
  private onGoBackTo(item: any, i: any) {
    goBackTo(item, i);
  }
  private onZeitraumChanged(newZeitraum: any) {
    this.refreshFullPageContent();
  }
  private onMonthChanged(args: any) {
    this.menu = false;
    this.date = args;
    if (!args) {
      return;
    }
    const vonDate = new Date(args);
    const bisDate = new Date(new Date(vonDate).lastInMonth());
    this.$api.zeitbereich = {
      bereich: "Tag",
      von: vonDate,
      bis: bisDate,
    };
    this.refreshFullPageContent();
  }
  public refreshFullPageContent() {
    this.showComponents = false;
    this.$nextTick(() => {
      this.showComponents = true;
      this.refreshZeitRaumInHeader();
    });
  }
  private refreshZeitRaumInHeader() {
    // this.getUserMessage();
    // warten auf Navigation fertig gerendert...
    this.$nextTick(() => {
      // letzte Komponente fragen ob die einen Zeitraum sehen mag.
      const contentComponents = this.$refs.currentComponent as Array<{
        showZeitraumInHeader?: boolean;
      }>;
      if (contentComponents) {
        this.showZeitRaumInHeader =
          contentComponents[contentComponents.length - 1]?.showZeitraumInHeader ?? false;
      }
    });
  }
  private onAlert(text: string, type: string, color: string) {
    this.alertText = text;
    this.alertVisible = true;
    this.alertColor = color;
    this.alertType = type;
    const me = this;
  }
  private toggleTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }
  private get getALLVersion() {
    return curentALLVersion;
  }
}
