

















































































































































































































































































































































































































































import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
// import BaseList from "@/components/BaseList";
import EditPage from "@/components/EditPage";
import {
  getMonthNames,
  getWeekdayNamesShort,
  valueToFormatStr,
} from "@/editor/CalenderHelper";
import AUApi from "@/api";
import { Globals } from "@/Globals";
import CreateList from "../CreateList";
import Constants from "@/Constants";
import { calcFunctionNew } from "@/CalcHelperStatic";
import BaseList from "../baseList.vue";
import {
  getKZValue,
  getKZValueDisplay,
  getKZTitle,
  getKennungValueDisplay,
} from "@/DataHelper";
// import { menuClass } from "@syncfusion/ej2-vue-grids";
import { DialogParameter, showDialog, showSnackbar } from "@/UIHelper";
import { onShowSite, showSite, refreshFullPageContent } from "@/NavigationHelper";

@Component
export default class VTimeLineDay extends CreateList {
  @Prop({ default: null }) public day!: Date | null;
  @Prop({ default: false }) private showName!: boolean;
  @Prop() private nameUebergabe!: string;
  @Prop({ default: 300 }) public contentHeight!: number;
  @Prop({ default: 450 }) public contentWidth!: number;
  @Prop({ default: false }) private admin!: boolean;
  @Prop() private minDate!: string;

  private tag: any = 0;
  private wochenTag: string = "";
  private monat: string = "-";
  private timeLine: boolean = false;
  private nachErfassung: boolean = false;
  // private editaic!: number;
  private innerHeight: number = 199;
  private showDialog: boolean = false;
  private dialogDate: boolean = false;
  private sperre: boolean = false; // dÃ¼rfen Tage nicht mehr bearbeitet werden- dann wird sperre gesetzt
  // sperre ist zB Ampel Sperre nach FREIGABE... diese tage dÃ¼rfen NICHT geÃ¤ndert werden!
  private lastRefresh: string | null = null;
  private showBtnNacherfassung: boolean = false;
  private showEditDelete: boolean = false;
  private hidden: boolean = false;
  private showReisekostenMutable: boolean = false;
  private antragMoeglich: boolean = this.checkBegriff("Web_ST_Antrag_erzeugen");
  private warningBtnVisible: boolean = false;
  private warningBtn: string = "warning";
  private holiday: any = null;
  private titleColor = "dvhBackground";
  private name = "";
  private calcToday: boolean = false;
  private reisen: boolean = false;
  private reiseBeginn: boolean = false;
  private tempZeitbereich!: any;
  private bdeAntrag: boolean = false;
  private bdeErlaubt: boolean = false;
  private isApiBusy: boolean = false;
  private picker: string = new Date().toISOStringWithTZ().substr(0, 10);
  private maxDate: string = new Date().toISOStringWithTZ().substr(0, 10);
  // private dayChanged() {
  //   this.mounted();
  // }
  // erzeugt errors in der konsole...

  public created() {
    this.isApiBusy = AUApi.Current.isBusy;
  }
  public mounted() {
    this.showReisekostenMutable = this.showReisekosten;
    if (this.checkBegriff("BDEErfassun_Nacherfassung")) {
      this.bdeErlaubt = true;
    }
    if (this.checkBegriff("Web_NacherfassungBDE") && !this.admin) {
      this.bdeAntrag = true;
    }
    if (
      this.checkBegriff("Web_NacherfassungSoftTerm") ||
      this.checkBegriff("Web_NacherfassungRK")
    ) {
      if (!this.checkBegriff("Web_NacherfassungAntragSoftTerm")) {
        this.showEditDelete = true;
      }
    }
    if (this.admin) {
      this.showEditDelete = true;
      this.showBtnNacherfassung = true;
    }
    if (
      this.checkBegriff("Web_NacherfassungSoftTerm") ||
      this.checkBegriff("Web_NacherfassungAntragSoftTerm") ||
      this.checkBegriff("Web_NacherfassungRK")
    ) {
      this.showBtnNacherfassung = true;
    }
    try {
      const days = getWeekdayNamesShort();
      if (!this.day) {
        this.day = this.$api.zeitbereich.von;
      }
      this.picker = this.day.toISOStringWithTZ().substr(0, 10);
      if (this.nameUebergabe) {
        this.name = this.nameUebergabe;
      }
      const d = this.day;
      this.wochenTag = days[d.getDay()];
      this.tag = this.day.getDate();
      this.monat = getMonthNames()[this.day.getMonth()];
      const holiday = this.$api.getHoliday(d);

      if (holiday) {
        this.holiday = holiday;
        this.titleColor = "red";
      }
      const me = this;
      this.editZeitbereich = {
        von: this.day,
        bis: this.day,
        bereich: "Tag",
      };
      if (!this.editZeitbereich) {
        return;
      }
      // auf tag eingrenzen
      this.editZeitbereich = {
        von: this.editZeitbereich.von,
        bis: this.editZeitbereich.von,
        bereich: "Tag",
      };
      if (!this.queryAIC) {
        this.queryAIC = this.aic;
      }
      if (this.checkBegriff("WEB_heute_Berechnen")) {
        this.calcToday = true;
      }

      if (!this.mountedBase()) {
        return;
      }
      this.colorZulagen = "grey";
      if (this.showName && this.checkBegriff("Web_Workflow_StatusTimerSatz")) {
        this.loadAmpel("Web_Workflow_StatusTimerSatz", false, false, "", true);
      } else {
        this.loadAmpel("Web_StatusAntrag", true);
      }
      this.reisen = this.checkBegriff("Web_Reisekosten");
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  protected dataLoaded(data: any) {
    this.lastRefresh = valueToFormatStr(new Date(), "dd.MM.yy HH:mm:ss");

    this.dataLoadedBase(data);
  }

  protected refreshItems() {
    const x = this.$refs.warningList as BaseList;
    x?.reloadData();
    const y = this.$refs.reisen as any;
    y?.reloadData();
    this.reiseBeginn = false;
    this.items = []; // ausleeren fÃ¼r refresh
    // if (this.data.length > 0) {
    //   this.timeLine = true;
    // }
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      let dot = "mdi-dot";
      const antrag = getKZValueDisplay(row, "Antrag", this.columns);
      let avatar = getKZValueDisplay(row, "avatar", this.columns);
      const header = getKZValueDisplay(row, "header", this.columns);
      const title = getKZValueDisplay(row, "titel", this.columns);
      const title2 = getKZValueDisplay(row, "titel2", this.columns);
      const auftrag = getKZValueDisplay(row, "Auftrag", this.columns);
      const textAuftrag = getKZTitle(row, "Auftrag", this.columns);
      const kostenstelle = getKZValueDisplay(row, "Kst", this.columns);
      const textKst = getKZTitle(row, "Kst", this.columns);
      const taetigkeit = getKZValueDisplay(row, "Taetigkeit", this.columns);
      const textTaetigkeit = getKZTitle(row, "Taetigkeit", this.columns);
      const subtitel = getKZValueDisplay(row, "subtitel", this.columns);
      const subtitel1 = getKZValueDisplay(row, "subtitel1", this.columns);
      const text = getKZValueDisplay(row, "text", this.columns);
      const icon = getKZValueDisplay(row, "icon", this.columns);
      const tagessperre = getKZValueDisplay(row, "sperre", this.columns);
      // gibt es nach einer Zeile die den Tag sperrt noch eine andere zeile - dann hebt diese die Sperre auf!
      if (tagessperre && !this.admin) {
        // admin darf immer
        this.sperre = true;
      }
      let color = getKZValueDisplay(row, "color", this.columns);
      const aicAntrag = getKZValue(row, "AICAntrag", this.columns);
      const aicReise = getKZValue(row, "AICReise", this.columns);
      const bde = getKZValueDisplay(row, "BDE", this.columns);
      const reise = getKZValueDisplay(row, "reise", this.columns);
      const noDelete = getKZValue(row, "noDelete", this.columns);
      const sperre = getKZValue(row, "sperreReise", this.columns);
      const gps = getKZValue(row, "gps", this.columns);
      const bdeErlaubt = this.checkBegriff("BDEErfassun_Nacherfassung");
      if (sperre && !this.admin) {
        // admin darf immer
        this.sperre = sperre;
      }
      const reiseName = getKennungValueDisplay(row, "RK_REISEBEWSTAMM", this.columns);
      const bewilligtAntrag = getKZValueDisplay(row, "bewilligt", this.columns);
      const abgelehntAntrag = getKZValueDisplay(row, "abgelehnt", this.columns);
      const delDatensatz = getKZValueDisplay(row, "delete", this.columns);
      if (this.name === "" && this.showName) {
        this.name = getKZValueDisplay(row, "name", this.columns);
      }
      if (reise === "reise") {
        dot = "local_airport";
        this.reiseBeginn = true;
      }
      if (bde) {
        dot = "extension";
        color = "black";
      }
      if (antrag === "1.00" || antrag === "1,00") {
        dot = "mdi-star";
      }
      if (delDatensatz === "1.0") {
        dot = "delete_forever";
        // color = "red";
      }
      if (!color) {
        color = "green";
      }
      if (avatar) {
        const start = avatar.indexOf('"') + 1;
        const len = avatar.lastIndexOf('"') - start;
        avatar = avatar.substr(start, len);
      } else if (!avatar) {
        avatar = "mdi-account-circle";
      }
      this.items.push({
        header,
        title,
        title2,
        subtitel,
        subtitel1,
        auftrag,
        textAuftrag,
        kostenstelle,
        textKst,
        taetigkeit,
        textTaetigkeit,
        text,
        avatar,
        icon,
        divider: true,
        inset: true,
        color,
        aic: row.aic_Bew_pool,
        menu: false,
        dot,
        aicAntrag,
        bewilligtAntrag,
        abgelehntAntrag,
        bde,
        reise,
        reiseName,
        aicReise,
        noDelete,
        sperre,
        antrag,
        Abflatlong: gps,
      });
    }
    this.timeLine = true;
  }
  public bewDelete(aic: any) {
    if (aic) {
      if (this.editZeitbereich) {
        // fÃ¼r HEUTE soll die Zeitbuchung NICHT ausgelÃ¶st werden
        const d = new Date();
        const datumHeute = new Date(d.setHours(0, 0, 0, 0));
        const vonZeitZone = this.editZeitbereich.von;
        const von = new Date(vonZeitZone.setHours(0, 0, 0, 0));
        if (
          this.calcToday &&
          datumHeute.valueOf() === von.valueOf() &&
          this.checkBegriff("Web_NacherfassungSoftTerm")
        ) {
          // nicht das Modell:  "ZE_IstZeit_SoftTerm_berechnen"
          // sondern immer Zeit - Zulagenbuchung
          // 7.7.2021
          this.onDelete(aic, "Web_Zeit_Zulagenbuchung", this.editZeitbereich, this.aic);
        } else if (
          datumHeute.valueOf() !== von.valueOf() &&
          this.checkBegriff("Web_NacherfassungSoftTerm")
        ) {
          this.onDelete(aic, "Web_Zeit_Zulagenbuchung", this.editZeitbereich);
          // this.reloadData(); /* onDelete refresht schon"!*/
        } else {
          this.onDelete(aic, "", this.editZeitbereich);
        }
      } else {
        this.reloadData();
      }
    }
  }
  public bewDeleteAntrag(aicAntrag: any, aic: any, useAic: boolean, antrag: string) {
    // wenn es eine Stemplung mit Reise ist - dann aic zum LÃ¶schen
    // wenn es Antrag ist immer AICAntrag
    // wenn es nur eine ReiseBuchung ist AICAntrag
    if (aicAntrag && (!useAic || antrag === "1,00")) {
      this.onDelete(aicAntrag, "", this.editZeitbereich);
    } else if (aic === 0) {
      this.onDelete(aicAntrag, "", this.editZeitbereich);
    } else {
      this.onDelete(aic, "", this.editZeitbereich);
    }
  }
  private warningListLoaded(args: any) {
    if (this.showError) {
      // wenn es mindestens ein item gibt dann zeigen wirs an
      this.warningBtnVisible = args && args.items && args.items.length > 0;
      if (this.warningBtnVisible) {
        const warning = args.items?.find((item: any) => item.icon === "error_outline");
        if (warning) {
          this.warningBtn = "error";
        } else {
          this.warningBtn = "warning";
        }
      }
    }
  }
  private onBack(item: any) {
    // vom BDE Edit kommt er hier her - wurden daten gespeichert dann kommt ITEM mit
    // wird nicht gespeichert, brauch ich auch nix rechnen!
    if (item) {
      item.menu = false;
    }
  }
  private onDialogClose(item: any) {
    if (item && this.checkBegriff("Web_NacherfassungSoftTerm")) {
      item.menu = false;
      const me = this;
      setTimeout(() => this.calcModell("Web_Zeit_Zulagenbuchung"), 20);
    }
  }

  private calcModell(
    modellUebergabe: string,
    abwZeitraum?: string,
    fullReload?: boolean
  ) {
    const modell = modellUebergabe;
    const me = this;
    if (this.editZeitbereich) {
      // fÃ¼r HEUTE soll die Zeitbuchung NICHT ausgelÃ¶st werden
      const d = new Date();
      const datumHeute = new Date(d.setHours(0, 0, 0, 0));
      const vonZeitZone = this.editZeitbereich.von;
      const von = new Date(vonZeitZone.setHours(0, 0, 0, 0));
      const begriffModell = this.$globalsKennung(Globals.Begriff, modell);
      if (this.calcToday || datumHeute.valueOf() !== von.valueOf()) {
        if (abwZeitraum) {
          const day = new Date(abwZeitraum);
          if (day) {
            this.tempZeitbereich = this.editZeitbereich;
            this.editZeitbereich = {
              von: day,
              bis: day,
              bereich: "Tag",
            };
          }
        }
        if (begriffModell) {
          calcFunctionNew(modell, this.aic, false, me, this.editZeitbereich, [])
            ?.then((response: any) => {
              if (this.tempZeitbereich) {
                this.editZeitbereich = this.tempZeitbereich;
                this.tempZeitbereich = null;
                me.reloadData();
                setTimeout(() => refreshFullPageContent());
              } else {
                me.reloadData();
                if (fullReload) {
                  setTimeout(() => refreshFullPageContent());
                }
              }
            })
            .catch((error: any) => {
              console.log(error);
            });
          // den Teil muss ich drinnen lassen, weil sonst wird erst der Relaod
          // gemacht wenn die Zeitbuchung fertig ist! wir warten aber nicht drauf!
          if (this.tempZeitbereich) {
            this.editZeitbereich = this.tempZeitbereich;
            this.tempZeitbereich = null;
            me.reloadData();
            setTimeout(() => refreshFullPageContent(), 20);
          } else {
            me.reloadData();
            if (fullReload) {
              setTimeout(() => refreshFullPageContent(), 20);
            }
          }
          return;
        } else {
          me.reloadData();
          return;
        }
      }
      me.reloadData();
      return;
    }
  }

  private openProjektstunden() {
    if (!this.day) {
      return;
    }
    let showFullscreen = false;
    console.log("isPhone: " + this.$isPhone());
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    const me = this;
    onShowSite({
      title: this.$globalsBezeichnung(
        this.Globals.Begriff,
        "WebWFProjektstunden",
        "Projektstunden"
      ),
      site: "SoftTermProjektstundenProjektstunden",
      data: { zeitbereich: this.editZeitbereich },
      callOnLeave: () => {
        /* die Fehler aus der Maske Buchungen sollen refresht werden: */
        const x = me.$refs.warningList as BaseList;
        x?.reloadData();
      },
    });
  }
  private openNacherfassung() {
    // solange die Zeitncuhung nicht fertig ist - soll man nicht NEU Erfassen kÃ¶nnen!
    this.isApiBusy = AUApi.Current.isBusy;
    if (this.isApiBusy) {
      showSnackbar({
        text: this.$globalsBezeichnung(
          this.Globals.Begriff,
          "Web_Berechnung_laueft:",
          "Berechnung lÃ¤uft..."
        ),
        duration: 800,
        color: "error",
      });
      return;
    }
    const me = this;
    let showFullscreen = false;
    if (Constants.isMobile || this.$isPhone()) {
      showFullscreen = true;
    }
    let onlyBDE = false;
    if (this.sperre && this.bdeErlaubt) {
      onlyBDE = true;
    }
    let aic = 0;
    if (this.showName) {
      // showName ist aktiv wenn VG diese Sicht hat
      aic = this.aic;
    }
    showDialog({
      title: this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_Nacherfassung",
        "Nacherfassung"
      ),
      site: "SoftTermNacherfassung",
      width: 400,
      fullscreen: showFullscreen,
      persistent: true,
      data: {
        zeitbereich: this.editZeitbereich,
        reisenBeginn: this.reiseBeginn,
        nurBDE: onlyBDE,
        aic,
        admin: this.admin,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: (dlg: DialogParameter) => {
        this.$api.deleteVariable();
        if (dlg.data.abwZeitraum || dlg.data.fullReload) {
          const abwZeitraum = dlg.data.abwZeitraum;
          const fullReload = dlg.data.fullReload;
          this.timeLine = false;
          this.calcModell("Web_ZeitBuchungPruefung", abwZeitraum, fullReload);
        } else {
          this.timeLine = false;
          this.calcModell("Web_ZeitBuchungPruefung");
        }
        return true;
      },
    });
  }
  /* Calc function fÃ¼r neuberechnung  */
  private calcFunction(
    // brauch ich fÃ¼r den Antrag!
    modell: string,
    aic: number,
    fromDialog: boolean = false,
    fromBew: boolean = false
  ) {
    let begriff: any;
    if (modell) {
      begriff = this.$globalsKennung(Globals.Begriff, modell);
    }
    const me = this;
    if (begriff.userFrage && fromDialog !== true) {
      const userFrage = this.$globalsBegriffMemo(
        this.Globals.Begriff,
        begriff.userFrage,
        "Soll die Berechnung durchgefÃ¼hrt werden?"
      );
      showDialog({
        title: "Frage",
        titleColor: "white",
        titleClass: "black--text",
        width: 300,
        height: 400,
        text: userFrage,
        persistent: true,
        okButton: true,
        onClose: (x: any) => {
          if (x.dialogResult === true) {
            me.calcFunction(modell, aic, true, fromBew);
          }
          return true;
        },
      });
      return Promise.resolve();
    }
    this.showLoading = true;
    const varUbergabe: any[] = [];
    const tabUbergabe: any[] = [];
    // const vecStamm: any[] = [];
    const bemerkung: string = "Web - TimeLineDay";
    let bewAic = 0;

    if (fromBew === true) {
      bewAic = aic;
      aic = 0;
    }
    this.$api.postCalc(
      varUbergabe,
      tabUbergabe,
      begriff.aic,
      aic,
      this.editZeitbereich,
      0,
      0,
      bemerkung,
      this.success,
      begriff.maxB,
      this.vecStamm,
      bewAic
    );
    this.showLoading = false;
  }
  private openTagesSalden() {
    if (!this.day) {
      return;
    }
    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }

    const me = this;
    let isVorgesetzter = false;
    let aic = 0;
    if (this.showName) {
      // showName ist aktiv wenn VG diese Sicht hat
      isVorgesetzter = true;
      aic = this.aic;
    }
    showDialog({
      title: this.day.toLocaleDateString(),
      site: "SoftTermTagesSalden",
      width: 400,
      fullscreen: showFullscreen,
      data: {
        zeitbereich: this.editZeitbereich,
        sperre: this.sperre,
        isVorgesetzter,
        aic,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: (x: any) => {
        this.colorZulagen = "grey";
        if (x) {
          if (x.ergebnis) {
            const ergebnis = x.ergebnis;
            if (ergebnis === 0) {
              this.colorZulagen = "warning";
            } else if (ergebnis === 1) {
              this.colorZulagen = "success";
            } else if (ergebnis === 2) {
              this.colorZulagen = "error";
            } else if (ergebnis === 3) {
              this.colorZulagen = "error";
            }
          } else {
            if (this.showName && this.checkBegriff("Web_Workflow_StatusTimerSatz")) {
              this.loadAmpel("Web_Workflow_StatusTimerSatz", false, false, "", true);
            } else {
              this.loadAmpel("Web_StatusAntrag", true);
            }
          }
        } else {
          setTimeout(() => this.loadAmpel("Web_StatusAntrag", true), 100);
        }

        // setTimeout(() => this.loadAmpel("Web_StatusAntrag", true), 100);
        // this.loadAmpel("Web_StatusAntrag", true);
        me.reloadData();
        this.$forceUpdate();
        return true;
      },
    });
  }
  private openEditStempelungen(aic: string) {
    if (!this.day) {
      return;
    }
    this.isApiBusy = AUApi.Current.isBusy;
    if (this.isApiBusy) {
      showSnackbar({
        text: this.$globalsBezeichnung(
          this.Globals.Begriff,
          "Web_Berechnung_laueft:",
          "Berechnung lÃ¤uft..."
        ),
        duration: 800,
        color: "error",
      });
      return;
    }
    const me = this;
    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    showDialog({
      title: this.day.toLocaleDateString(),
      site: "SoftTermStempelEdit",
      width: 300,
      fullscreen: showFullscreen,
      data: {
        zeitbereich: this.editZeitbereich,
        aic,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",

      onClose: () => {
        if (this.checkBegriff("Web_NacherfassungSoftTerm")) {
          // this.timeLine = false;
          setTimeout(() => this.calcModell("Web_Zeit_Zulagenbuchung"), 20);
        } else if (this.checkBegriff("Web_NacherfassungRK")) {
          this.timeLine = false;
          this.reloadData();
          // this.$nextTick(() => {
          //   setTimeout(() => this.reloadData(), 100);
          // });
        }
        return true;
      },
    });
  }
  private openEditStempelungenBDE(aic: string) {
    if (!this.day) {
      return;
    }
    this.isApiBusy = AUApi.Current.isBusy;
    if (this.isApiBusy) {
      showSnackbar({
        text: this.$globalsBezeichnung(
          this.Globals.Begriff,
          "Web_Berechnung_laueft:",
          "Berechnung lÃ¤uft..."
        ),
        duration: 800,
        color: "error",
      });
      return;
    }
    const me = this;
    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    showDialog({
      title: this.day.toLocaleDateString(),
      site: "SoftTermStempelEditBde",
      width: 300,
      fullscreen: showFullscreen,
      data: {
        zeitbereich: this.editZeitbereich,
        aic,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: (x: any) => {
        // this.timeLine = false;
        // this.reloadData();
        if (x?.returnedData) {
          // this.timeLine = false;
          // console.log("im onClose der BDE mAske");
          // showSnackbar({
          //   text: "im OnClose  - mit returened Data von der BDE: " + x.returnedData.JSON,
          //   duration: 8000,
          //   color: "error",
          // });
          // this.timeLine = false;
          setTimeout(() => this.calcModell("Web_Zeit_Zulagenbuchung"), 20);
          // this.$nextTick(() => {
          //   me.reloadData();
          // setTimeout(() => this.reloadData(), 100);
          // });
        }
        return true;
      },
    });
  }
  private openEditStempelantrag(
    aicAntrag: string,
    bewilligtAntrag: string,
    abgelehntAntrag: string,
    edit: boolean,
    bde: boolean,
    openAlways?: boolean,
    reise?: string,
    noDelete?: boolean,
    oriAIC?: string
  ) {
    const me = this;
    if (!this.day) {
      return;
    }
    this.isApiBusy = AUApi.Current.isBusy;
    if (this.isApiBusy && !openAlways) {
      showSnackbar({
        text: this.$globalsBezeichnung(
          this.Globals.Begriff,
          "Web_Berechnung_laueft:",
          "Berechnung lÃ¤uft..."
        ),
        duration: 800,
        color: "error",
      });
      return;
    }
    let textTitel = this.$globalsBezeichnung(Globals.Begriff, "Web_offen", "offen");
    if (bewilligtAntrag !== null) {
      textTitel = bewilligtAntrag;
    } else if (abgelehntAntrag !== null) {
      textTitel = abgelehntAntrag;
    }

    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    const freigabe = this.freigabeAntrag;
    const text = this.$globalsBezeichnung(Globals.Begriff, "Antrag", "Antrag");
    showDialog({
      title: this.day.toLocaleDateString() + " " + text + ": " + textTitel,
      site: "SoftTermEditStempelantrag",
      width: 400,
      fullscreen: showFullscreen,
      persistent: true,
      data: {
        zeitbereich: this.editZeitbereich,
        aic: aicAntrag,
        freigabe,
        bewegungsdaten: true,
        edit,
        bde,
        reise,
        noDelete,
        oriAIC,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      noClose: true,
      onClose: (x: any) => {
        this.timeLine = false;
        this.reloadData();
        // this.$nextTick(() => {
        //   setTimeout(() => this.reloadData(), 100);
        // });
        return true;
      },
    });
  }
  private openEditReise(
    aicReise: string,
    reiseName: string,
    aic: string,
    aicAntrag: string
  ) {
    if (!this.day) {
      return;
    }
    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    console.log("fullscreen: " + showFullscreen);
    let showZeit = false;
    if (!aic && !aicAntrag) {
      showZeit = true;
      // ist es NUR eine Reisezeit (GrenzÃ¼bertritt) - dann darf auch die Uhrzeit geÃ¤ndert werden!
    }
    const title = this.day.toLocaleDateString() + ": " + reiseName;
    const me = this;
    showDialog({
      title,
      site: "SoftTermReisenEditReisen",
      width: 420,
      persistent: true,
      fullscreen: showFullscreen,
      data: {
        zeitbereich: this.editZeitbereich,
        aic: aicReise,
        bewegungsdaten: true,
        showZeit,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: (x: any) => {
        // this.timeLine = false;
        // this.reloadData();
        if (x?.returnedData) {
          this.$nextTick(() => {
            me.timeLine = false;
            me.reloadData();
            // setTimeout(() => this.reloadData(), 100);
          });
        }
        return true;
      },
    });
  }
  private success(res: any) {
    const aicResponse = res.data.aic;
    if (aicResponse) {
      let bde = false;
      if (res.data!.JSON && res.data.JSON.BDE) {
        bde = res.data.JSON.BDE;
      }
      let reise = "";
      if (res.data!.JSON && res.data.JSON.Reise) {
        reise = res.data.JSON.Reise;
      }
      // Antrag muss immer geÃ¶ffnet werden auch wenn Modell eventuell noch auf Busy ist!
      this.openEditStempelantrag(
        aicResponse,
        "",
        "",
        false,
        bde,
        true,
        reise,
        false,
        aicResponse
      );
    }
  }
  private setDate() {
    this.dialogDate = false;
    const date = new Date(this.picker);
    if (this.day) {
      this.picker = this.day.toISOStringWithTZ().substr(0, 10);
    }
    this.$emit("goToDate", date);
  }
  private selectToday() {
    this.picker = new Date().toISOString().substr(0, 10);
  }

  // umstellen auf CalcHelper
}
