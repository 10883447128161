import { VarUebergabe } from './../CalcHelperStatic';
import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import { getKennungTitle, getKennungValueDisplay, getValue } from "@/DataHelper";
import { Globals } from "@/Globals";
import { showDialog, showSnackbar } from "@/UIHelper";
import { refreshFullPageContent } from "@/NavigationHelper";

@Component
export default class EditPage extends Vue {
  public thisIsAnEditPage: boolean = true;
  public isValueChangeTrackerEnabled() { if (!this.header.aicBew) { return true; } return false; }

  protected query!: string | undefined;
  @Prop({ default: null }) protected lastValues: any;
  @Prop() protected aic!: number;
  @Prop() protected mainAic!: number;
  @Prop() protected mainStt!: number;
  protected zeitbereich!: APIZeitbereich | null;
  public bewegungsdaten!: boolean;
  // ohne @Prop sonst kommt ein Error - Zeitbereich kann auch aus SiteData kommmen
  @Prop({ default: true }) protected backOnSave!: boolean;
  @Prop({ default: true }) protected backOnDelete!: boolean;
  @Prop({ default: null }) public siteData: any;
  @Prop({ default: false }) public insertMode!: boolean;
  @Prop({ default: false }) protected newData!: boolean;
  @Prop() public preloadedData!: any;
  public printItems: PrintItems[] = [];
  public insertHint: boolean = false;
  public vecStamm: number[] = [];
  protected useVec!: boolean;
  public data: any = {};
  protected header: any = { bezeichnung: "lade..." };
  protected headerStamm: any = "";
  public queryAIC: number | null = null;
  public columns: DataColumn[] = [];
  protected showLoading: boolean = false;
  protected initialized: boolean = false;
  protected backupData: any;
  private validations: any[] = [];
  protected msgType: string = "";
  protected info: string = "";
  protected calcMessage: string = "";
  protected showKopie: boolean = false;
  protected avatarIcon!: string;
  private frame!: ALLFrame;
  public modelle: ALLModell[] = [];
  public exportItems!: AbfrageItems[];
  public items: Tabs[] = [];
  public step: number = 0;
  public stammKennung: string = "";
  public selected: number = 0;
  protected frageDelete: string = this.$globalsBegriffMemo(
    this.Globals.Begriff,
    "Loeschen2",
    "Soll der Datensatz wirklich gelÃ¶scht werden?"
  );
  protected jokerStt: any[] = [];
  @Prop({ default: null }) public formular!: any;

  public mounted() {
    this.mountedBase();
  }
  public getKennungTitle(kennung: string, column: DataColumn) {
    const x = getKennungTitle(kennung, column);
    return x;
  }
  public getKennungValueDisplay(data: any, kennung: string, column: any) {
    const x = getKennungValueDisplay(data, kennung, column);
    return x;
  }
  protected checkBegriff(kennung: string) {
    const x = this.$globalsKennung(Globals.Begriff, kennung, false);
    return x;
  }
  protected checkMobile(kennung: string) {
    const x = this.$globalsBegriffMobile(Globals.Begriff, kennung, false);
    return x;
  }

  protected mountedBase() {
    if (this.query === "" && !this.preloadedData) {
      // ist die Abfrage leer - dann brauch ich nichts laden!
      return;
    }
    if (this.aic) {
      this.queryAIC = this.aic;
    }
    this.insertHint = this.insertMode;
    if (this.siteData) {
      if (this.siteData.query) {
        this.query = this.siteData.query;
      }
      if (this.siteData.preloadedData) {
        this.preloadedData = this.siteData.preloadedData;
      }
      if (this.siteData.aic && typeof this.siteData.aic !== "object") {
        this.queryAIC = this.siteData.aic;
      }
      if (this.siteData.zeitbereich) {
        this.zeitbereich = this.siteData.zeitbereich;
      }
      if (this.siteData.tag) {
        this.zeitbereich = {
          von: this.siteData.tag,
          bis: this.siteData.tag,
          bereich: "Tag"
        };
      }
      if (this.siteData.insertHint) {
        this.insertHint = this.siteData.insertHint;
      }
      if (this.siteData.bewegungsdaten) {
        this.bewegungsdaten = this.siteData.bewegungsdaten;
      }
      if (this.siteData.site) {
        this.frame = this.siteData.site.compNameFrm ?? this.siteData.site.kennung;
        const frame = this.$globalsFrame("Begriff", false, "", "", false, this.frame);
        if (frame[0].mod2) {
          this.modelle = frame[0].mod2;
        }
        this.exportItems = frame[0].abf2;
      }
      if (this.siteData?.mainAic) {
        this.mainAic = this.siteData.mainAic; // Aufrufenden AIC zum Speichern Ã¼bergeben!
      }
      if (this.siteData.newData) {
        this.newData = this.siteData.newData;
      }
    }
    if (this.preloadedData) {
      this.dataLoaded(this.preloadedData);
      //  this.initialize();
    } else { this.reloadData(); }
  }

  protected async reloadData() {
    if (this.query != null) {
      this.showLoading = true;
      const me = this;
      let vecStamm: any[] = [];
      if (this.useVec) {
        vecStamm = this.vecStamm;
      }
      let varUebergabe: VarUebergabe[] = [];
      if (this.siteData && this.siteData?.varUbergabe) {
        varUebergabe = this.siteData.varUbergabe;
      }
      this.$api.getQuery(
        // Query
        this.query,
        // Done function
        (data: any) => me.dataLoaded(data),
        this.queryAIC,
        this.zeitbereich,
        0,
        false,
        this.bewegungsdaten,
        "",
        this.formular?.aic,
        vecStamm,
        "",
        this.newData,
        0,
        [],
        this.jokerStt,
      );
    } else {
      this.showLoading = false;
      this.initialized = true;
      this.initialize();
    }
  }

  protected dataLoaded(data: any) {
    try {
      if (data.data.error !== undefined) {
        this.$api.onfail(data.data.error);
        console.log(data.data.error);
        alert("ACHTUNG: " + data.data.error);
        this.$emit("back");
        return;
      }

      // diese Schleife initialisiert die filter Property auf da Spalte die es vom Server her nicht gibt ;)
      for (let i = 0; i < data.data.columns.length; i++) {
        data.data.columns[i].filter = "";
      }
      this.header = data.data.header;
      this.headerStamm = data.data.header.aicStt;
      this.columns = data.data.columns;
      if (data.data.data && data.data.data.length > 0) {
        this.data = data.data.data[0];
      } else {
        this.data = {};
      }
      // in Stammmasken kÃ¶nnen so Tabs automatisiert werdeb!
      const tabs: any = this.columns.filter(
        (c: any) => c.meta?.step !== "" && c.meta.step !== undefined
      );
      if (tabs.length > 0 && this.initialized) {
        this.items = [];
        tabs.forEach((r: any) => {
          let active = false;
          let value = 0;
          // wenn "Zahl" - dann gibt es eine Info ob es Daten zu dem Folder/Tab gibt!
          if (r.type === "zahl" && this.data) {
            value = getValue(this.data, r);
            if (value) {
              active = true;
            }
          }
          const query = r.meta?.subAbf[0]?.kennung;
          const step = r.meta?.step;
          this.items.push({
            text: r.title,
            icon: r.meta.icon ?? "info",
            color: r.meta.color ?? "success",
            step: parseInt(step, 10),
            component: r.meta.webDarstellung ?? "BaseVGrid",
            active,
            query,
            activeNumber: value,
          });
        });
      }


      if (this.data) {
        // wenn keine Daten da sind kann ich auch nix aufbereiten
        this.backupData = JSON.parse(JSON.stringify(this.data));
      }
      // SyncStamm setzen..nur wenn es sich um eine Stamm Maske handelt
      if (this.data && this.headerStamm) {
        if (this.$api.user.stt !== this.headerStamm && this.data.aic_Stamm) {
          this.$api.setSyncStamm(this.headerStamm, this.data.aic_Stamm);
        }
      }
      // insert Hint wird nur gesetzt, damit er trotz keiner verÃ¤nderter Daten glaubt
      // es hat sich was getan (brauch ma aber nicht speichern)
      if (this.insertHint) {
        this.backupData = null;
      }
    } finally {
      this.showLoading = false;
      this.initialized = true;
      this.addDruck();
      this.$nextTick(() => {
        this.initialize();
      });
    }
  }

  protected initialize() {
    // todo some stuff
  }
  public registerEditComponent(comp: Vue, column: any) {
    const me = this;
    comp.$on("input", (e: any) => {
      me.data[column.name] = e;
    });
    comp.$on("addValidation", this.addValidation);
    if (this.data) {
      const val = this.data[column.name];
      if (val || val === 0) {
        const ecomp = comp as EditComponent;
      }
    }
    if (this.lastValues) {
      const lastVal = this.lastValues[column.name];
      if (lastVal || lastVal === 0) {
        const ecomp = comp as EditComponent;
        ecomp.lastValue = lastVal;
      }
    }
  }
  protected setValue(evt: any) {
    if (this.data.length === 0) {
      this.data.push({});
    }
    this.data[0][evt.field.name] = evt.value;
  }
  private addValidation(v: any) {
    this.validations.push(v);
  }
  protected onValidate() {
    let valid: boolean = true;
    for (let i = 0; i < this.validations.length; i++) {
      const val = this.validations[i];
      const res = val.isValid() as boolean;
      valid = valid && res;
      console.log("field " + val.field.column.name + " -> " + res);
    }
    return valid;
  }

  protected backOrReload() {
    if (this.backOnDelete) {
      // nix tun...
    } else if (this.backOnSave) {
      this.$emit("back", true);
    } else {
      this.reloadData();
    }
  }
  // beim LÃ¶schen von StammsÃ¤tzen muss unbedingt gefragt werden ob die Daten gelÃ¶scht werden sollen!
  protected async deleteStt(selected: number) {
    if (!this.header.aicBew) {
      if (
        confirm(
          "Wollen Sie den Datensatz wirklich lÃ¶schen?"
        )) {
        this.onDelete(selected, "", "", true);
      }
    }
  }
  protected async onDeleteAsync(aic: number) {
    this.onDelete(aic, "");
  }
  protected onDelete(aic: number, modell: string, zeitbereich?: any, stt?: boolean) {
    if (!aic) {
      showSnackbar("keinen Datensatz Ã¼bergeben!");
      // gibt es keinen AIC Dann abbrechen! damit nix falsches gelÃ¶scht wird
      return;
    }
    const bewPool = aic;
    const result = this.$api
      .DeleteData(this.header, bewPool, 0, zeitbereich)
      ?.then((res: any) => {
        if (res) {
          const msgType = res.data.msgType;
          let info = res.data.info;
          const memo = res.data.memo;
          const title = res.data.titel;
          if (memo) {
            info = memo;
          }
          if (info) {
            if (msgType === "Error") {
              showSnackbar({
                text: info,
                color: "error"
              });
            } else if (msgType === "Info") {
              showSnackbar({
                text: info,
                color: "info"
              });
            } else if (msgType === "Warnung") {
              showSnackbar({
                text: info,
                color: "warning"
              });
            }
            this.showLoading = false;
            return msgType;
          }
          // if (stt) {
          //   // this.$emit('reloadList');
          //   refreshFullPageContent();
          // }
          showSnackbar(
            this.$globalsBegriffMemo(
              Globals.Begriff,
              "Datensatz_geloescht",
              "Daten wurden gelÃ¶scht"
            )
          ); // Geloescht
        }
        if (modell) {
          this.calcFunction(modell, this.$api.user.aic, false)
            .then(() => this.backOrReload())
            .catch((err: any) => alert(JSON.stringify(err)));
        } else {
          this.backOrReload();
        }
      })
      .catch((ex: any) => {
        if (ex && ex.response) {
          let errorMessage = "";
          if (ex.response) {
            errorMessage = ex.response.data.error;
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error"
          });
        }
        console.log(ex);
        if (ex !== this.$api.ApiBusyMessage) {
          showSnackbar(
            this.$globalsBegriffMemo(
              Globals.Begriff,
              "Berechnung_lauft",
              "Berechnung lÃ¤uft, bitte warten Sie diese ab."
            )
          );
        }
      });
  }
  // speichern und dann gleich ein Modell auslÃ¶sen!
  protected onSave(
    aicAbfAll: number,
    modell: string,
    zeitbereich?: any,
    aic?: any,
    getData?: boolean,
    varUebergabe?: any[]
  ) {
    let AbfAll = 0;
    if (typeof aicAbfAll === "number") {
      AbfAll = aicAbfAll; // Abfrage fÃ¼r Planung speichern
    }
    const isvalid = this.onValidate();
    if (!isvalid) {
      showSnackbar({
        text: "Validierung fehlgeschlagen!",
        duration: 4000,
        color: "error"
      });
      return;
    }
    if (zeitbereich) {
      this.zeitbereich = zeitbereich;
    }
    const setDataTrue = getData;
    this.showLoading = true;
    let aicUebergabe: number = aic;
    if (!aicUebergabe) {
      if (this.header.aicBew) {
        aicUebergabe = this.aic;
      } else {
        aicUebergabe = this.aic ?? this.queryAIC;
      }
    }
    let saveHaupt = false;
    if ((this.mainAic || this.siteData?.mainAic) && this.header.aicBew) {
      getData = true;
      aicUebergabe = this.mainAic ?? this.siteData?.mainAic;
      saveHaupt = true;
      if (!this.mainStt) {
        // this.mainStt = this.header.aicStt;
      }
    }
    const me = this;
    const result = this.$api
      .SaveData(
        this.header,
        AbfAll,
        this.data.aic_Bew_pool,
        this.data,
        this.columns,
        this.zeitbereich,
        this.backupData,
        (res: any) => me.saved(res, modell),
        (ex: any) => {
          let errorMessage = "";
          this.showLoading = false;
          if (ex.response) {
            errorMessage = ex.response.data.error;
            if (!errorMessage && ex.response?.status && ex.response.status === 400) {
              errorMessage = ex.response.data.info;
            }
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error"
          });
          console.error("ERROR: " + ex);
        },
        aicUebergabe,
        getData,
        saveHaupt,
        this.mainStt,
        null,
        this.jokerStt,
        varUebergabe
      )
      .then(() => {
        // nach dem Speichern soll gleich ein Modell aufgerufen werden!
        if (modell) {
          this.calcFunction(modell, this.$api.user.aic, false).then(() => {
            // danach ggf zumachen...
            if (this.backOnSave) {
              this.$emit("back", this.updateDataForAsync);
            }
          });
        }
      });
    return result;
  }
  private updateDataForAsync: any = null;

  protected saved(res: any, modell: any) {
    if (res.data) {
      const msgType = res.data.msgType;
      let info = res.data.info;
      const memo = res.data.memo;
      const title = res.data.titel;
      if (memo) {
        info = memo;
      }
      if (info) {
        if (msgType === "Error") {
          showSnackbar({
            text: info,
            duration: 6000,
            color: "error"
          });
        } else if (msgType === "Info") {
          showSnackbar({
            text: info,
            duration: 6000,
            color: "info"
          });
        } else if (msgType === "Warnung") {
          showSnackbar({
            text: info,
            duration: 6000,
            color: "warning"
          });

        }
        this.showLoading = false;
        return msgType;
      }
    }
    this.showLoading = false;
    if (!res.nothingHappend) {
      // wenn nix passiert is aber weitermachen ;)
      showSnackbar({
        text: this.$globalsBegriffMemo(
          Globals.Begriff,
          "Datensatz_gespeichert",
          "Daten wurden gespeichert"
        ),
        duration: 100,
      }
      );
    } else {
      showSnackbar(
        this.$globalsBegriffMemo(
          Globals.Begriff,
          "Datensatz_keine_Aenderung",
          "Daten wurden nicht verÃ¤ndert, kein Speichern durchgefÃ¼hrt!"
        )
      );
    }
    let updateData = null;
    if (res && res.data) {
      updateData = res.data;
    }
    if (this.siteData) {
      this.siteData.reloadData = true;
    }
    // backOnSave nur wenn nicht modellberechnung danach gefordert.
    if (this.backOnSave && !modell) {
      this.$emit("back", updateData);
    } else {
      this.updateDataForAsync = updateData;
    }
    if (updateData && updateData.data && updateData.data.length > 0) {
      const update = updateData.data[0];
      this.data.aic_Bew_pool = update.aic_Bew_pool;
      this.initialized = false;
      const me = this;
      this.columns.forEach(col => {
        if (update[col.name] !== undefined) {
          me.data[col.name] = update[col.name];
          console.log("updated " + col.name + " -> " + update[col.name]);
        }
      });

      this.$nextTick(() => (this.initialized = true));
    } else if (updateData.aic_Bew_pool) {
      // wird nur der aic_Bew_pool zurÃ¼ck geliefert, dann auch diesen ersetzen!
      this.data.aic_Bew_pool = updateData.aic_Bew_pool;
    }
  }

  protected onCancel() {
    this.$emit("back");
  }
  private calcFunction(
    modell: string,
    aic: number,
    fromDialog: boolean = false
  ) {
    let begriff: any;
    if (modell) {
      begriff = this.$globalsKennung(Globals.Begriff, modell);
    }
    const me = this;
    if (begriff.userFrage && fromDialog !== true) {
      const userFrage = this.$globalsBegriffMemo(
        this.Globals.Begriff,
        begriff.userFrage,
        "Soll die Berechnung durchgefÃ¼hrt werden?"
      );
      showDialog({
        title: "Frage",
        titleColor: "white",
        titleClass: "black--text",
        width: 300,
        height: 400,
        text: userFrage,
        persistent: true,
        okButton: true,
        onClose: (x: any) => {
          if (x === true) {
            me.calcFunction(modell, aic, true);
          }
          return true;
        }
      });
      return Promise.resolve();
    }
    this.showLoading = true;
    const varUbergabe: any[] = [];
    const tabUbergabe: any[] = [];
    const bemerkung: string = "Web - EditPage";
    return this.$api.postCalc(
      varUbergabe,
      tabUbergabe,
      begriff.aic,
      aic,
      this.zeitbereich,
      0,
      0,
      bemerkung,
      this.success,
      begriff.maxB
    );
  }
  private success(res: any) {
    const vorfehler = res.data.vorfehler;
    if (vorfehler && vorfehler.length > 0 && vorfehler[0].error) {
      console.error("Thread - Modell Fehler: " + res.data.header.vorfehler[0].error);
      showSnackbar({
        text: vorfehler.error,
        color: "error"
      });
    }
    this.calcMessage = res.data.error;
    this.msgType = res.data.msgType;
    this.header = res.data.header;
    this.info = res.data.info;
    const memo = res.data.memo;
    const title = res.data.titel;
    if (memo) {
      this.info = memo;
    }
    if (this.msgType === "Error") {
      showSnackbar({
        text: this.info,

        color: "error"
      });
    } else if (this.msgType === "Info") {
      showSnackbar({
        text: this.info,
        color: "info"
      });
    } else if (this.msgType === "Warnung") {
      showSnackbar({
        text: this.info,
        color: "warning"
      });
    }
    this.showLoading = false;
  }
  private addDruck() {
    if (!this.header.aicBew && this.printItems.length === 0) {
      const stamm = this.header.aicStt;
      this.printItems = this.$globalsDruck("Begriff", false, stamm, true);
    } else if (this.siteData?.item?.compTitle) {
      const comp = this.siteData.item.compTitle;
      const frame = this.$globalsFrame("Begriff", false, "", comp);
      this.printItems = frame[0].druck;
      // fÃ¼r das Formular drucke suchen...
    }
  }
  protected loadImage(avatar: any) {
    if (!avatar.data) {
      this.avatarIcon = this.$api.fullUrl(
        "imageM/" + this.$api.user.id + ":" + +avatar.aic + "_" + avatar.name
      );
    }
  }
  protected openDruckVorschau(item: any) {
    const me = this;
    const printItem = item;
    let aic = this.queryAIC;
    if (this.aic) {
      aic = this.aic;
    }
    const noSelect = true;
    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      width: 500,
      data: {
        zeitbereich: this.zeitbereich,
        printItem,
        aic,
        pers: true,
        noSelect,
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
      },
    });
  }
  protected onCalculated(args: any) {
    // nach der Berechnung alles neu laden!
    setTimeout(() => refreshFullPageContent());
  }
  public saveFrage: boolean = false;
  public onClose() {
    if (this.saveFrage) {
      if (
        confirm(
          "Wollen Sie die Seite wirklich verlassen - es sind nicht alle Daten gespeichert?"
        )
      ) {
        this.saveFrage = false;
        this.$emit("back");
      }
    } else {
      this.$emit("back");
    }
  }
}
export declare interface User {
  aic: number;
  kennung: string;
  bezeichnung: string;
  admin?: boolean;
  art?: number;
  datum?: string;
  ebene?: string;
  tel?: string;
  ohne?: boolean;
  pwMG?: number;
  pwML?: number;
  pwMM?: number;
  pwMS?: number;
  pwMZ?: number;
  sprache?: any;
  land?: any;
  hauptBenutzerGruppe?: any;
}
declare interface Tabs {
  text: string;
  icon: string;
  color?: string;
  step: number;
  component?: string;
  subsites?: any[];
  active?: boolean;
  query?: string;
  activeNumber?: number;
}
