



























































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { showSnackbar, showDialog } from "@/UIHelper";
import { toNumber } from "@/DataHelper";
import { Globals } from "@/Globals";
import Constants from "@/Constants";
import {
  calcFunctionNew,
  getAufgabeDefinition,
  getKZAufgabeDefiniton,
} from "@/CalcHelperStatic";
import {
  getDisplay,
  getDisplayValue,
  getGroupSumValue,
  getKZColumn,
  getValue,
  roundNumber,
} from "@/DataHelper";
import { getMonthNames, valueToFormatStr } from "@/editor/CalenderHelper";
import { onShowSite, refreshFullPageContent } from "@/NavigationHelper";
import AUApi from "@/api";
import { keysIn } from "lodash";
import { getVal } from "@syncfusion/ej2-inputs";
interface GridDialogData {
  aic?: number;
  title: string;
  componentName: string;
  zeitbereich?: APIZeitbereich;
  showCustomEditor: boolean;
  showCustomGrid: boolean;
  query: string;
  lastValues?: null;
  newData: boolean;
  bewegungsdaten: boolean;
  width: number;
  height: number;
  fullscreen?: boolean;
  mainAic?: number;
  mainStt?: number;
  printItems?: any[];
  abwAic?: number;
  keinZeitraum?: boolean;
}

@Component
export default class BaseVGrid extends Vue {
  private showLoading = true;
  private showLoadingSub = false;
  private headers: DataTableHeader[] = [];
  public header: any = { bezeichnung: "hallo" };
  private rowdata: any[] = [];
  private bezeichnung: string = "";
  private noData: boolean = false;
  protected tableData: any[] = [];

  private visibleColumns: DataColumn[] = [];
  private colorColumn: DataColumn[] = [];
  private aggregateColumns: DataColumn[] = [];
  public allColumns: DataColumn[] = [];
  private showGroupBy: boolean = false;
  private groupedBy: string | null = null;
  private expanded: DataColumn[] = [];
  private showExpand: boolean = false;
  private showSearch: boolean = false;
  private search: string = "";
  private menuSearch: boolean = false;
  private sortDesc: string[] = [];
  private sortBy: string[] = [];
  private aic: number = 0;
  private rowKey: string = "aic_Stamm";
  private showZeitraum: boolean = false;
  private type: string = "month";
  private landscape: boolean = true;
  private menu: boolean = false;
  private date: string[] = [new Date().toISOStringWithTZGMT().substr(0, 7)];
  private dateString: string =
    getMonthNames()[new Date().getMonth()] + " " + new Date().getFullYear();
  private startVal: Date = this.$api.zeitbereich.von;
  private endVal: Date = this.$api.zeitbereich.bis;

  private frageDelete: string = this.$globalsBegriffMemo(
    this.Globals.Begriff,
    "Loeschen2",
    "Soll der Datensatz wirklich gelÃ¶scht werden?"
  );
  private frageCopy: string = this.$globalsBegriffMemo(
    this.Globals.Begriff,
    "Kopie",
    "Soll der Datensatz kopiert werden?"
  );
  // private singleSelect: boolean = false;
  private showSelect: boolean = false;
  private selected: any[] = [];
  private pageSize: number = 15;
  private dialogEdit: boolean = false;
  private dialogInlineEdit: boolean = false;
  private showDelete: boolean = false;
  private showDeleteDetail: boolean = false;
  private showNew: boolean = true;
  private dialogOpen: boolean = false;
  private dialogVerwendung: boolean = false;
  private showDialog: boolean = false;
  private showBezeichnung: boolean = true;
  private showCopy: boolean = false;
  private showCopyStamm: boolean = false;
  private showVerwendung: boolean = false;
  private calcModell: string = "";
  private subAbf: string = "";
  private editAbf: string = "";
  private aufgabenDefinition?: QueryResponse;
  private isMobile: boolean = false;
  private dialog: GridDialogData = {
    title: "--!!--",
    componentName: "None",
    showCustomEditor: false,
    showCustomGrid: false,
    query: "",
    newData: false,
    bewegungsdaten: false,
    width: 455,
    height: 200,
    fullscreen: false,
    mainAic: 0,
    mainStt: 0,
    printItems: [],
    abwAic: 0,
    keinZeitraum: false,
  };
  @Prop() public queryData?: QueryResponse;
  @Prop() public query?: string;
  @Prop() public queryAic?: number;
  @Prop() public mainAic?: number;
  private subQuery?: string;
  private expandQuery?: string;
  @Prop() public preloadedData!: QueryResponse;
  public disableFooter: boolean = false;
  @Prop() public dark?: boolean;
  @Prop({ default: false }) public showTitle!: boolean;
  @Prop() public title?: string;
  @Prop({ default: false }) public showClose!: boolean;
  @Prop({ default: false }) public treeView!: boolean;
  private treeviewMutable: boolean = false;
  @Prop({ default: false }) public hideHeader!: boolean;
  @Prop({ default: null }) private zeitbereich!: APIZeitbereich | null;
  @Prop() protected exportItems!: AbfrageItems[];
  @Prop() protected modellItems!: ALLModell[];
  private vecStamm!: any[];

  private mainAicInternal: number = 0;
  private lastQueryResponse?: QueryResponse;

  private maxWidth = window.innerWidth;
  private initialized = false;
  @Prop() public onlyVector?: boolean;
  @Prop() protected printItems!: PrintItems[];
  private mainprintItems: PrintItems[] = [];
  @Prop() public mainStt!: number;
  private mainSttInternal: number = 0;
  private selectedRow: number = 0;
  @Prop() protected preSelectedRow!: number;
  private lokalZeitbereich: APIZeitbereich = {
    von: this.$api.zeitbereich.von,
    bis: this.$api.zeitbereich.bis,
    bereich: "Tag",
  };
  @Prop({ default: null }) public siteData: any;
  private selectedAic!: number;
  public mounted() {
    if ((Constants.isMobile && this.$isPhone()) || window.innerWidth < 600) {
      this.isMobile = true;
    }
    // datum in der Anzeige sauber stellen
    if (this.lokalZeitbereich) {
      const von = this.lokalZeitbereich.von;
      this.date = [von.toISOStringWithTZGMT().substr(0, 7)];
    }
    if (this.siteData) {
      if (this.siteData.query) {
        this.query = this.siteData.query;
      }
      if (this.siteData.aic && typeof this.siteData.aic !== "object") {
        this.queryAic = this.siteData.aic;
      }
      if (this.siteData.zeitbereich) {
        this.zeitbereich = this.siteData.zeitbereich;
        const von = this.siteData.zeitbereich.von;
        this.date = [von.toISOStringWithTZGMT().substr(0, 7)];
      }
    }
    this.treeviewMutable = this.treeView;
    if (this.zeitbereich) {
      this.lokalZeitbereich = this.zeitbereich;
    }
    getAufgabeDefinition().then((r: any) => {
      this.aufgabenDefinition = r;
    });
    this.mainAicInternal = this.mainAic ?? this.queryAic ?? 0;
    this.mainSttInternal = this.mainStt ?? 0;
    this.refresh();
  }

  private get visibleColumnsWithoutGrouped() {
    // const cols = this.visibleColumns.filter(
    //   (c) => !c.meta.gruppiert
    //   // (c) => this.groupedBy.indexOf(c.name) < 0
    // );
    // darf nicht filtern  sonst gruppiert er nicht mehr...
    const cols = this.visibleColumns;
    return cols;
  }
  private get columnsforGroupSummary() {
    if (!this.isMobile) {
      const cols = this.visibleColumns.filter((c) => !c.meta.gruppierbar);
      return cols;
    }
  }
  public fixKennungForProperty(column: any): string {
    let x = "g" + column.name;
    if (this.allColumns) {
      x += "_" + this.allColumns.indexOf(column);
    }
    return x.replace(/\s+/g, "_");
  }
  // @Watch("selected") // darf ich nicht machen sonst refresht er dauernd!
  @Watch("queryData")
  private async refresh() {
    this.selected = [];
    try {
      if (this.preloadedData) {
        if (this.treeviewMutable) {
          const tempData: any = this.preloadedData.data;
          this.dataLoaded(tempData);
        } else {
          this.dataLoaded(this.preloadedData);
        }
      } else if (this.queryData) {
        this.dataLoaded(this.queryData);
      } else if (this.query) {
        const response = await AUApi.Current.getQuery(
          this.query,
          undefined,
          this.queryAic,
          this.lokalZeitbereich
        );
        if (response) {
          this.dataLoaded(response.data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.addDruck();
      this.showLoading = false;
      this.initialized = true;
    }
  }
  private expandDataSet?: QueryResponse | null = null;

  private async loadDetails(item: any) {
    const me = this;
    if (item.value) {
      this.showLoadingSub = true; // nicht alles ausblenden nur ein loading fÃ¼r den subblock
      try {
        if (this.expandQuery) {
          const itemLokal = item;
          const aic = itemLokal.item.aic_Stamm;
          this.mainprintItems = this.printItems ?? [];
          this.mainAicInternal = aic ?? this.mainAic;
          this.mainSttInternal = me.header.aicStt; // mainStt = Stammtyp auf den gespeichert werden soll
          const response = await AUApi.Current.getQuery(
            this.expandQuery,
            undefined,
            aic,
            this.lokalZeitbereich
          );
          if (response && response.data) {
            const dataExpand = response.data;
            const colsExpand = dataExpand.columns.filter(
              (c) => !c.meta.invisible && !c.meta.banned
            );
            this.expandDataSet = dataExpand;
            // if (dataExpand) { this.isExpandDataSetLoaded = true; }
            // else { this.isExpandDataSetLoaded = false; }
            this.showLoadingSub = false;
          }
          return "no data found";
        }
      } finally {
        this.showLoadingSub = false;
      }
    }
  }

  private dialogDelete: boolean = false;
  private newItem() {
    const me = this;
    if (this.subQuery) {
      this.dialog.query = this.subQuery;
      if (me.header.aicBew) {
        // this.dialog.aic = this.mainAicInternal;
        this.dialog.aic = undefined;
        this.dialog.mainAic = me.mainAicInternal;
        this.dialog.mainStt = me.mainSttInternal;
        this.dialog.bewegungsdaten = false;
        // wenn es um SubData geht - dann muss ich den fÃ¼hrenden AIC Ã¼bergeben!
      } else {
        this.dialog.aic = undefined;
      }
      this.dialog.newData = true;
      this.dialogOpen = true;
    } else if (this.dialog.showCustomEditor) {
      const editZeitbereich = this.lokalZeitbereich;
      const aic = 0;
      let mainAic = 0;
      const bewegungsdaten = false;
      let newData = false;
      if (me.header.aicBew) {
        mainAic = me.mainAicInternal;
        newData = true;
      }
      const navData = {
        zeitbereich: editZeitbereich,
        aic,
        bewegungsdaten,
        reloadData: false,
        mainAic,
        newData,
      };
      let fullscreen = false;
      if (this.isMobile) {
        fullscreen = true;
      }
      if (Constants.BottomMenuVisible) {
        showDialog({
          title: this.dialog.title,
          site: this.dialog.componentName,
          data: navData,
          width: 400,
          fullscreen,
          transition: "dialog-bottom-transition",
          onClose: (x: any) => {
            if (navData.reloadData) {
              this.lokalZeitbereich = editZeitbereich;
              setTimeout(() => this.refresh());
            } else if (x?.returnedData) {
              this.lokalZeitbereich = editZeitbereich;
              setTimeout(() => this.refresh());
            }

            return true;
          },
        });
      } else {
        onShowSite({
          title: this.dialog.title,
          site: this.dialog.componentName,
          data: navData,
          callOnLeave: () => {
            if (navData.reloadData) {
              this.lokalZeitbereich = editZeitbereich;
              setTimeout(() => this.refresh());
            }

            return true;
          },
        });
      }
    }
  }
  private editItem(item: any) {
    if (item) {
      console.log(item);
    }
    this.dialog.query = this.editAbf;
    this.dialog.aic = item.aic_Stamm ?? item.aic_Bew_pool;
    if (item.aic_Bew_pool && this.dialog.aic) {
      this.selectedAic = this.dialog.aic;
      this.dialog.mainAic = this.mainAicInternal;
      this.dialog.mainStt = this.mainSttInternal;
      this.dialog.bewegungsdaten = true;
    }
    if (this.dialog.abwAic) {
      const key = this.dialog.abwAic;
      const gridrow = this.rowdata.find(
        (row: any) => row.aic_Bew_pool === item.aic_Bew_pool
      );
      const column = this.allColumns.find((col: any) => col.name === key);
      if (column) {
        try {
          const abwAic = getValue(gridrow, column);
          this.dialog.aic = abwAic;
        } catch (ex) {
          console.log(ex);
        }
      }
    }
    this.dialog.newData = false;
    const editZeitbereich = this.lokalZeitbereich;
    if (this.dialog.query) {
      this.dialogOpen = true;
    } else {
      if (this.dialog.keinZeitraum) {
        // ich geh auf 2 Jahre in die vergangenheit - dann sollten alle Daten da sein..
        let von = this.lokalZeitbereich.von;
        von = von.addMonths(-24, true);
        editZeitbereich.von = von;
        // zeitbereich auf offen stellen??
      }
      const navData = {
        zeitbereich: editZeitbereich,
        aic: this.dialog.aic,
        bewegungsdaten: this.dialog.bewegungsdaten,
        reloadData: false,
      };
      let fullscreen = false;
      if (this.isMobile) {
        fullscreen = true;
      }
      if (Constants.BottomMenuVisible) {
        showDialog({
          title: this.dialog.title,
          site: this.dialog.componentName,
          data: navData,
          fullscreen,
          width: 400,
          transition: "dialog-bottom-transition",
          onClose: () => {
            if (navData.reloadData) {
              this.lokalZeitbereich = editZeitbereich;
              setTimeout(() => this.refresh());
            }
            return true;
          },
        });
      } else {
        onShowSite({
          title: this.dialog.title,
          site: this.dialog.componentName,
          data: navData,
          callOnLeave: () => {
            if (navData.reloadData) {
              this.lokalZeitbereich = editZeitbereich;
              setTimeout(() => this.refresh());
            }
            return true;
          },
        });
      }
    }
  }
  private deleteItemDialog(item: any) {
    this.aic = item.aic_Stamm ?? item.aic_Bew_pool;
    this.dialogDelete = true;
  }
  private dialogCopy: boolean = false;
  private copyItemDialog(item: any) {
    this.aic = item.aic_Stamm ?? item.aic_Bew_pool;
    this.dialogCopy = true;
  }
  private deleteItem() {
    if (!this.aic) {
      showSnackbar("keinen Datensatz Ã¼bergeben!");
      return;
    }
    this.$api.DeleteData(
      this.header,
      this.aic,
      0,
      this.lokalZeitbereich,
      (res: any) => {
        const msgType = res.data.msgType;
        let info = res.data.info;
        const tabInfo = res.data.tab;
        const memo = res.data.memo;
        const title = res.data.titel;
        if (memo) {
          info = memo;
        }
        if (tabInfo) {
          const typ = tabInfo[0].Bewegungstyp;
          const anzahl = tabInfo[0].Anzahl;
          const table = tabInfo;
          // info =
          //   info + "<br><br> in Tabelle: " + typ + "<br> Anzahl Verwendung: " + anzahl;
        }
        if (info) {
          if (msgType === "Error") {
            showSnackbar({
              text: info,
              color: "error",
            });
          } else if (msgType === "Info") {
            showSnackbar({
              text: info,
              color: "info",
            });
          } else if (msgType === "Warnung") {
            showSnackbar({
              text: info,
              color: "warning",
              table: tabInfo,
            });
          }
          this.showLoading = false;
          return msgType;
        }
        this.deleteRow();
        showSnackbar(
          this.$globalsBegriffMemo(
            Globals.Begriff,
            "Datensatz_geloescht",
            "Daten wurden gelÃ¶scht"
          )
        );
      },
      (ex: any) => {
        let errorMessage = "";
        if (ex.response) {
          errorMessage = ex.response.data.error;
        } else {
          errorMessage = ex;
        }
        this.$root.$emit("alert", {
          text: errorMessage,
          type: "error",
        });
        console.log(ex);
        alert("Entschuldigung es ist ein Fehler beim LÃ¶schen aufgetreten");
      }
    );
    this.dialogDelete = false;
    this.showLoading = true;
  }

  private copyItem() {
    const me = this;
    const varUbergabe: any[] = [];
    calcFunctionNew(this.calcModell, this.aic, false, me, "", varUbergabe)
      ?.then((response: any) => {
        if (response !== "Error") {
          this.onBack(response);
        }
      })
      .catch((error: any) => {
        // hÃ¼bsche Fehlerbehandlung
        showSnackbar({
          text: error,
          color: "error",
        });
        console.log("ModellError:" + error);
      });
    this.dialogCopy = false;
  }
  private async copyStamm() {
    const me = this;
    const response = await this.$api.copyStamm(this.aic);
    this.dialogCopy = false;
    if (response?.data.copyStamm) {
      const bezeichnung = response.data.copyBez;
      this.refresh();
    }
    // function von Roland!
  }
  private closeDelete() {
    this.dialogDelete = false;
  }
  private closeCopy() {
    this.dialogCopy = false;
  }
  protected onCancel() {
    this.dialogVerwendung = false;
  }
  private openVerwendung(item: any) {
    const me = this;
    this.dialog.query = this.subAbf;
    this.dialog.aic = undefined;
    this.dialog.mainAic = item.aic_Stamm;
    this.dialog.mainStt = 0;
    this.dialog.bewegungsdaten = true;
    this.dialogVerwendung = true;
    this.dialog.printItems = [];
    this.dialog.width = innerWidth;
    this.dialog.height = 800;
  }
  public dataLoaded(data: QueryResponse) {
    try {
      this.lastQueryResponse = data;
      this.rowdata = data.data;
      this.header = data.header;
      if (this.header.aicBew && this.header?.ZR_Wahl === true) {
        this.showZeitraum = true;
      }
      if (this.header?.keinNeu) {
        this.showNew = false;
      }
      const editForm: any = data.columns.filter(
        (c) => c.datatype === "Formular" && c.meta.kz === "edit"
      );
      let editDetail: any;
      if (editForm.length === 0) {
        if (this.header.fomDetail) {
          editForm.push(this.header.fomDetail);
          editDetail = this.header.fomDetail;
          this.dialogEdit = true;
        }
      } else if (editForm[0] && editForm[0].meta && editForm[0].meta.subFom) {
        editDetail = editForm[0].meta?.subFom[0];
        this.dialogEdit = true;
      }
      if (editDetail && editForm!.length > 0) {
        if (!this.dialogInlineEdit) {
          this.dialogInlineEdit = true;
          this.headers.push({
            text: "",
            value: "action",
            sortable: false,
          });
        }
        this.dialog.componentName = editDetail.alias ?? editDetail.kennung;
        this.dialog.title = editDetail.bezeichnung;
        this.dialog.showCustomEditor = true;
        this.dialog.fullscreen = true;
        this.dialog.width = window.innerWidth;
        this.dialog.height = window.innerHeight;
        this.dialog.keinZeitraum = editDetail.keinZR;
        if (editForm[0].meta?.subSpalte) {
          this.dialog.abwAic = editForm[0].meta?.subSpalte;
        }
      }
      const editAbf: any = data.columns.filter(
        (c) => c.datatype === "Abfrage" && c.meta.kz === "edit"
      );
      const details = this.header?.abfDetail;
      if (details) {
        this.subQuery = details;
        this.dialog.query = details;
      }
      if (editAbf!.length > 0) {
        if (!this.dialogInlineEdit) {
          this.headers.push({
            text: "",
            value: "action",
            sortable: false,
          });
        }
        // this.showVerwendung = true;
        const editAbfDetail = editAbf[0].meta;
        this.editAbf = editAbfDetail.subAbf[0].kennung;
      } else if (this.subQuery) {
        this.editAbf = this.subQuery;
      }

      if ((data?.data?.length ?? 0) === 0) {
        this.disableFooter = true;
        this.noData = true;
        this.mainStt = data.header.aicStt;

        if (this.header.keinBez) {
          // this.header = "";
          this.bezeichnung = "";
          this.showBezeichnung = false;
        } else {
          this.bezeichnung = this.header.bezeichnung;
        }
        if (this.header?.DialogEdit) {
          const abfDetails = this.header?.abfDetail;
          if (abfDetails) {
            this.subQuery = abfDetails;
            this.dialogEdit = true;
          }
          if (this.header.fomDetail) {
            let editNew: any;
            editNew = this.header.fomDetail;
            this.dialogEdit = true;
            this.dialog.componentName = editNew.alias ?? editNew.kennung;
            this.dialog.title = editNew.bezeichnung;
            this.dialog.showCustomEditor = true;
            this.dialog.keinZeitraum = editNew.keinZR;
          }
        }
        return;
      } else if (data.data.length < this.pageSize) {
        this.disableFooter = true;
      }
      if (this.onlyVector || this.header.multi) {
        this.showSelect = true;
      }
      if (this.exportItems && this.exportItems.length > 0) {
        // nur die Abfragen die auch zur Bewegung / Stamm passen anzeigen!
        const id = this.header.aicBew ?? this.header.aicStt;
        this.exportItems = this.exportItems.filter(
          (c: any) => c.bew === id || c.stt === id
        );
        this.showSelect = true;
      }

      if (data.data[0] && data.data[0].aic) {
        this.rowKey = "aic";
      } else if (data.data[0] && data.data[0].aic_Stamm) {
        this.rowKey = "aic_Stamm";
      } else if (data.data[0] && data.data[0].aic_Bew_pool) {
        this.rowKey = "aic_Bew_pool";
      }
      const webStamm = this.header?.webStamm; // webStamm = definitin bei der Abfrage!
      if (webStamm) {
        if (this.aufgabenDefinition) {
          const stamm = this.aufgabenDefinition?.data.find(
            (r: any) => r.aic_Stamm === webStamm
          );
          if (stamm) {
            this.showCopyStamm = getKZAufgabeDefiniton(
              "stammKopie",
              stamm.aic_Stamm,
              this.aufgabenDefinition
            );
          }
        }
      }
      const erweitert = this.header?.abfErweitert;
      if (erweitert) {
        this.expandQuery = erweitert;
      }
      if (this.expandQuery) {
        this.showExpand = true;
      }

      this.showSearch = this.header?.suche;
      this.bezeichnung = this.header?.bezeichnung;
      if (this.header?.keinBez) {
        // this.header = "";
        this.bezeichnung = "";
        this.showBezeichnung = false;
      }
      this.allColumns = data.columns;
      const dataTable = data.data;

      const defColums = data.columns.filter((c) => c.meta.kz === "allowDelete");
      const cols = data.columns.filter((c) => !c.meta.invisible && !c.meta.banned);
      this.visibleColumns = cols;
      const colsSelect = data.columns.filter((c) => c.meta.kz === "select");
      const sysTable = data.columns.filter((c) => c.datatype === "SysAic");
      if (this.header?.DialogEdit) {
        this.showDialog = true;
        this.dialogInlineEdit = true;
        this.dialogEdit = true;
        if (!this.header.keinDel) {
          // kein LÃ¶schen - Parameter bei Abfrage
          this.showDelete = true;
        }
      }
      let maxPercent = 0;
      this.headers = cols.map((c) => {
        const me = this;
        this.showGroupBy =
          this.showGroupBy || (c.meta.gruppiert === true && c.meta.gruppierbar === true);
        if (c.meta.gruppierbar === true) {
          this.groupedBy = c.name;
        }
        let alignText: any = "start";
        if (c.type === "zahl" || c.type === "waehrung" || c.type === "mass") {
          alignText = "end";
        }
        if (c.meta.webWidth) {
          maxPercent = maxPercent + toNumber(c.meta?.webWidth);
        } else {
          maxPercent = maxPercent + 20;
        }

        return {
          text: c.title,
          value: c.name,
          align: alignText,
          queryColumn: c,
          groupable: c.meta.gruppierbar === true,
          type: c.type,
          width: c.meta.webWidth ?? "20%",
        };
      });
      if (this.dialogInlineEdit) {
        if (maxPercent > 100) {
          maxPercent = 70;
        }
        const widthBtn = 100 - maxPercent;
        const widthString = widthBtn.toString() + "%";
        this.headers.push({
          text: "",
          value: "action",
          sortable: false,
          width: "widthString",
          align: "center",
        });
      }
      const calcModell: any = data.columns.filter((c) => c.datatype === "Modell");
      if (calcModell!.length > 0) {
        if (!this.dialogInlineEdit) {
          this.headers.push({
            text: "",
            value: "action",
            sortable: false,
          });
        }
        this.showCopy = true;
        const subMod = calcModell[0].meta;
        this.calcModell = subMod.subMod.kennung;
      }
      const subAbf: any = data.columns.filter(
        (c) => c.datatype === "Abfrage" && c.meta.kz === "use"
      );

      if (subAbf!.length > 0) {
        if (!this.dialogInlineEdit) {
          this.headers.push({
            text: "",
            value: "action",
            sortable: false,
          });
        }
        this.showVerwendung = true;
        const subAbfDetail = subAbf[0].meta;
        this.subAbf = subAbfDetail.subAbf.kennung;
      }

      this.colorColumn = getKZColumn("color", data.columns);
      this.aggregateColumns = data.columns.filter(
        (c: any) => c.meta.ergebnisArt !== undefined && c.meta.ergebnisArt !== null
      );

      // this.groupedBy = data.columns
      //   .filter(c => c.meta.gruppiert === true)
      //   .map(c => c.name);

      // hier nur dateninhalte kein styling
      // this.tableData = data.data;
      this.tableData = data.data.map((row) => {
        const tr: any = {};
        // let alignText: any = "Right";
        // let widthNumber = 200;
        // if (row.meta.width || row.meta.webWidth) {
        //   widthNumber = row.meta.webWidth ?? row.meta.width ?? 150 * 1.45;
        // }
        // if (
        //   row.type === "textField" ||
        //   row.type === "comboBox" ||
        //   row.type === "datePicker" ||
        //   row.type === "checkBox3"
        // ) {
        //   alignText = "Left";
        // }
        this.visibleColumnsWithoutGrouped.forEach((col) => {
          if (col.type === "checkBox" && this.colorColumn) {
            tr[col.name] = row[col.name];
            // boolean nicht in text umwandeln
          } else {
            tr[col.name] = getDisplay(row, col);
          }
        });
        sysTable.forEach((col) => {
          tr[col.name] = getDisplay(row, col);
        });
        if (defColums) {
          this.showDeleteDetail = true;
          defColums.forEach((col) => {
            tr.allowDelete = getValue(row, col);
          });
        }
        return tr;
      });
      // Zeilen Vorselektieren! KZ bei Column = "select"
      if (colsSelect && colsSelect.length > 0) {
        data.data.forEach((row) => {
          const col = row[colsSelect[0].name];
          if (col) {
            this.selected.push(row);
          }
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (this.preSelectedRow) {
        const gridrow = this.tableData.find(
          (x: any) => x.aic_Stamm === this.preSelectedRow
        );
        this.selected.push(gridrow);
        this.rowSelect(this.preSelectedRow);
      }
      this.showLoading = false;
      this.initialized = true;
      const init = true;
      this.$emit("loaded", init);
    }
  }
  public itemRowBackground(item: any) {
    // paramaterFarbe Ã¼bergeben
    if (item.colorCloumn) {
      return item.colorColumn;
    }
  }
  public getRowStyle(rowIndex: number) {
    if (!this.colorColumn && this.aggregateColumns.length === 0) {
      if (rowIndex === this.selectedRow) {
        return { backgroundColor: "green" };
      } else {
        return;
      }
    }
    const row = this.lastQueryResponse?.data[rowIndex];
    const color = getValue(row, this.colorColumn);
    return { backgroundColor: color };
  }
  private getColStyle(c: DataColumn) {
    if (c.type === "zahl" || c.type === "waehrung" || c.type === "mass") {
      return "text-align: right";
    } else {
      return "text-align: left";
    }
  }
  public getDisplay(row: any, column: DataColumn) {
    return getDisplay(row, column);
  }
  private groupChanged(val: string) {
    this.groupedBy = val;
  }
  public getFooter(col: DataColumn, options: any, pagination: any) {
    if (!this.lastQueryResponse || !this.aggregateColumns.find((c) => c === col)) {
      return;
    }

    const val = getGroupSumValue(this.lastQueryResponse.data, col);
    return getDisplayValue(val, col);
  }
  public getSubTotal(col: DataColumn, items: any) {
    if (!this.aggregateColumns.find((c) => c === col)) {
      return;
    }

    const val = getGroupSumValue(items, col);
    return getDisplayValue(val, col);
  }
  private onDialogClose(updateData: any) {
    this.dialogOpen = false;
    if (updateData) {
      this.onBack(updateData);
    }
  }
  private onBack(updateData: any, reload?: boolean, aicOld?: number) {
    this.showLoading = true;
    if (updateData) {
      this.$emit("onReloadData");
      let newData = false;
      const data = updateData[0];
      if (!this.header.aicBew) {
        let gridrow = this.tableData.find((row: any) => row.aic_Stamm === data.aic_Stamm);
        if (!gridrow) {
          gridrow = data;
          newData = true;
        }
        if (data) {
          for (const key of Object.keys(data)) {
            if (key !== "aic_Stamm") {
              const column = this.allColumns.find((col: any) => col.name === key);
              if (column) {
                try {
                  const rowProp: any = column.name;
                  gridrow[rowProp] = getDisplayValue(data[key], column);
                } catch (ex) {
                  console.log(ex);
                }
              }
            }
          }
          if (newData) {
            this.tableData.push(gridrow);
            this.sortData();
          }
          this.tableData = [...this.tableData]; // datasource refreshen fÃ¼r grid
        }
      } else {
        let gridrowBew;
        if (this.selectedAic || aicOld) {
          gridrowBew = this.tableData.find(
            (row: any) => row.aic_Bew_pool === this.selectedAic || aicOld
          );
        } else {
          gridrowBew = this.tableData.find(
            (row: any) => row.aic_Bew_pool === data.aic_Bew_pool
          );
        }
        if (!gridrowBew) {
          gridrowBew = updateData[0];
          newData = true;
        }
        if (data) {
          for (const key of Object.keys(data)) {
            const column = this.allColumns.find((col: any) => col.name === key);
            if (column) {
              try {
                const rowProp: string = column.name;
                gridrowBew[rowProp] = getDisplayValue(data[key], column);
              } catch (ex) {
                console.log(ex);
              }
            }
          }
          if (newData) {
            this.tableData.push(gridrowBew);
            this.sortData();
          }
          this.tableData = [...this.tableData]; // datasource refreshen fÃ¼r grid
        }
      }
    }
    this.showLoading = false;
    this.dialogOpen = false;
  }
  private dialogBack() {
    this.dialogVerwendung = false;
  }

  private sortData() {
    // das muss nochmal Ã¼berarbeitet werden - es kann auch auf UNSICHTBARE Spalten sortiert werden!
    const sort = this.allColumns.find((col: any) => col.meta.sortiert === 1);
    const sort2 = this.allColumns.find((col: any) => col.meta.sortiert === 2);
    if (sort2) {
      this.tableData.sort(this.dynamicSort(sort2.name));
    }
    if (sort) {
      this.tableData.sort(this.dynamicSort(sort.name));
    }
  }
  private rowSelectedColor() {
    return "rowSelected";
  }
  private rowSelect(idx: number) {
    const comp = this.$refs.zuordnung as any;
    (this.$refs.comp as any).$forceUpdate();
    this.selectedRow = idx;
    return { backgroundColor: "green" };
  }

  private deleteRow() {
    let gridrow;
    if (this.header.aicBew) {
      gridrow = this.tableData.findIndex((row: any) => row.aic_Bew_pool === this.aic);
    } else {
      gridrow = this.tableData.findIndex((i: any) => i.aic_Stamm === this.aic);
    }
    this.aic = 0;
    if (gridrow !== -1) {
      this.tableData.splice(gridrow, 1);
      this.tableData = [...this.tableData]; // datasource refreshen fÃ¼r grid
    }
    this.$emit("onReloadData");
    this.showLoading = false;
  }
  private dynamicSort(property: any) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a: any, b: any) => {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  protected createdBase() {
    const me = this;
    this.$root.$on("windowResize", (a: any) => me.onResize(a));
  }
  public onResize(args: any) {
    this.$forceUpdate();
  }
  private addDruck() {
    if (this.header && !this.header.aicBew && !this.treeviewMutable) {
      const stamm = this.header.aicStt;
      // const stammTyp = this.$globalsStt(Globals.Stt, "", stamm);
      this.mainprintItems = this.$globalsDruck("Begriff", false, stamm, true);
      if (this.mainprintItems?.length > 0) {
        this.showSelect = true;
      }
    } else if (this.printItems && this.printItems.length > 0) {
      this.mainprintItems = this.printItems;
    }
  }
  private openDruckVorschau(item: any) {
    const me = this;
    const printItem = item;
    let fullscreen = false;
    const vecStamm: any[] = [];
    if (this.selected.length > 0) {
      this.selected.forEach((element) => {
        vecStamm.push(element.aic_Stamm ?? element.aic_Bew_pool);
      });
    }
    if (this.selected.length === 0) {
      showSnackbar({
        text: "Bitte wÃ¤hlen Sie zuerst einen Datensatz aus!",
        color: "warning",
      });
      return;
    }
    // console.log("isPhone: " + this.$isPhone());
    if (this.isMobile) {
      fullscreen = true;
    }
    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      fullscreen,
      width: 510,
      data: { zeitbereich: this.lokalZeitbereich, printItem, vecStamm },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
        /* nix tun*/
      },
    });
  }
  private onMonthChanged(args: any) {
    this.menu = false;
    this.date = args;
    if (!args) {
      return;
    }
    const vonDate = new Date(args);
    const bisDate = new Date(new Date(vonDate).lastInMonth());
    this.lokalZeitbereich = {
      bereich: "Tag",
      von: vonDate,
      bis: bisDate,
    };
    this.tableData = [];
    this.refresh();
  }

  private closeAll() {
    Object.keys(this.$refs).forEach((k) => {
      if (this.$refs[k]) {
        const y = this.$refs[k] as any;
        if (y) {
          const x = y.$el;
          if (x) {
            x.click();
          }
        }
      }
    });
  }
  private onCalculated(args: any) {
    setTimeout(() => this.refresh());
  }
  private onClickLoad() {
    this.showLoading = true;
    setTimeout(() => {
      this.refresh();
    });
  }
}
