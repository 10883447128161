































































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import Constants from "@/Constants";
import EditPage from "@/components/EditPage";
import BaseSyncSchedule from "../../../components/baseSyncSchedule.vue";
@Component
export default class AbwesenheitenVG extends EditPage {
  private abwZeitraum: Date = this.$api.zeitbereich.von;
  protected showTimeLineMonth: boolean = true;
  protected showDay: boolean = false;
  protected showWeek: boolean = false;
  protected currentView: string = "TimelineMonth";
  protected allowDragAndDrop: boolean = false;
  private toggleSight: any = "abwesenheiten";
  private showStempelantrag: boolean = false;
  private showAbwesenheiten: boolean = false;
  private showDetailantrag: boolean = false;
  private showReisedaten: boolean = false;
  private showDienstplan: boolean = false;
  private showSonstBelege: boolean = false;
  protected editorComponent: string = "PzeWfVorgesetzterFreigabenTemplateDialogFreigabe";
  protected editorComponent1: string = "";
  protected showQuickInfo: boolean = true;
  protected showMonth: boolean = false;
  protected setMinDate: boolean = false;
  protected query: string = "Web_Planung_Vorgesetzer";
  protected saveQuery: string = "WebPlanungSpeichern";
  protected showGroup: boolean = true;
  protected queryMitarbeiter: string = "Web_Liste_MA_Vorgesetzter";
  protected group2: string = "Web_WF_VG_Gruppiert_Abteilung_ALLE_MA";
  protected readBewilligt: boolean = true;
  protected readOnly: boolean = false;
  protected onlyEdit: boolean = true;
  protected saveModell: string = "";
  protected useCellTemplate: boolean = false;
  protected gotoAic: string = "";
  protected dialogAicStamm: boolean = false;
  private loading: boolean = false;
  private loading1: boolean = false;
  private loading2: boolean = false;
  private loading3: boolean = false;
  private loading4: boolean = false;
  private loading5: boolean = false;
  private disabled: boolean = false;
  private hideMeForRefresh: boolean = false;
  private persistence: boolean = false;
  private reloadGroup: boolean = false;
  private showRow: number = 0;
  private showBack: boolean = false;
  private rkAdmin: boolean = false;
  private formularUebergabe: any;
  private dienstplan: boolean = false;
  private takeDienstplan: boolean = false;
  private myHeight: number = window.innerHeight - 50;
  private showGrid: boolean = false;

  private loader: any = null;
  private modellItems: ALLModell[] = [];

  protected group: any = {
    enableCompactView: false,
    resources: ["groupData", "employee"],
  };
  public mounted() {
    // this.formular... muss leer bleiben!
    if (this.siteData?.showBack) {
      this.showBack = this.siteData.showBack;
    }
    this.showRow = 0;
    if (this.checkBegriff("WF_Abwesenheiten_Abteilung")) {
      this.showAbwesenheiten = true;
      this.showRow++;
    }
    if (this.checkBegriff("LZ_Korrekturantrag_VG")) {
      this.showStempelantrag = true;
      this.showRow++;
    }
    if (this.checkBegriff("Lizenz_DetailantragVG_Sicht")) {
      this.showDetailantrag = true;
      this.showRow++;
    }
    if (this.checkBegriff("LZ_ReiseabrechnungVG")) {
      this.showReisedaten = true;
      this.showRow++;
    }
    if (this.checkBegriff("LV_ReiseabrechnungAdmin")) {
      this.showReisedaten = true;
      this.rkAdmin = true;
      this.showRow++;
    }
    if (this.checkBegriff("LZ_Dienstplan_VG")) {
      this.showDienstplan = true;
      this.showRow++;
    }
    if (this.checkBegriff("LZ_sonstigeBelege_VG")) {
      this.showSonstBelege = true;
      this.showRow++;
    }
  }
  protected createdBase() {
    const me = this;
    this.$root.$on("windowResize", (a: any) => me.onResize(a));
  }
  public onResize(args: any) {
    this.$forceUpdate();
  }
  public created() {
    if (Constants.isMobile) {
      this.persistence = true;
      // dann klappt nach einem Reload das hinpositionieren auf den zuletzt gewÃ¤hlten MA!
      this.currentView = "Month";
      this.showTimeLineMonth = false;
      this.showMonth = true;
    } else {
      this.persistence = false;
      this.currentView = "TimelineMonth";
      this.showTimeLineMonth = true;
      this.showWeek = true;
      this.showMonth = false;
    }
    if (this.siteData && this.siteData.aic && this.siteData.aic !== 0) {
      this.gotoAic = this.siteData.aic;
    }
    if (this.siteData?.toggleUebergabe) {
      this.toggleSight = this.siteData.toggleUebergabe;
      if (this.toggleSight === "Dienstplan") {
        const localTime = window.localStorage.getItem("activeZeitraumDPVG");
        if (localTime) {
          this.abwZeitraum = new Date(localTime);
        } else {
          this.abwZeitraum = new Date();
        }
      }
    } else if (localStorage.activeTabVG) {
      if (
        localStorage.activeTabVG !== "undefined" &&
        localStorage.activeTabVG !== "" &&
        localStorage.activeTabVG !== "null"
      ) {
        this.toggleSight = window.localStorage.getItem("activeTabVG");
      } else {
        if (this.checkBegriff("WF_Abwesenheiten_Abteilung")) {
          this.toggleSight = "abwesenheiten";
        } else if (this.checkBegriff("LZ_Korrekturantrag_VG")) {
          this.toggleSight = "Stempelantraege";
        } else if (this.checkBegriff("Lizenz_DetailantragVG_Sicht")) {
          this.toggleSight = "Detailantraege";
        } else if (
          this.checkBegriff("LZ_ReiseabrechnungVG") ||
          this.checkBegriff("LV_ReiseabrechnungAdmin")
        ) {
          this.toggleSight = "Reisedaten";
        } else if (this.checkBegriff("LZ_Dienstplan_VG")) {
          this.toggleSight = "Dienstplan";
        } else if (this.checkBegriff("LZ_sonstigeBelege_VG")) {
          this.toggleSight = "sonstBelege";
        }
      }
    } else {
      if (this.checkBegriff("WF_Abwesenheiten_Abteilung")) {
        this.toggleSight = "abwesenheiten";
      } else if (this.checkBegriff("LZ_Korrekturantrag_VG")) {
        this.toggleSight = "Stempelantraege";
      } else if (this.checkBegriff("Lizenz_DetailantragVG_Sicht")) {
        this.toggleSight = "Detailantraege";
      } else if (
        this.checkBegriff("LZ_ReiseabrechnungVG") ||
        this.checkBegriff("LV_ReiseabrechnungAdmin")
      ) {
        this.toggleSight = "Reisedaten";
      } else if (this.checkBegriff("LZ_Dienstplan_VG")) {
        this.toggleSight = "Dienstplan";
        const localTime = window.localStorage.getItem("activeZeitraumDPVG");
        if (localTime) {
          this.abwZeitraum = new Date(localTime);
        }
      } else if (this.checkBegriff("LZ_sonstigeBelege_VG")) {
        this.toggleSight = "sonstBelege";
      }
    }
    this.changeView();
  }
  private onloaded(loaded: boolean, gotoAic: string) {
    if (loaded) {
      setTimeout(() => {
        this.loading = false;
        this.loading1 = false;
        this.loading2 = false;
        this.loading3 = false;
        this.loading4 = false;
        this.loading5 = false;
        this.disabled = false;
      }, 300);
    }
  }
  private onZeitraum(newZeitraum: any) {
    this.abwZeitraum = newZeitraum;
    if (this.toggleSight === "Dienstplan") {
      const zeitBereich = newZeitraum.neuerZeitbereich;
      const zeitText = zeitBereich.toISOStringWithTZ().substr(0, 10);
      const activeZeitraum = window.localStorage.setItem("activeZeitraumDPVG", zeitText);
    }
  }
  private changeView() {
    const me = this;
    this.loading = false;
    this.loading1 = false;
    this.loading2 = false;
    this.loading3 = false;
    this.loading4 = false;
    this.loading5 = false;
    this.disabled = true;
    this.hideMeForRefresh = true;

    const schedule = this.$refs.schedule as BaseSyncSchedule;
    // akt. Tab merken fÃ¼r refresh!
    const activeTabVG = window.localStorage.setItem("activeTabVG", this.toggleSight);
    // Lizenz: Dienstplan VG: LZ_Dienstplan_VG
    if (this.toggleSight === "Stempelantraege") {
      if (Constants.isMobile) {
        this.group2 = "";
        this.group = {
          enableCompactView: true,
          resources: ["Employee"],
        };
      } else {
        this.group2 = "Web_WF_VG_Gruppiert_Abteilung_ALLE_MA"; // Organigramm
        // this.group2 = "WEB_WF_StempAntrag_MA";
        this.group = {
          enableCompactView: false,
          resources: ["GroupData", "Employee"],
        };
      }
      this.loading1 = true;
      this.query = "Web_Uebersicht_offene_Antraege";
      this.editorComponent = "PzeWfVorgesetzterFreigabenTemplateStempelungen";
      this.queryMitarbeiter = "Web_Liste_MA_VG_Stempelantraege"; // alle MA
      // this.queryMitarbeiter = "Web_WF_Liste_MA_Stempelantrag_VG";
      this.showQuickInfo = false;
      this.onlyEdit = true;
      this.formularUebergabe = "";
      this.setMinDate = false;
      this.saveQuery = "";
      this.useCellTemplate = true;
      this.reloadGroup = true;
      this.editorComponent1 = "";
      this.dialogAicStamm = false;
      this.dienstplan = false;
      this.takeDienstplan = false;
    } else if (this.toggleSight === "abwesenheiten") {
      this.loading = true;
      if (Constants.isMobile) {
        this.currentView = "Month";
        this.group2 = "";
        this.group = {
          enableCompactView: true,
          resources: ["Employee"],
        };
      } else {
        this.currentView = "TimelineMonth";
        this.group2 = "Web_WF_VG_Gruppiert_Abteilung_ALLE_MA"; // Kst aller MA und nicht nur mit Planungsdaten!
        // this.group2 = "Web_Gruppierung_Abteilung"; // enthÃ¤lt nur MA mit Planungen...
        this.group = {
          enableCompactView: false,
          resources: ["GroupData", "Employee"],
        };
      }
      this.query = "Web_Planung_Vorgesetzer";
      this.editorComponent = "PzeWfVorgesetzterFreigabenTemplateDialogFreigabe";
      this.editorComponent1 = "";
      this.queryMitarbeiter = "Web_Liste_MA_Vorgesetzter";
      // this.readBewilligt = false;
      this.formularUebergabe = this.$globalsKennung(
        this.Globals.Begriff,
        "WF_Abwesenheiten_Abteilung"
      );
      this.setMinDate = false;
      this.saveQuery = "WebPlanungSpeichern";
      this.saveModell = "WEB_PZE_PlanungDoppelbuchungen";
      this.useCellTemplate = false;
      this.reloadGroup = true;
      if (this.checkBegriff("Web_KorrekturPlanunngen")) {
        this.onlyEdit = false;
        this.showQuickInfo = false;
      } else {
        this.onlyEdit = true;
        this.showQuickInfo = true;
      }
      this.dialogAicStamm = false;
      this.dienstplan = false;
      this.takeDienstplan = false;
      this.showGrid = false;
      this.readOnly = false;
    } else if (this.toggleSight === "Detailantraege") {
      this.loading2 = true;
      this.setMinDate = false;
      this.query = "Web_Uebersicht_VG_Detailantrag";
      this.editorComponent = "PzeWfVorgesetzterFreigabenTemplateDialogDetailantrag";
      this.editorComponent1 = "";
      if (Constants.isMobile) {
        this.currentView = "Month";
        this.group2 = "";
        this.group = {
          enableCompactView: true,
          resources: ["Employee"],
        };
      } else {
        this.currentView = "TimelineMonth";
        this.group2 = "Web_WF_VG_Gruppiert_Abteilung_ALLE_MA"; // Organigramm
        // this.group2 = "Web_WF_Gruppe_MA_Organigramm";
        this.group = {
          enableCompactView: false,
          resources: ["GroupData", "Employee"],
        };
      }
      this.queryMitarbeiter = "Web_Liste_MA_Korrekturantraege"; // alle MA
      // this.queryMitarbeiter = "Web_WF_Liste_MA_VG_WF"; mur die mit daten...
      // this.readBewilligt = true;
      this.showQuickInfo = false;
      this.useCellTemplate = true;
      this.reloadGroup = true;
      this.formularUebergabe = "";
      this.saveQuery = "";
      this.currentView = "TimelineMonth";
      this.dialogAicStamm = false;
      this.dienstplan = false;
      this.takeDienstplan = false;
      this.showGrid = false;
      this.readOnly = false;
      this.onlyEdit = true;
    } else if (this.toggleSight === "Reisedaten") {
      this.loading3 = true;
      this.setMinDate = false;
      this.query = "Web_Planung_Reisen_VG_Sicht";
      if (this.checkBegriff("LV_ReiseabrechnungAdmin")) {
        this.editorComponent = "RkAdminDialogReisen";
      } else {
        this.editorComponent = "RkWfVorgesetzterDialogReisen";
      }
      this.editorComponent1 = "";
      // this.editorComponent = "RkWfVorgesetzterDialogReisen";
      if (Constants.isMobile) {
        this.currentView = "Month";
        this.group2 = "";
        this.group = {
          enableCompactView: true,
          resources: ["Employee"],
        };
        this.showMonth = true;
        this.showTimeLineMonth = false;
      } else {
        this.currentView = "TimelineMonth";
        this.group2 = "Web_WF_VG_Gruppiert_Abteilung_ALLE_MA";
        this.group = {
          enableCompactView: false,
          resources: ["GroupData", "Employee"],
        };
        this.showMonth = false;
        this.showTimeLineMonth = true;
      }
      this.queryMitarbeiter = "Web_Liste_MA_VG_Reiseplanungen"; // ohne Resturlaub..
      // this.queryMitarbeiter = "Web_PlanungVG_ListeMA";
      this.showQuickInfo = false;
      this.useCellTemplate = false;
      this.reloadGroup = true;
      this.formularUebergabe = this.$globalsKennung(
        this.Globals.Begriff,
        "WFVorgesetzterFreigabenReisenMitarbeite"
      );
      this.saveQuery = "";
      this.dialogAicStamm = true;
      this.dienstplan = false;
      this.takeDienstplan = true;
      this.showGrid = false;
      this.readOnly = false;
      this.onlyEdit = true;
    } else if (this.toggleSight === "Dienstplan") {
      this.loading4 = true;
      if (Constants.isMobile) {
        this.group2 = "";
        this.group = {
          enableCompactView: true,
          resources: ["Employee"],
        };
      } else {
        this.group2 = "Web_WF_VG_Gruppiert_Kst_Alle_MA";
        this.group = {
          enableCompactView: false,
          resources: ["GroupData", "Employee"],
        };
      }
      this.currentView = "TimelineWeek";
      let von = new Date();

      von = new Date(von.firstOfWeek());
      // ich stelle yhier schon auf Woche - damit auch die QUERY Abfrage sauber fÃ¼r die eine woche geholt wird
      const bis = von.addDays(+6);
      const bereich = "Woche";
      this.zeitbereich = {
        von,
        bis,
        bereich,
      };
      this.showWeek = true;
      this.onlyEdit = false;
      this.readOnly = false;
      this.showQuickInfo = false;
      this.query = "Web_ZE_Schichtplan_VG";
      this.editorComponent = "PzeWfVorgesetzterFreigabenTemplateDialogDienstplan";
      this.editorComponent1 = "PzeWfVorgesetzterFreigabenTemplateDialogFreigabe";
      this.queryMitarbeiter = "Web_MA_ListeDienstplan_VG";
      this.formularUebergabe = this.$globalsKennung(
        this.Globals.Begriff,
        "WFVorgesetzterFreigabenDienstplanMitarbeiter"
      );
      this.setMinDate = false;
      // this.saveQuery = "WebPlanungSpeichern";
      this.useCellTemplate = true;
      this.reloadGroup = true;
      this.showDay = true;
      this.dialogAicStamm = false;
      this.dienstplan = true;
      this.takeDienstplan = true;
      this.showGrid = false;
    } else if (this.toggleSight === "sonstBelege") {
      this.loading5 = true;
      this.query = "Web_UebersichtVG_SonstigeBelege";
      const formular = this.$globalsKennung(
        this.Globals.Begriff,
        "WFVorgesetzterFreigabensonstigeBelegeMitarbeite"
      );
      this.modellItems = formular.mod2;
      this.printItems = formular.druck;
      this.showGrid = true;
      this.readOnly = false;
    }
    this.$nextTick(() => {
      this.hideMeForRefresh = false;
    });
  }
}
